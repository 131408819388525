import React, { useEffect } from "react";
import { MissionView, usePrimaryMemoryStore } from "../../lib/state";
import { Library } from "hedwigai";
import { useOutsideClick } from "../../lib/utils";

interface ForgotPasswordProps {
  library: Library;
}

const ForgotPasswordMission: React.FC<ForgotPasswordProps> = (
  props: ForgotPasswordProps,
) => {
  const missionRef = React.createRef<HTMLDivElement>();

  const missionView = usePrimaryMemoryStore((state) => state.missionView);
  const setMissionView = usePrimaryMemoryStore((state) => state.setMissionView);
  const [inputEmail, setInputEmail] = React.useState<string>();
  const [sending, setSending] = React.useState<boolean>(false);
  const [triggerSending, setTriggerSending] = React.useState<boolean>(false);
  const [verificationSent, setVerificationSent] =
    React.useState<boolean>(false);
  let signUpTrigger: NodeJS.Timeout;

  useEffect(() => {
    setVerificationSent(false);
  }, [inputEmail]);

  useEffect(() => {
    if (typeof inputEmail == "undefined") return;
    if (inputEmail.split("@").length == 2) {
      clearTimeout(signUpTrigger);
      setSending(true);
      signUpTrigger = setTimeout(() => {
        props.library.forgotPassword(inputEmail).then((success) => {
          setSending(false);
          if (success) {
            setVerificationSent(true);
          }
        });
      }, 3000);
    }
  }, [triggerSending]);

  useOutsideClick(
    missionRef,
    missionView === MissionView.FORGOT_PASSWORD,
    () => {
      setMissionView(undefined);
    },
  );

  return (
    <div
      ref={missionRef}
      className="mission-view signup-mission"
      style={{
        display: missionView == MissionView.FORGOT_PASSWORD ? "flex" : "none",
      }}
    >
      <div className="heading">
        <span
          className="icon close-window-icon"
          onClick={() => setMissionView(undefined)}
        ></span>
      </div>
      <div className="content">
        <div className="input-content">
          Get a Magic Link to Sign-in !
          <label className="input-text-field">
            <input
              type="email"
              placeholder="&nbsp;"
              value={inputEmail}
              onChange={(e) => setInputEmail(e.target.value)}
            />
            <span className="placeholder">Enter Email</span>
            {/* <span className="error-message" aria-live="polite">The email is invalid</span> */}
          </label>
        </div>

        <div className="output-action">
          <div
            onClick={() =>
              verificationSent ? null : setTriggerSending(!triggerSending)
            }
            className={`check-mail ${sending ? "check-sending" : verificationSent ? "" : "check-interact"}`}
          >
            {sending
              ? "Sending ✉️"
              : verificationSent
                ? "Check your email ✉️"
                : "Send Magic Link ✨"}
          </div>
        </div>
      </div>
    </div>
  );
};

export { ForgotPasswordMission };
