import React from "react";

const distinctArray = (arr: any[]): any[] => {
  return arr.filter((value, index, self) => {
    return self.indexOf(value) === index;
  });
};
function getMostCommon<T>(items: T[], k: number): T[] {
  // Create a map to count occurrences of each item
  const itemCounts = new Map<T, number>();

  // Count occurrences of each item
  for (const item of items) {
    if (itemCounts.has(item)) {
      itemCounts.set(item, itemCounts.get(item)! + 1);
    } else {
      itemCounts.set(item, 1);
    }
  }

  // Convert map entries to an array of [item, count] tuples
  const itemEntries = Array.from(itemCounts.entries());

  // Sort items by count in descending order
  itemEntries.sort((a, b) => b[1] - a[1]);

  // Get the top 3 most common items (or fewer if there are less than 3 distinct items)
  const topThreeItems = itemEntries.slice(0, k).map((entry) => entry[0]);

  return topThreeItems;
}

function splitListIntoWindows<T>(list: T[], windowSize: number): T[][] {
  const windows: T[][] = [];

  for (let i = 0; i < list.length; i += windowSize) {
    windows.push(list.slice(i, i + windowSize));
  }

  return windows;
}

const range = (start: number, end: number, step: number = 1) => {
  const result = [];

  if (step === 0) {
    throw new Error("Step cannot be zero");
  }

  if (step > 0) {
    for (let i = start; i <= end; i += step) {
      result.push(i);
    }
  } else {
    for (let i = start; i >= end; i += step) {
      result.push(i);
    }
  }

  return result;
};

export { distinctArray, range, splitListIntoWindows, getMostCommon };
