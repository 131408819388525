import React from "react";
import Asset from "../Asset";

interface FooterProps {}

const Footer: React.FC<FooterProps> = (props: FooterProps) => {
  return (
    <div className="bottom-bar">
      <div className="footer">
        <div className="logo">
          <img src={Asset.LOGO_IMAGE} alt="hedwigAI" />
          <h1>hedwigAI</h1>
        </div>
        <a
          href="https://hedwigai.slite.page/p/gkWz5z2BPopusg/Terms-of-Use"
          target="_blank"
        >
          Terms of Use
        </a>
        <div className="social">
          <a href="https://discord.gg/kH6FvhVc" target="_blank">
            <span className="icon discord-icon"></span>
          </a>
          <a href="https://huggingface.co/hedwigai" target="_blank">
            <span className="icon hugging-face-icon"></span>
          </a>
          <a href="https://in.linkedin.com/company/hedwigai" target="_blank">
            <span className="icon linkedin-icon"></span>
          </a>
          <a href="https://www.youtube.com/@hedwig-ai" target="_blank">
            <span className="icon youtube-icon"></span>
          </a>
          <a href="https://twitter.com/hedwigaicom" target="_blank">
            <span className="icon x-icon"></span>
          </a>
        </div>
      </div>
    </div>
  );
};

export { Footer };
