import { Library } from "hedwigai";
import React, { useEffect } from "react";
import {
  useAccountStore,
  usePrimaryMemoryStore,
  usePrimaryQueryStore,
  useUploadStore,
} from "../../lib/state";
import "react-dropdown/style.css";
import Toast from "../Toast";

interface BoardControlProps {
  library: Library;
}

enum MenuId {
  AddButton,
  TagsButton,
  RatingsButton,
  FavoritesButton,
}

const BoardConfigureUI: React.FC<BoardControlProps> = (
  props: BoardControlProps,
) => {
  const promptInputRef = React.createRef<HTMLInputElement>();
  const [typingTimeout, setTypingTimeout] = React.useState<NodeJS.Timeout>();
  const addUpload = useUploadStore((state) => state.push);
  const setSearchPrompt = usePrimaryQueryStore(
    (state) => state.setSearchPrompt,
  );
  const balance = useAccountStore((state) =>
    typeof state.workspaceCredit != "undefined" &&
    typeof state.workspaceDebit != "undefined"
      ? state.workspaceCredit - state.workspaceDebit
      : -1,
  );
  const handlePromptChange = (ev: Event) => {
    if (ev.target == null) return;
    if (promptInputRef.current == null) return;
    if (ev.key === "Enter") {
      setSearchPrompt(promptInputRef.current.value);
    } else {
      const text = ev.target.value;
      // Clear the previous timeout
      clearTimeout(typingTimeout);
      // Set a new timeout to update state after 1 second
      const newTimeout = setTimeout(() => setSearchPrompt(text), 1000);
      setTypingTimeout(newTimeout);
    }
  };

  const [menuId, setMenuId] = React.useState<MenuId>();
  const [uploadedfiles, setUploadedFiles] = React.useState<FileList>();

  const fileInput = React.useRef<HTMLInputElement>(null);
  const changeFileInput = () => {
    // Display the selected file name
    if (balance < 0) {
      Toast(`Workspace Credits (${balance}) are running low`);
      Toast(`Please purchase more credits`);
    }
    if (
      typeof fileInput.current?.files == "undefined" ||
      fileInput.current.files == null
    )
      return;
    setUploadedFiles(fileInput.current.files);
  };

  useEffect(() => {
    setMenuId(undefined);
    if (typeof uploadedfiles == "undefined") return;
    const count: number = uploadedfiles.length;
    for (let i = 0; i < count; i += 1) {
      const file = uploadedfiles[i];
      addUpload(file);
    }
  }, [uploadedfiles]);

  return (
    <div className="board-configure">
      <div className="text-prompt">
        <input
          type="text"
          accept="image/*, video/*, audio/*"
          ref={promptInputRef}
          placeholder="&#xF002; Search All Assets..."
          onChange={handlePromptChange}
          onKeyDown={handlePromptChange}
        ></input>
        <div className="add">
          <input
            type="file"
            style={{ display: "none" }}
            ref={fileInput}
            multiple={true}
            onChange={changeFileInput}
          />
          <p
            onClick={() =>
              menuId == MenuId.AddButton
                ? setMenuId(undefined)
                : setMenuId(MenuId.AddButton)
            }
          >
            <span className="material-symbols-outlined">add</span>
            Add
          </p>
          <div
            className="add-menu"
            style={{ display: menuId == MenuId.AddButton ? "flex" : "none" }}
          >
            <div
              className="menu-item"
              onClick={() => fileInput.current?.click()}
            >
              <span className="icon file-upload-icon" />
              File Upload
            </div>
            <div className="menu-item">
              <span className="icon folder-upload-icon" />
              Folder Upload
            </div>
            {/* <div className="menu-separator">Import From...</div>
              <div className="menu-item">
                <span className="icon instagram-icon"></span>Instagram
              </div>
              <div className="menu-item">
                <span className="icon meta-icon"></span>Meta Ads
              </div>
              <div className="menu-item">
                <span className="icon google-ads-icon"></span>Google Ads
              </div>
              <div className="menu-item">
                <span className="icon shopify-icon"></span>Shopify
              </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export { BoardConfigureUI };
