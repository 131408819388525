import React, { useEffect } from "react";
import Toast from "../Toast";
import { hexToRgb } from "../../lib/colorscience";

function measureLetterHeight(
  ctx: CanvasRenderingContext2D,
  letter: string,
): number {
  // Measure the height of the letter
  const metrics = ctx.measureText(letter);
  return metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent;
}

interface LockupUsageProps {
  logoUrl: string;
  backgroundUrl: string;
  lockupSpace: string;
  clearSpace: string;
  fillColor: string;
  textColor: string;
  fontFamily: string;
  lockupValue: string;
  canvasWidthInRem: number;
  canvasHeightInRem: number;
  logoSizeInRem: number;
  fontSizeInRem: number;
}

const LockupUsage: React.FC<LockupUsageProps> = (props: LockupUsageProps) => {
  const canvasRef = React.useRef<HTMLCanvasElement>(null);
  const [mouseHover, setMouseHover] = React.useState(false);
  const [imageElement, setImageElement] = React.useState<HTMLImageElement>();
  const [backgroundElement, setBackgroundElement] =
    React.useState<HTMLImageElement>();

  useEffect(() => {
    // handle mouse enter
    const handleMouseEnter = () => {
      setMouseHover(true);
    };
    // handle mouse leave
    const handleMouseLeave = () => {
      setMouseHover(false);
    };
    // add event listeners
    const canvas = canvasRef.current;
    if (canvas) {
      canvas.addEventListener("mouseenter", handleMouseEnter);
      canvas.addEventListener("mouseleave", handleMouseLeave);
    }
    // remove event listeners
    return () => {
      if (canvas) {
        canvas.removeEventListener("mouseenter", handleMouseEnter);
        canvas.removeEventListener("mouseleave", handleMouseLeave);
      }
    };
  }, []);

  const draw = (
    ctx: CanvasRenderingContext2D,
    canvas: HTMLCanvasElement,
    remSize: number,
    img: HTMLImageElement,
  ) => {
    // Clear the canvas
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    // Background
    ctx.fillStyle = props.fillColor;
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    // Typeface
    const fontSize = remSize * props.fontSizeInRem;
    ctx.font = `${fontSize}px '${props.fontFamily}'`; // Adjust font size and family as needed

    // Calculate image and text dimensions and positions
    const imageWidth = remSize * props.logoSizeInRem;
    const imageHeight = remSize * props.logoSizeInRem;
    const textWidth = ctx.measureText(props.lockupValue).width;
    const lockupSpace = measureLetterHeight(ctx, props.lockupSpace);
    const clearSpace = measureLetterHeight(ctx, props.clearSpace);
    const combinedWidth = imageWidth + lockupSpace + textWidth; // 10px gap between image and text

    const imageX = canvas.width / 2.0 - combinedWidth / 2;
    const imageY = (canvas.height - imageHeight) / 2;

    const textX = imageX + imageWidth + lockupSpace;
    const textY = (canvas.height + fontSize * 0.7) / 2;

    // Draw Background
    if (backgroundElement) {
      ctx.drawImage(backgroundElement, 0, 0, canvas.width, canvas.height);
    }

    // Draw a fill rectablge of opacity 0.5 to make the text more readable
    if (props.fillColor != "transparent") {
      const rgb = hexToRgb(props.fillColor);
      ctx.save();
      ctx.fillStyle = "rgba(" + rgb + ", 0.5)";
      ctx.fillRect(
        imageX - clearSpace,
        imageY - clearSpace,
        combinedWidth + 2 * clearSpace,
        imageHeight + 2 * clearSpace,
      );
      ctx.restore();
    }

    if (mouseHover) {
      // Guidelines
      ctx.save();
      ctx.strokeStyle = "dashed";
      ctx.strokeStyle = props.textColor;
      ctx.lineWidth = 1;
      ctx.setLineDash([5, 5]);
      // ClearSpace Axis
      // Left:Left
      ctx.beginPath();
      ctx.moveTo(imageX - clearSpace, 0);
      ctx.lineTo(imageX - clearSpace, canvas.height);
      ctx.stroke();
      // Top:Bottom
      ctx.beginPath();
      ctx.moveTo(0, canvas.height / 2.0 - (imageHeight / 2.0 + lockupSpace));
      ctx.lineTo(
        canvas.width,
        canvas.height / 2.0 - (imageHeight / 2.0 + lockupSpace),
      );
      ctx.stroke();
      // Right:Right
      ctx.beginPath();
      ctx.moveTo(imageX + combinedWidth + lockupSpace, 0);
      ctx.lineTo(imageX + combinedWidth + lockupSpace, canvas.height);
      ctx.stroke();
      // Bottom:Bottom
      ctx.beginPath();
      ctx.moveTo(0, canvas.height / 2.0 + (imageHeight / 2.0 + lockupSpace));
      ctx.lineTo(
        canvas.width,
        canvas.height / 2.0 + (imageHeight / 2.0 + lockupSpace),
      );
      ctx.stroke();
      // End of Guidline
      ctx.restore();
    }

    // Draw image
    ctx.drawImage(img, imageX, imageY, imageWidth, imageHeight);
    // Draw text
    ctx.fillStyle = props.textColor;
    ctx.fillText(props.lockupValue, textX, textY);
  };

  const exportCanvasAsPNG = () => {
    if (canvasRef.current === null) return;
    const canvas = canvasRef.current;
    canvas.toBlob((blob) => {
      if (blob) {
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "logo.png";
        // Append the anchor element to the body
        document.body.appendChild(a);
        // Click the anchor element to trigger the download
        a.click();
        // Remove the anchor element from the body
        document.body.removeChild(a);
        // Revoke the URL to release resources
        URL.revokeObjectURL(url);
        Toast(`Downloading (${canvas.width}px, ${canvas.height}px)`);
      }
    }, "image/png");
  };

  useEffect(() => {
    if (typeof imageElement === "undefined") return;
    draw(
      canvasRef.current?.getContext("2d") as CanvasRenderingContext2D,
      canvasRef.current as HTMLCanvasElement,
      parseFloat(getComputedStyle(document.documentElement).fontSize),
      imageElement as HTMLImageElement,
    );
  }, [mouseHover, imageElement, backgroundElement]);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (canvas) {
      const ctx = canvas.getContext("2d");
      // Set the canvas width and height to 100%
      const remSize = parseFloat(
        getComputedStyle(document.documentElement).fontSize,
      ); // Get size of 1rem in pixels
      const divWidth = props.canvasWidthInRem * remSize; // 20rem
      const divHeight = props.canvasHeightInRem * remSize; // 10rem
      // Set the canvas element size
      canvas.width = divWidth * 2;
      canvas.height = divHeight * 2;
      canvas.style.width = `${divWidth}px`;
      canvas.style.height = `${divHeight}px`;

      if (ctx) {
        ctx.imageSmoothingEnabled = true;
        ctx.imageSmoothingQuality = "high";
        ctx.globalCompositeOperation = "destination-out";
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        // Reset the globalCompositeOperation to default
        ctx.globalCompositeOperation = "source-over";
      }
    }
  }, [canvasRef]);

  useEffect(() => {
    const img = new Image();
    img.src = props.logoUrl;
    img.onload = () => {
      setImageElement(img);
    };
  }, [props.logoUrl]);

  useEffect(() => {
    const img = new Image();
    img.src = props.backgroundUrl;
    img.onload = () => {
      setBackgroundElement(img);
    };
  }, [props.backgroundUrl]);
  return (
    <div className="guideline lockup">
      <canvas ref={canvasRef} />
      <div className="button" onClick={exportCanvasAsPNG}>
        <span className="material-symbols-outlined"> download </span>
      </div>
    </div>
  );
};

export { LockupUsage };
