import { Library } from "hedwigai";
import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { convertImageToBase64 } from "../lib/image";
import BoxSpinner from "../components/Common/BoxSpinner";
import Toast from "../components/Toast";

interface ShareUIProps {
  library: Library;
}

interface ShareData {
  imageUrl: string;
  avatar: string;
  username: string;
  brand: string;
  location: string;
}

const ShareUI: React.FC<ShareUIProps> = ({ library }) => {
  const { hash } = useParams();
  const navigate = useNavigate();
  const [data, setData] = React.useState<ShareData | undefined>(undefined);
  const [copied, setCopied] = React.useState<boolean>(false);
  const [fullscreen, setFullscreen] = React.useState<boolean>(false);

  if (!hash) {
    navigate("/");
    return;
  }

  useEffect(() => {
    library
      .unlockSecret(hash)
      .then(async (r: any) => {
        const res = r.response;
        const memberData = res[0][0];
        const url = res[1];
        const brand = res[2];
        const base64 = await convertImageToBase64(url);
        setData({
          imageUrl: base64,
          avatar: memberData.avatar,
          username: memberData.name,
          brand: brand,
          location:
            memberData.location === "Not, here"
              ? "Planet Earth 🌏"
              : memberData.location,
        });
      })
      .catch((e) => {
        console.log("Error fetching data", e);
        Toast("Unable to fetch data, please try again later");
        setTimeout(() => {
          navigate("/");
        }, 3000);
      });
  }, []);

  const handleDownload = async () => {
    if (!data) return;
    const base64 = await convertImageToBase64(data.imageUrl);
    const a = document.createElement("a");
    a.href = base64;
    const date = new Date().toISOString().split("T")[0];

    a.download = `shared-${hash}-${date}.png`;
    a.click();
  };

  const handleSelect = (
    e:
      | React.MouseEvent<HTMLDivElement, MouseEvent>
      | React.TouchEvent<HTMLDivElement>,
  ) => {
    if (!hash) return;
    const selection = window.getSelection();
    const range = document.createRange();
    range.selectNodeContents(e.currentTarget);
    selection?.removeAllRanges();
    selection?.addRange(range);
  };

  const handleCopy = () => {
    if (!hash) return;
    navigator.clipboard.writeText(`${window.location.origin}/share/${hash}`);
    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  };

  const toggleFullscreen = () => setFullscreen(!fullscreen);

  return (
    <div className="share-ui">
      {data ? (
        <>
          <section className="content">
            <div className="image-container">
              <img
                className="image-shared"
                src={data.imageUrl}
                alt="shared image"
              />
              <div className="overlay">
                <span
                  className="material-symbols-outlined"
                  onClick={handleDownload}
                  onTouchEnd={handleDownload}
                >
                  download
                </span>
                <span
                  className="material-symbols-outlined"
                  onClick={toggleFullscreen}
                  onTouchEnd={toggleFullscreen}
                >
                  fullscreen
                </span>
              </div>
            </div>
            <div className="meta-data">
              <div className="user-details">
                <img src={data.avatar} alt="user avatar" className="avatar" />
                <div className="brand-name">{data.brand}</div>
                <div className="user-data">
                  By {data.username} | {data.location}
                </div>
              </div>
              <div className="share-url">
                <div
                  className="url"
                  onClick={handleSelect}
                  onTouchEnd={handleSelect}
                >
                  {hash ? (
                    `${window.location.origin}/share/${hash}`
                  ) : (
                    <div className="dot-loader">
                      <div className="loading-animation"></div>
                    </div>
                  )}
                </div>
                <div
                  className={`copy-url-button ${hash ? "" : "disabled"}`}
                  onClick={handleCopy}
                  onTouchEnd={handleCopy}
                >
                  {copied ? "Copied!" : "Copy to clipboard"}
                </div>
              </div>
            </div>
          </section>
          {fullscreen && (
            <div className="fullscreen-modal">
              <div className="image-container">
                <img
                  className="image-shared"
                  src={data.imageUrl}
                  alt="shared image"
                />
                <div className="overlay">
                  <span
                    className="material-symbols-outlined"
                    onClick={handleDownload}
                    onTouchEnd={handleDownload}
                  >
                    download
                  </span>
                  <span
                    className="material-symbols-outlined"
                    onClick={toggleFullscreen}
                    onTouchEnd={toggleFullscreen}
                  >
                    fullscreen_exit
                  </span>
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <BoxSpinner />
      )}
    </div>
  );
};

export default ShareUI;
