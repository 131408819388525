import React from "react";

const addEllipsis = (str: string, limit: number): string => {
  if (str.length > limit) {
    return str.slice(0, limit) + "...";
  } else {
    return str;
  }
};

const trimTextWithEllipsis = (text: string) => {
  const maxWidth =
    24 * parseFloat(getComputedStyle(document.documentElement).fontSize); // Convert 24rem to pixels
  const originalText = text;

  // Create a temporary span element to measure the width of the text
  const tempSpan = document.createElement("span");
  tempSpan.style.visibility = "hidden";
  tempSpan.style.position = "absolute";
  tempSpan.style.whiteSpace = "nowrap";
  tempSpan.textContent = text;
  document.body.appendChild(tempSpan);

  // If text width exceeds max width, trim it and append an ellipsis
  if (tempSpan.offsetWidth > maxWidth) {
    let trimmedText = text;
    while (tempSpan.offsetWidth > maxWidth && trimmedText.length > 0) {
      trimmedText = trimmedText.slice(0, -1);
      tempSpan.textContent = trimmedText + "...";
    }
    // Clean up the temporary span
    document.body.removeChild(tempSpan);
    return trimmedText + "...";
  } else {
    return text;
  }
};

const isValidEmail = (email: string): boolean => {
  // Regular expression pattern for validating email addresses
  const emailRegex: RegExp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  // Test the email against the regular expression
  return emailRegex.test(email);
};

const toTitleCase = (text: string): string => {
  return text[0].toUpperCase() + text.slice(1);
};

const stringToNumericValue = (str: string): number => {
  let numericValue = 0;
  for (let i = 0; i < str.length; i++) {
    // Convert each character to its ASCII value and add it to the numeric value
    numericValue += str.charCodeAt(i);
  }
  return numericValue;
};

const removeNonAlphaNumFromEnd = (input: string): string => {
  // Helper function to check if a character is alphabetic
  function isAlpha(char: string): boolean {
    return /^[A-Za-z1-9]+$/.test(char); // Use regular expression to check if the character is alphabetic
  }

  let endIndex = input.length - 1; // Start from the end of the string
  // Iterate from the end of the string
  while (endIndex >= 0 && !isAlpha(input[endIndex])) {
    endIndex--; // Move the endIndex to the left until we find an alphabetic character
  }
  return input.substring(0, endIndex + 1); // Return the substring from the start to the endIndex (inclusive)
};

function replaceWithParagraphs(text: string, stringsToReplace: string[]) {
  console.log(stringsToReplace);
  // Escape special characters in strings to replace
  const escapedStrings = stringsToReplace.map((str) =>
    str.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"),
  );

  // Create a regular expression pattern to match any of the strings to replace
  const pattern = new RegExp(`(${escapedStrings.join("|")})`, "gi");

  // Replace matched strings with <p> tags enclosing the matched string
  const replacedText = text.replace(pattern, "<span>$1</span>");

  return replacedText;
}

export {
  stringToNumericValue,
  addEllipsis,
  replaceWithParagraphs,
  isValidEmail,
  toTitleCase,
  trimTextWithEllipsis,
  removeNonAlphaNumFromEnd,
};
