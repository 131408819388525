import React, { useEffect } from "react";
import { redirect, useLocation, useNavigate } from "react-router-dom";
import Asset from "../Asset";
import { ROUTES } from "../lib/Route";
import {
  HubContent,
  HeroContent,
  ImageContent,
  SDKContent,
  VideoContent,
} from "../lib/content";
import { MemberSignUI } from "../components/Auth/member-signin";
import { Library } from "hedwigai";
import { SignUpMission } from "../components/Mission/signup-mission";
import { Integration, Invoice, Member, useAccountStore } from "../lib/state";
import Toast from "../components/Toast";
import { ForgotPasswordMission } from "../components/Mission/forgot-password-mission";
import { Footer } from "./Footer";
import { Header } from "./Header";
import { getCurrentLocation } from "../lib/location";

interface MainProps {
  library: Library;
}

const MainUI: React.FC<MainProps> = (props: MainProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const sign = useAccountStore((state) => state.sign);
  const setWorkspace = useAccountStore((state) => state.setWorkspace);
  const setAccountDebit = useAccountStore((state) => state.setAccountDebit);
  const setMemberLocation = useAccountStore((state) => state.setMemberLocation);
  const setMember = useAccountStore((state) => state.setMember);
  const setMemberList = useAccountStore((state) => state.setMemberList);
  const setInvoiceList = useAccountStore((state) => state.setInvoiceList);
  const setIntegrationList = useAccountStore(
    (state) => state.setIntegrationList,
  );

  useEffect(() => {
    console.log(`Location: ${location.pathname}`);
    if (location.pathname == ROUTES.ACTIVATE) {
      const searchParams = new URLSearchParams(location.search);
      Toast(`Wait for a moment while we activate your account`);
      props.library
        .activateMember(
          searchParams.get("e") as string,
          searchParams.get("s") as string,
        )
        .then((data: any) => {
          console.log(`Activation: ${data["email"]}`);
          const email = data["email"];
          Toast(`Searching in the waitlist for ${email}`);
          setTimeout(() => {
            if (email) {
              Toast(`Assembling the Platform for ${email}`);
              sign(email, props.library.getIdToken());
              props.library.getMember().then((item: any) => {
                const value = item[0];
                setMember(
                  value["name"] as string,
                  value["avatar"] as string,
                  value["generation"] as string,
                  value["gender"] as string,
                  value["location"] as string,
                  value["access_level"] as boolean,
                  value["active_brand"] as string | null,
                );
                navigate(ROUTES.STUDIO);
              });

              // props.library.getWorkspace().then((item) => {
              //   const value = item[0];
              //   setWorkspace(
              //     value["name"] as string,
              //     value["logo"] as string,
              //     value["plan"] as string,
              //     value["credit"] as number,
              //   );
              //   setAccountDebit(0 as number);
              // });

              props.library.getMemberList().then((result: any) => {
                const memberList: Member[] = result.map((value: any) => {
                  return {
                    name: value["name"],
                    logo: value["avatar"],
                    email: value["email"],
                    generation: value["generation"],
                    gender: value["gender"],
                    location: value["location"],
                  } as Member;
                });
                setMemberList(memberList);
              });

              // props.library.getInvoiceList().then((item) => {
              //   const invoiceList: Invoice[] = item.map((value) => {
              //     return {
              //       name: value["name"],
              //       timestamp: value["timestamp"],
              //       credit: value["credit"],
              //       paid: value["paid"],
              //     } as Invoice;
              //   });
              //   setInvoiceList(invoiceList);
              // });

              // props.library.getIntegrationList().then((item) => {
              //   const integrationList: Integration[] = [
              //     {
              //       name: "Google Drive",
              //       lastSync: "23rd April, 2024",
              //       icon: "google-drive-icon",
              //       connected: true,
              //     },
              //     {
              //       name: "Shopify",
              //       lastSync: "15th March, 2024",
              //       icon: "shopify-icon",
              //       connected: true,
              //     },
              //   ];
              //   setIntegrationList(integrationList);
              // });

              getCurrentLocation().then((value) => {
                if (typeof value == "undefined") return;
                setMemberLocation(value);
              });
            } else {
              Toast("Email Verification Failed 😥");
            }
          }, 1000);
        })
        .catch((err) => {
          Toast(`Email Verification Failed 😥 ${err}`);
        });
    }

    if (location.pathname == ROUTES.MAGIC_LINK) {
      console.log(`Magic Link: ${location.search}`);
      const searchParams = new URLSearchParams(location.search);
      props.library
        .magicLink(
          searchParams.get("e") as string,
          searchParams.get("s") as string,
        )
        .then((data: any) => {
          const response = data["response"];
          console.log(`MagicResponse: ${response}`);
          // props.library.setIdToken(response["idToken"]);
          const email = response["email"];
          setTimeout(() => {
            if (email) {
              Toast(`Assembling the Platform for ${email}`);
              sign(email, props.library.getIdToken());
              props.library.getMember().then((item: any) => {
                const value = item[0];
                setMember(
                  value["name"] as string,
                  value["avatar"] as string,
                  value["generation"] as string,
                  value["gender"] as string,
                  value["location"] as string,
                  value["access_level"] as boolean,
                  value["active_brand"] as string | null,
                );
                navigate(ROUTES.STUDIO);
              });

              // props.library.getWorkspace().then((item) => {
              //   const value = item[0];
              //   setWorkspace(
              //     value["name"] as string,
              //     value["logo"] as string,
              //     value["plan"] as string,
              //     value["credit"] as number,
              //   );
              //   setAccountDebit(0 as number);
              // });

              props.library.getMemberList().then((result: any) => {
                const memberList: Member[] = result.map((value: any) => {
                  return {
                    name: value["name"],
                    logo: value["avatar"],
                    email: value["email"],
                    generation: value["generation"],
                    gender: value["gender"],
                    location: value["location"],
                  } as Member;
                });
                setMemberList(memberList);
              });

              // props.library.getInvoiceList().then((item) => {
              //   const invoiceList: Invoice[] = item.map((value) => {
              //     return {
              //       name: value["name"],
              //       timestamp: value["timestamp"],
              //       credit: value["credit"],
              //       paid: value["paid"],
              //     } as Invoice;
              //   });
              //   setInvoiceList(invoiceList);
              // });

              // props.library.getIntegrationList().then((item) => {
              //   setIntegrationList([]);
              // });

              getCurrentLocation().then((value) => {
                if (typeof value == "undefined") return;
                setMemberLocation(value);
              });
            } else {
              Toast("Email Verification Failed 😥");
            }
          }, 1000);
        });
    }

    if (location.pathname == ROUTES.BILLING_CREDIT_CHECKOUT) {
      const searchParams = new URLSearchParams(location.search);
      const checkoutId = searchParams.get("checkout_id") as string;
      const token = searchParams.get("token") as string;
      props.library
        .billAccountCreditCheckout(checkoutId, token)
        .then((success) => {
          if (success) {
            props.library.getInvoiceList().then((item: any) => {
              const invoiceList: Invoice[] = item.map((value: any) => {
                return {
                  name: value["name"],
                  timestamp: value["timestamp"],
                  credit: value["credit"],
                  paid: value["paid"],
                } as Invoice;
              });
              setInvoiceList(invoiceList);
            });
            navigate(ROUTES.STUDIO);
            Toast(`Thank you for your purchase!`);
          } else {
            navigate(ROUTES.STUDIO);
            Toast(`Sorry your purchase was not completed 😞 !`);
          }
        });

      navigate(ROUTES.STUDIO);
    }

    if (location.pathname == ROUTES.BILLING_SUBSCRIPTION_CHECKOUT) {
      const searchParams = new URLSearchParams(location.search);
      const checkoutId = searchParams.get("checkout_id") as string;
      const token = searchParams.get("token") as string;
      props.library
        .billAccountSubscriptionCheckout(checkoutId, token)
        .then((success) => {
          if (success) {
            props.library.getInvoiceList().then((item: any) => {
              const invoiceList: Invoice[] = item.map((value: any) => {
                return {
                  name: value["name"],
                  timestamp: value["timestamp"],
                  credit: value["credit"],
                  paid: value["paid"],
                } as Invoice;
              });
              setInvoiceList(invoiceList);
            });
            navigate(ROUTES.STUDIO);
            Toast(`Welcome ✨ Pro User!`);
          } else {
            navigate(ROUTES.STUDIO);
            Toast(`Sorry your subscription was not completed 😞 !`);
          }
        });

      navigate(ROUTES.STUDIO);
    }

    if (location.pathname == ROUTES.INVITE) {
      const searchParams = new URLSearchParams(location.search);
      const encodedEmail = searchParams.get("e") as string;
      const email = encodedEmail ? atob(encodedEmail) : "invalid@gmail.com";
      Toast(`Welcome ${email}!`);
    }
  }, [location]);

  return (
    <>
      <Header />
      <div className="main">
        <SignUpMission library={props.library} />
        <ForgotPasswordMission library={props.library} />
        <div className="headline">
          <div className="left-column">
            <h1>{HeroContent.subheading}</h1>
            <h2>{HeroContent.description}</h2>
            <MemberSignUI library={props.library} />
          </div>
          <div className="right-column">
            <div className="visual">
              <img id="static" src={Asset.OWL_ICON}></img>
            </div>
          </div>
        </div>
        <div className="highlight">
          <div className="feature">
            <div className="visual">
              <img
                id="static"
                src={Asset.HUB_ICON}
                onClick={() => navigate(ROUTES.HUB_ALL_ASSETS)}
              ></img>
              <img
                id="motion"
                src={Asset.HUB_MOTION_ICON}
                onClick={() => navigate(ROUTES.HUB_ALL_ASSETS)}
              ></img>
            </div>
            <div className="content">
              <h2>{HubContent.heading}</h2>
              <h3>{HubContent.subheading}</h3>
            </div>
            <div
              className="do-it-button"
              onClick={() => navigate(ROUTES.HUB_ALL_ASSETS)}
            >
              Goto {HubContent.heading} ↘️
            </div>
          </div>
          <div className="feature">
            <div className="visual">
              <img
                id="static"
                src={Asset.IMAGE_ICON}
                onClick={() => navigate(ROUTES.STUDIO)}
              ></img>
              <img
                id="motion"
                src={Asset.IMAGE_MOTION_ICON}
                onClick={() => navigate(ROUTES.STUDIO)}
              ></img>
            </div>
            <div className="content">
              <h2>{ImageContent.heading}</h2>
              <h3>{ImageContent.subheading}</h3>
            </div>
            <div
              className="do-it-button"
              onClick={() => navigate(ROUTES.STUDIO)}
            >
              Goto {ImageContent.heading} ↘️
            </div>
          </div>
          <div className="feature">
            <div className="visual">
              <img
                id="static"
                src={Asset.VIDEO_ICON}
                onClick={() => navigate(ROUTES.STUDIO)}
              ></img>
              <img
                id="motion"
                src={Asset.VIDEO_MOTION_ICON}
                onClick={() => navigate(ROUTES.STUDIO)}
              ></img>
            </div>
            <div className="content">
              <h2>{VideoContent.heading}</h2>
              <h3>{VideoContent.subheading}</h3>
            </div>
            <div
              className="do-it-button"
              onClick={() => navigate(ROUTES.STUDIO)}
            >
              Goto {VideoContent.heading} ↘️
            </div>
          </div>
        </div>
        <div className="info-section">
          <div className="info-tile">
            <div className="right-column column">
              <img src={Asset.HUB_ICON}></img>
            </div>
            <div className="left-column column">
              <h1>{HubContent.subheading}</h1>
              <h2>{HubContent.description}</h2>
            </div>
          </div>
          <div className="info-tile">
            <div className="left-column column">
              <img src={Asset.IMAGE_ICON}></img>
            </div>
            <div className="right-column column">
              <h1>{ImageContent.subheading}</h1>
              <h2>{ImageContent.description}</h2>
            </div>
          </div>
          <div className="info-tile">
            <div className="left-column column">
              <img src={Asset.VIDEO_ICON}></img>
            </div>
            <div className="right-column column">
              <h1>{VideoContent.subheading}</h1>
              <h2>{VideoContent.description}</h2>
            </div>
          </div>
          <div className="info-tile">
            <div className="right-column column">
              <img src={Asset.SDK_ICON}></img>
            </div>
            <div className="left-column column">
              <h1>{SDKContent.subheading}</h1>
              <h2>{SDKContent.description}</h2>
              <div
                className="do-it-button"
                onClick={() =>
                  window.open("https://docs.hedwigai.com/", "_blank")
                }
              >
                Goto Docs ↘️
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export { MainUI };
