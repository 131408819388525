import React, { useState, useEffect } from "react";

interface ToolTipProps {
  text: string;
  position?: string;
  children: React.ReactNode;
}

const ToolTip: React.FC<ToolTipProps> = ({
  text,
  children,
  position = "t",
}) => {
  const [tooltipStyle, setTooltipStyle] = useState({});

  useEffect(() => {
    const cM = (value: number) => {
      return `${value * 0.25}rem`;
    };

    const [pos, marginValue] = position.split("-");
    const margin = parseInt(marginValue || "0", 10);

    let newStyle = {};
    switch (pos) {
      case "t":
        newStyle = {
          bottom: "100%",
          left: "50%",
          transform: "translateX(-50%)",
          marginBottom: cM(margin),
        };
        break;
      case "r":
        newStyle = {
          top: "50%",
          left: "100%",
          transform: "translateY(-50%)",
          marginLeft: cM(margin),
        };
        break;
      case "b":
        newStyle = {
          top: "100%",
          left: "50%",
          transform: "translateX(-50%)",
          marginTop: cM(margin),
        };
        break;
      case "l":
        newStyle = {
          top: "50%",
          right: "100%",
          transform: "translateY(-50%)",
          marginRight: cM(margin),
        };
        break;
      case "tr":
        newStyle = {
          bottom: "100%",
          left: "100%",
          transform: "translate(-100%, 100%)",
          marginBottom: cM(margin),
          marginLeft: cM(margin),
        };
        break;
      case "tl":
        newStyle = {
          bottom: "100%",
          right: "100%",
          transform: "translate(100%, 100%)",
          marginBottom: cM(margin),
          marginRight: cM(margin),
        };
        break;
      case "bl":
        newStyle = {
          top: "100%",
          right: "100%",
          transform: "translate(100%, -100%)",
          marginTop: cM(margin),
          marginRight: cM(margin),
        };
        break;
      case "br":
        newStyle = {
          top: "100%",
          left: "100%",
          transform: "translate(-100%, -100%)",
          marginTop: cM(margin),
          marginLeft: cM(margin),
        };
        break;
      default:
        break;
    }
    setTooltipStyle(newStyle);
  }, [position]);

  return (
    <div className="tooltip-wrapper">
      {children}
      <div className={`tooltip-box`} style={tooltipStyle}>
        <span className="tooltip-text">{text}</span>
      </div>
    </div>
  );
};

export default ToolTip;
