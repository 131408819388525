import Asset from "../../Asset";
import { ImagePreview } from "../../lib/hedwigai";
import React from "react";
import Toast from "../Toast";
import { useAccountStore, useUploadStore } from "../../lib/state";

interface OnboardingDamProps {}

const OnboardingDam = (props: OnboardingDamProps) => {
  const [uploadedfiles, setUploadedFiles] = React.useState<FileList>();
  const fileTypeSupported = ["PNG", "WEBP", "JPEG", "GLB", "MP4", "PDF"];
  const addUpload = useUploadStore((state) => state.push);
  const balance = useAccountStore((state) =>
    typeof state.workspaceCredit != "undefined" &&
    typeof state.workspaceDebit != "undefined"
      ? state.workspaceCredit - state.workspaceDebit
      : -1,
  );

  const fileInput = React.useRef<HTMLInputElement>(null);
  const changeFileInput = () => {
    // Display the selected file name
    if (balance < 0) {
      Toast(`Workspace Credits (${balance}) are running low`);
      Toast(`Please purchase more credits`);
    }
    if (
      typeof fileInput.current?.files == "undefined" ||
      fileInput.current.files == null
    )
      return;
    setUploadedFiles(fileInput.current.files);
  };

  React.useEffect(() => {
    if (typeof uploadedfiles == "undefined") return;
    const count: number = uploadedfiles.length;
    for (let i = 0; i < count; i += 1) {
      const file = uploadedfiles[i];
      addUpload(file);
    }
  }, [uploadedfiles]);

  const getIcon = (value: string) => {
    if (value == "GLB") {
      return Asset.THREED_ICON;
    } else if (value == "PDF") {
      return Asset.DOCUMENT_ICON;
    } else if (value == "MP4") {
      return Asset.VIDEO_CAMERA_ICON;
    } else {
      return Asset.IMAGE_FILE_ICON;
    }
  };
  return (
    <div className="onboarding-dam">
      <h1>Oops! Nothing here.</h1>
      <div className="symbol">
        <img
          src={Asset.ZIP_FILE_ICON}
          onClick={() => fileInput.current?.click()}
        />
        <h1>Upload A Zip File</h1>
      </div>
      <input
        type="file"
        style={{ display: "none" }}
        ref={fileInput}
        multiple={true}
        onChange={changeFileInput}
      />
      <div className="support-assets">
        <div className="support-info">Supported File Types</div>
        {fileTypeSupported.map((value) => {
          return (
            <div className="grid-item" key={value}>
              <img
                src={getIcon(value)}
                style={{ marginRight: value == "PDF" ? "0.5rem" : "0" }}
                onClick={() => fileInput.current?.click()}
              />
              {value}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export { OnboardingDam };
