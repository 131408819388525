import { Library } from "hedwigai";
import React, { useEffect } from "react";
import {
  LoadingStatus,
  usePrimaryMemoryStore,
  usePrimaryQueryStore,
} from "../../lib/state";
import { ImagePreview } from "../../lib/hedwigai";
import { LoadingMessage } from "../loading-message";
import { Carousel } from "./asset-carousel";
import { getMostCommon } from "../../lib/set";
import { OnboardingDam } from "./onboarding-dam";

interface AssetsViewerProps {
  library: Library;
}

interface CategoryGroup {
  heading: string;
  items: ImagePreview[];
}

interface CategoryResult {
  1: CategoryGroup;
  2: CategoryGroup;
  3: CategoryGroup;
}

const AssetsViewerUI: React.FC<AssetsViewerProps> = (
  props: AssetsViewerProps,
) => {
  const [loadingStatus, setLoadingStatus] = React.useState<LoadingStatus>(
    LoadingStatus.loaded,
  );
  const primaryAssets = usePrimaryMemoryStore((state) => state.assets);
  const push = usePrimaryMemoryStore((state) => state.push);
  const clean = usePrimaryMemoryStore((state) => state.clean);
  const searchPrompt = usePrimaryQueryStore((state) => state.searchPrompt);
  const searchQuery = usePrimaryMemoryStore((state) => state.searchQuery);
  const setSearchQuery = usePrimaryMemoryStore((state) => state.setSearchQuery);
  const [categoryResult, setCategoryResult] = React.useState<CategoryResult>();

  const searchCallback = (count: number) => {
    if (searchPrompt == searchQuery && primaryAssets.length > 0) {
      setLoadingStatus(LoadingStatus.loaded);
      return;
    }

    if (searchPrompt.length == 0) {
      setLoadingStatus(LoadingStatus.loaded);
      return;
    }
    setLoadingStatus(LoadingStatus.loading);
    props.library
      .searchImage(searchPrompt, count)
      .then((frames) => {
        clean();
        setSearchQuery(searchPrompt);
        let index = 0;
        for (const item of frames["response"]) {
          const asset = {
            id: item["id"],
            index: index,
            image: item["preview_image"],
            content: item["content"] || [],
          };
          index += 1;
          push(asset);
        }
        setLoadingStatus(LoadingStatus.loaded);
      })
      .catch((err) => {});
  };

  useEffect(() => searchCallback(30), [searchPrompt]);

  useEffect(() => {
    if (loadingStatus != LoadingStatus.loaded) return;

    const itemCategory = primaryAssets.flatMap((item) => item.content);
    const mostCommon = getMostCommon(itemCategory, 3);

    const categoryA: CategoryGroup = {
      heading: "",
      items: [],
    };
    const categoryB: CategoryGroup = {
      heading: "",
      items: [],
    };
    const categoryC: CategoryGroup = {
      heading: "",
      items: [],
    };
    primaryAssets.toReversed().forEach((item) => {
      if (
        item.content.length > 0 &&
        mostCommon.length > 0 &&
        item.content.findIndex((x) => x == mostCommon[0])
      ) {
        categoryA.items.push(item);
        categoryA.heading =
          categoryA.heading.length > 0 ? categoryA.heading : item.content[0];
      } else if (
        item.content.length > 0 &&
        mostCommon.length > 1 &&
        item.content.findIndex((x) => x == mostCommon[1])
      ) {
        categoryB.items.push(item);
        categoryB.heading =
          categoryB.heading.length > 0 ? categoryB.heading : item.content[0];
      } else if (
        item.content.length > 0 &&
        mostCommon.length > 2 &&
        item.content.findIndex((x) => x == mostCommon[2])
      ) {
        categoryC.items.push(item);
        categoryC.heading =
          categoryC.heading.length > 0 ? categoryC.heading : item.content[0];
      } else {
        categoryC.items.push(item);
        if (
          item.content.length > 0 &&
          !categoryC.heading.includes(item.content[0])
        ) {
          if (categoryC.heading.split(", ").length < 3) {
            categoryC.heading = categoryC.heading + ", " + item.content[0];
          } else if (!categoryC.heading.endsWith(", etc.")) {
            categoryC.heading = categoryC.heading + ", etc.";
          }
        }
      }
    });

    categoryA.heading += ` (${categoryA.items.length})`;
    categoryB.heading += ` (${categoryB.items.length})`;
    categoryC.heading += ` (${categoryC.items.length})`;

    setCategoryResult({
      1: categoryA,
      2: categoryB,
      3: categoryC,
    });
  }, [loadingStatus]);

  const [openMenuId, setOpenMenuId] = React.useState<string>();

  return (
    <div
      className="assets-viewer"
      onClick={() =>
        typeof openMenuId != "undefined" ? setOpenMenuId(undefined) : null
      }
    >
      <div
        className="assets-heading"
        style={{
          visibility:
            primaryAssets?.length == 0 ||
            loadingStatus == LoadingStatus.loading ||
            typeof primaryAssets == "undefined"
              ? "hidden"
              : "visible",
        }}
      >
        Because you searched for{" "}
        {searchQuery == "*" ? "everything" : ' "' + searchQuery + '"'} (
        {primaryAssets?.length})
      </div>

      {(typeof categoryResult == "undefined" ||
        categoryResult["1"].items.length == 0 ||
        loadingStatus == LoadingStatus.loading) &&
        (loadingStatus == LoadingStatus.loading ? (
          <LoadingMessage
            message={
              loadingStatus == LoadingStatus.loading
                ? searchPrompt
                : `No Assets for Prompt:${searchPrompt}`
            }
          />
        ) : (
          <OnboardingDam />
        ))}

      {loadingStatus != LoadingStatus.loading &&
        typeof categoryResult != "undefined" &&
        typeof categoryResult["1"] != "undefined" &&
        categoryResult["1"].items.length > 0 && (
          <Carousel
            headline={categoryResult["1"].heading}
            items={categoryResult["1"].items}
          />
        )}

      {loadingStatus != LoadingStatus.loading &&
        typeof categoryResult != "undefined" &&
        typeof categoryResult["2"] != "undefined" &&
        categoryResult["2"].items.length > 0 && (
          <Carousel
            headline={categoryResult["2"].heading}
            items={categoryResult["2"].items}
          />
        )}
      {loadingStatus != LoadingStatus.loading &&
        typeof categoryResult != "undefined" &&
        typeof categoryResult["3"] != "undefined" &&
        categoryResult["3"].items.length > 0 && (
          <Carousel
            headline={categoryResult["3"].heading}
            items={categoryResult["3"].items}
          />
        )}
    </div>
  );
};

export { AssetsViewerUI };
