import { useState, useEffect } from "react";
import { useAccountStore } from "./state";
import { Library } from "hedwigai";

const useLibrary = () => {
  const token = useAccountStore((state) => state.token);
  const exp = useAccountStore((state) => state.exp);
  const reset = useAccountStore((state) => state.reset);
  const [library, setLibrary] = useState<Library>(new Library());

  useEffect(() => {
    if (exp && Date.now() > exp) {
      reset();
    } else if (token && exp) {
      library.setIdToken(token);
      setTimeout(() => {
        reset();
      }, exp - Date.now());
    }
  }, [token, exp, reset, library]);

  return library;
};

export default useLibrary;
