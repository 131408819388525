import LOGO_IMAGE from "../image/logo.png";
import NPM_LOGO from "../image/npm-icon.png";
import ALPHA_ICON from "../image/alpha-icon.png";
import PREVIEW_MEDIA_PAGE from "../image/media-preview.png";
import HEART_RED_ICON from "../image/heart-red.svg";
import OWL_CAT from "../image/owl-cat.webp";
import OWL_RUNNING from "../image/owl-running.webp";
import OWL_SKETCH from "../image/owl-sketch.webp";
import IMAGE_FILE_ICON from "../image/image-file-icon.png";
import ZIP_FILE_ICON from "../image/zip-file.png";
import VIDEO_CAMERA_ICON from "../image/video-camera-icon.png";
import THREED_ICON from "../image/threed-icon.png";
import DOCUMENT_ICON from "../image/document-icon.png";
import SPARKLING from "../image/sparkling.webp";
import TEXT_FIELD from "../image/text-field-icon.png";
import SELECT_FLOW from "../image/select-flow.gif";
import SELECT_FLOW_STATIC from "../image/select-flow-static.png";
import NEW_FLOW_STATIC from "../image/new-flow-static.png";
import NEW_FLOW from "../image/new-flow.gif";
import BRING_FORWARD_ICON from "../image/bring-forward.png";
import SEND_BACKWARD_ICON from "../image/send-backward.png";

export default {
  LOGO_IMAGE: LOGO_IMAGE,
  NPM_LOGO: NPM_LOGO,
  ALPHA_ICON: ALPHA_ICON,
  USER_LOGO_MISSION_ICON:
    "https://t3.ftcdn.net/jpg/06/33/54/78/360_F_633547842_AugYzexTpMJ9z1YcpTKUBoqBF0CUCk10.jpg",
  HEART_RED_ICON: HEART_RED_ICON,
  COMING_SOON_ICON: "https://i.imgur.com/JQojgTf.png",
  IMAGE_ICON: "https://i.imgur.com/KOiSH54.png",
  IMAGE_MOTION_ICON: "https://i.giphy.com/AdMtXX3QCtlhO3kJAi.webp",
  HUB_ICON: "https://i.imgur.com/2cd7oJZ.png",
  HUB_MOTION_ICON: "https://i.giphy.com/USEtZi1YYFZCSbkngz.webp",
  VIDEO_ICON: "https://i.imgur.com/W23rPGY.png",
  VIDEO_MOTION_ICON: "https://i.giphy.com/2dMEdYriKrmfk2x5Dq.webp",
  OWL_ICON: "https://i.imgur.com/C9ocweW.png",
  OWL_MOTION_ICON: "https://i.giphy.com/x5KkPW2ETfJvE3w7BS.webp",
  SDK_ICON: "https://i.imgur.com/9Mcryyu.png",
  PREVIEW_MEDIA_PAGE: PREVIEW_MEDIA_PAGE,
  OWL_CAT: OWL_CAT,
  OWL_RUNNING: OWL_RUNNING,
  OWL_SKETCH: OWL_SKETCH,
  IMAGE_FILE_ICON: IMAGE_FILE_ICON,
  ZIP_FILE_ICON: ZIP_FILE_ICON,
  VIDEO_CAMERA_ICON: VIDEO_CAMERA_ICON,
  THREED_ICON: THREED_ICON,
  DOCUMENT_ICON: DOCUMENT_ICON,
  SPARKLING: SPARKLING,
  TEXT_FIELD: TEXT_FIELD,
  SELECT_FLOW: SELECT_FLOW,
  SELECT_FLOW_STATIC: SELECT_FLOW_STATIC,
  NEW_FLOW_STATIC: NEW_FLOW_STATIC,
  NEW_FLOW: NEW_FLOW,
  BRING_FORWARD_ICON: BRING_FORWARD_ICON,
  SEND_BACKWARD_ICON: SEND_BACKWARD_ICON,
};
