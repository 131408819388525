import { AspectRatio, Library } from "hedwigai";
import {
  ImageRecord,
  StudioElement,
  StudioElementTypes,
  addNewTextElement,
  backgroundBoilerPlate,
  orderElementsByCategory,
  productBoilerPlate,
  uuid,
} from "../studio";
import {
  base64ToBlob,
  convertImageToBase64,
  getImageDimensions,
} from "../../../lib/image";
import _ from "lodash";
import { CSSProperties } from "react";
import { toPng } from "html-to-image";
import Toast from "../../Toast";
import { useAccountStore } from "../../../lib/state";

export interface Position {
  x: number;
  y: number;
}
export interface Size {
  height: number;
  width: number;
}

export enum TemplateType {
  SOLID = "solid",
  GRADIENT = "gradient",
  PROVERB = "proverb",
  PHOTOGRAPHIC = "photographic",
}

export interface ElementData {
  key: string;
  box: number[];
  category: string[];
  properties: {
    css: CSSProperties;
    // [key: string]: string;
  };
}
export interface WorkTemplate {
  width: number;
  height: number;
  background: ElementData;
  element: ElementData[];
}

export const base64ImageRegex =
  /^data:image\/(jpeg|jpg|gif|png|webp);base64,([A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=)?$/;

export function isValidBase64Image(base64String: string): boolean {
  if (base64String.length > 10000) return true;
  return base64ImageRegex.test(base64String);
}

export interface SearchResult {
  srcId: string;
  image: string;
  multiview?: boolean;
  bbox: number[];
  size: { width: number; height: number };
}

export function cssToObject(css: any) {
  Object.keys(css).forEach((key: string) => {
    if (key.includes("-")) {
      const reactKey = key.replace(/-([a-z])/g, (g) => g[1].toUpperCase());
      css[reactKey] = css[key];
      delete css[key];
    }
  });
}

export const migrateImageRecordKey = (
  imageRecord: ImageRecord,
  elements: StudioElement[],
) => {
  const newRecord: ImageRecord = {};
  const newElements = _.cloneDeep(elements);
  Object.keys(imageRecord).forEach((key) => {
    newRecord[imageRecord[key].srcId] = imageRecord[key];
  });

  newElements.forEach((element) => {
    if (element.type !== StudioElementTypes.text) {
      element.imageSrc = imageRecord[element.imageSrc].srcId;
    }
  });

  return { newRecord, newElements };
};

export function normalizeBoundingBox(
  initialWidth: number,
  initialHeight: number,
  boundingBox: number[],
  finalWidth?: number,
  finalHeight?: number,
): number[] {
  const [x, y, x1, y1] = boundingBox;

  const normalizedBox: number[] = [
    x / initialWidth,
    y / initialHeight,
    (x1 - x) / initialWidth,
    (y1 - y) / initialHeight,
  ];

  if (finalWidth && finalHeight) {
    return [
      normalizedBox[0] * finalWidth,
      normalizedBox[1] * finalHeight,
      normalizedBox[2] * finalWidth,
      normalizedBox[3] * finalHeight,
    ];
  }
  return normalizedBox;
}

export const fetchImage = async (
  library: Library,
  srcId: string,
): Promise<string> => {
  try {
    const r = (await library.getFileUrl(srcId)) as any;
    const res = r.response;
    await new Promise((resolve) => setTimeout(resolve, 3000));
    // @ts-ignore
    const base64 = await convertImageToBase64(res[0].url);
    return base64;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const calculateElementPositionAndSize = (
  bbox: number[],
  width: number,
  height: number,
) => {
  const [x, y, x1, y1] = bbox;
  return {
    position: {
      x: x * width,
      y: y * height,
    },
    size: {
      width: (x1 - x) * width,
      height: (y1 - y) * height,
    },
  };
};

export const createBlankWork = async (
  library: Library,
  email: string,
  aspectRatio: AspectRatio,
) => {
  const workId = await uuid(email, 24);
  const size = () => {
    const [width, height] = aspectRatio.split(":").map((n) => parseInt(n));
    return { width, height };
  };
  await Promise.all([
    library.setWorkMeta(
      workId,
      `Untitled - blank`,
      "",
      new Date().toISOString(),
    ),
    library.setWorkData(
      workId,
      JSON.stringify({
        workId,
        name: `Untitled - blank`,
        theme: "",
        size: size(),
        imageRecord: {},
        elements: [],
      }),
    ),
  ]);
  return workId;
};

export const downloadImageFromDom = async (
  element: HTMLElement,
  filename: string,
  options: {
    resolution?: "ultraLow" | "low" | "normal" | "high" | "ultra";
    download?: boolean;
  } = {},
): Promise<string> => {
  const resolutionSettings: Record<string, number> = {
    ultraLow: 0.1,
    low: 0.5,
    normal: 1,
    high: 3,
    ultra: 5,
  };

  const scale = resolutionSettings[options.resolution ?? "normal"];
  try {
    const dataUrl = await toPng(element, {
      cacheBust: true,
      pixelRatio: scale,
    });

    if (options.download) {
      const a = document.createElement("a");
      a.href = dataUrl;
      a.download = `${filename}.png`;
      a.click();
    }

    console.log(dataUrl);

    return dataUrl;
  } catch (e) {
    console.error("Error generating image:", e);
    return "";
  }
};

export const base64ToDownloadLink = (base64: string, filename: string) => {
  const a = document.createElement("a");
  a.href = base64;
  a.download = `${filename}.png`;
  a.click();
};

export const createRemix = async (
  library: Library,
  asset: { srcId: string; base64: string },
) => {
  const email = useAccountStore.getState().email;
  try {
    // const res = await library.lis(asset.srcId);
    // // @ts-ignore
    // const r = res.response;

    const workId = await uuid(email, 24);
    const imageRecord: ImageRecord = {};
    const elements = [];
    const position = { x: 0, y: 0 };
    const assetSize = await getImageDimensions(asset.base64);

    elements.push(
      backgroundBoilerPlate(position, assetSize, asset.srcId, [0, 0, 1, 1]),
    );

    imageRecord[asset.srcId] = {
      base64: asset.base64,
      srcId: asset.srcId,
      multiview: false,
    };

    // for (const item of r) {
    //   imageRecord[item.id] = {
    //     base64: `data:image/png;base64,${item.preview_image}`,
    //     srcId: item.id,
    //     multiview: false,
    //     bbox: item.box,
    //     mask: item.mask,
    //   };
    //   elements.push(
    //     productBoilerPlate(position, assetSize, item.id, item.box, true),
    //   );
    // }

    orderElementsByCategory(elements);
    await Promise.all([
      library.setWorkMeta(
        workId,
        "Untitled - Remix",
        "",
        new Date().toISOString(),
      ),
      library.setWorkData(
        workId,
        JSON.stringify({
          workId,
          name: "Untitled - Remix",
          theme: "",
          size: assetSize,
          imageRecord: imageRecord,
          elements: elements,
        }),
      ),
    ]);
    return workId;
  } catch (error) {
    console.error("Error creating work", error);
    return "";
  }
};

export const replaceImageSection = async (
  image_1: string,
  image_2: string,
  bbox: number[],
): Promise<string> => {
  // Create new Image elements
  const img1 = new Image();
  const img2 = new Image();

  // Load the base64 images
  const loadImg1 = new Promise<void>((resolve) => {
    img1.onload = () => resolve();
    img1.src = image_1;
  });
  const loadImg2 = new Promise<void>((resolve) => {
    img2.onload = () => resolve();
    img2.src = image_2;
  });

  await Promise.all([loadImg1, loadImg2]);

  img2.height = img1.height;
  img2.width = img1.width;

  // Create a canvas and get the context
  const canvas = document.createElement("canvas");
  const context = canvas.getContext("2d")!;
  canvas.width = img1.width;
  canvas.height = img1.height;

  // Draw the first image on the canvas
  context.drawImage(img1, 0, 0);

  // Calculate the bounding box in pixels
  const bboxX = bbox[0] * img1.width;
  const bboxY = bbox[1] * img1.height;
  const bboxWidth = (bbox[2] - bbox[0]) * img1.width;
  const bboxHeight = (bbox[3] - bbox[1]) * img1.height;

  // Draw the section of the second image on the first image
  context.drawImage(
    img2,
    bboxX,
    bboxY,
    bboxWidth,
    bboxHeight,
    bboxX,
    bboxY,
    bboxWidth,
    bboxHeight,
  );

  // Get the data URL representing the modified image
  const dataUrl = canvas.toDataURL("image/png");

  // Explicitly destroy the canvas
  context.clearRect(0, 0, canvas.width, canvas.height);
  canvas.width = 0;
  canvas.height = 0;

  return dataUrl;
};
