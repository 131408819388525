import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Asset from "../Asset";
import { ROUTES } from "../lib/Route";
import { ThemeUI } from "../components/theme-toggle";
import { HubContent, ImageContent, VideoContent } from "../lib/content";

interface HeaderProps {}

const Header: React.FC<HeaderProps> = (props: HeaderProps) => {
  const navigate = useNavigate();
  const [toolsMenu, setToolsMenu] = React.useState<boolean>(false);
  const [burgerMenu, setBurgerMenu] = React.useState<boolean>(false);
  const [burgerMenuTools, setBurgerMenuTools] = React.useState<boolean>(true);

  useEffect(() => setToolsMenu(false), []);
  useEffect(() => setBurgerMenu(false), []);

  return (
    <div className="bar">
      <div className="brand">
        <div
          className="side-menu"
          style={{
            backgroundColor: burgerMenu
              ? "rgba(185, 180, 180, 0.674)"
              : "transparent",
          }}
        >
          <div
            onClick={() => setBurgerMenu(!burgerMenu)}
            className="burger-button"
          >
            <span className="material-icons">menu</span>
          </div>
          <div
            className="burger-menu"
            style={{
              display: burgerMenu ? "flex" : "none",
              opacity: burgerMenu ? "1.0" : "0.0",
              translate: "translateY(1vh)",
            }}
          >
            <h1
              style={{
                backgroundColor: burgerMenuTools
                  ? "rgba(185, 180, 180, 0.674)"
                  : "transparent",
              }}
              onClick={() => setBurgerMenuTools(!burgerMenuTools)}
            >
              Tools
              <span className="material-symbols-outlined">arrow_drop_down</span>
            </h1>
            <div
              style={{
                display: burgerMenuTools ? "block" : "none",
              }}
            >
              <h2
                onClick={() => {
                  navigate(ROUTES.HUB_BRAND_BOOK);
                }}
              >
                {HubContent.heading}
              </h2>
              <h2
                onClick={() => {
                  navigate(ROUTES.STUDIO);
                }}
              >
                {ImageContent.heading}
              </h2>
              <h2
                onClick={() => {
                  navigate(ROUTES.HUB_ALL_ASSETS);
                }}
              >
                {VideoContent.heading}
              </h2>
            </div>
            <h1>Features</h1>
          </div>
        </div>

        <img
          src={Asset.LOGO_IMAGE}
          style={{ width: "2rem" }}
          alt="hedwigAI"
          onClick={() => navigate(ROUTES.MAIN)}
        />
        <p onClick={() => navigate(ROUTES.MAIN)}>hedwigAI</p>
        <p className="alpha-tag">alpha</p>
        <div
          className="tools"
          style={{
            backgroundColor: toolsMenu
              ? "rgba(185, 180, 180, 0.674)"
              : "transparent",
          }}
        >
          <div
            onClick={() => setToolsMenu(!toolsMenu)}
            className="tools-button"
          >
            Tools
            <div className="button-down"></div>
          </div>
          <div
            className="tools-menu"
            onClick={() => setToolsMenu(!toolsMenu)}
            style={{
              display: toolsMenu ? "flex" : "none",
              opacity: toolsMenu ? "1.0" : "0.0",
              translate: "translateY(1vh)",
            }}
          >
            <div
              className="tool-icon"
              onClick={() => navigate(ROUTES.HUB_BRAND_BOOK)}
            >
              <img src={Asset.HUB_ICON}></img>
              <div className="tool-info">
                <h2>{HubContent.heading}</h2>
                <h3>{HubContent.subheading}</h3>
              </div>
            </div>
            <div className="tool-icon" onClick={() => navigate(ROUTES.STUDIO)}>
              <img src={Asset.IMAGE_ICON}></img>
              <div className="tool-info">
                <h2>{ImageContent.heading}</h2>
                <h3>{ImageContent.subheading}</h3>
              </div>
            </div>
            <div className="tool-icon" onClick={() => navigate(ROUTES.HUB)}>
              <img src={Asset.VIDEO_ICON}></img>
              <div className="tool-info">
                <h2>{VideoContent.heading}</h2>
                <h3>{VideoContent.subheading}</h3>
              </div>
            </div>
          </div>
        </div>

        <div
          className="features"
          onClick={() => window.open("https://brands.hedwigai.com", "_blank")}
        >
          Brands
        </div>
      </div>
      <div className="user">
        <ThemeUI />
      </div>
    </div>
  );
};

export { Header };
