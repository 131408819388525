interface Content {
  heading: string;
  subheading: string;
  description: string;
}

const ImageContent: Content = {
  heading: "Studio",
  subheading: "Work on a new idea or repurpose existing media assets.",
  description:
    "AI finds your product images, enhance creatives with AI tools and generate creatives with 50x higher CTR",
};

const HubContent: Content = {
  heading: "Brand Book",
  subheading: "Capture your brand guidelines from website, assets and more.",
  description:
    "Focus on your story, not managing your files. Images and Videos are cataloged by AI to get you what you want 100x faster.",
};

const VideoContent: Content = {
  heading: "All Assets",
  subheading:
    "Digital Asset Manager to search, export & share assets with your team.",
  description:
    "Splice video clips with audio through interactive prompt based editor. Animate custom avatars and much more!",
};

const SDKContent: Content = {
  heading: "Javascript SDK",
  subheading: "Generative and Interpretive AI Models Fine Tuned for You",
  description:
    "Learn more about our Javascript SDK for image, audio & video annotations, and custom video generation.",
};

const HeroContent: Content = {
  heading: "hedwigAI",
  subheading: "Ultimate Protégé for Your Brand",
  description: "Fastest Designer 🌩️ for Your Brand",
};

export { ImageContent, HeroContent, HubContent, VideoContent, SDKContent };
