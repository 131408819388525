import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useRef,
  useCallback,
} from "react";
import Toast from "../components/Toast";

interface WebSocketContextProps {
  sendMessage: (message: any) => void;
  isConnected: boolean;
  messages: any[];
  pop: () => void;
  indexFiles: any[];
}

const WebSocketContext = createContext<WebSocketContextProps | undefined>(
  undefined,
);

interface WebSocketProviderProps {
  authToken: string | undefined;
  reconnectInterval?: number;
  children: React.ReactNode;
}

const indexFiles = [{ id: "1", command: "INDEX", instruction: {} }];

interface MessageCounterType {
  outgoing: number;
  acknowledged: number;
}

const useWebSocketConnection = (
  authToken: string | undefined,
  reconnectInterval: number,
  maxAttempts: number,
) => {
  const [isConnected, setIsConnected] = useState(false);
  const [outQueue, setOutQueue] = useState<any[]>([]);
  const [messages, setMessages] = useState<any[]>([]);
  const [lastOutMessage, setLastOutMessage] = useState<any | null>(null);
  const [lastOutRetryCount, setLastOutRetryCount] = useState(0);
  const ws = useRef<WebSocket | null>(null);
  const [messageCounter, setMessageCounter] = useState<MessageCounterType>({
    outgoing: 0,
    acknowledged: 0,
  });
  const [reconnectionAttempts, setReconnectionAttempts] = useState(0);
  const heartbeatInterval = useRef<number | null>(null);
  const lastOutMessageRef = useRef(lastOutMessage);

  const handleOpen = useCallback(() => {
    setIsConnected(true);
    setReconnectionAttempts(0);
    console.log("WebSocket connected");

    // setOutQueue((prevQueue) => [...prevQueue, authToken]);
    if (authToken) {
      ws.current?.send(authToken);
      setMessageCounter({ outgoing: 1, acknowledged: 0 });
    }
    // if (heartbeatInterval.current === null) {
    //   heartbeatInterval.current = window.setInterval(() => {
    //     if (ws.current?.readyState === WebSocket.OPEN) {
    //       ws.current.send(JSON.stringify({ instruction: "PING" }));
    //     }
    //   }, HEARTBEAT_INTERVAL);
    // }
  }, [authToken]);

  useEffect(() => {
    console.log("Last out message: ", lastOutMessage);
    lastOutMessageRef.current = lastOutMessage;
  }, [lastOutMessage]);

  const handleMessage = useCallback((event: MessageEvent) => {
    let { response } = JSON.parse(event.data);
    if (response === "ACK" || response === "ERROR") {
      if (response == "ERROR") {
        console.log("Error sending message: ", lastOutMessageRef.current);
        if (lastOutMessageRef.current !== null) {
          setOutQueue((prevQueue) => [lastOutMessageRef.current, ...prevQueue]);
        }
      }
      // if (messageCounter.acknowledged >= messageCounter.outgoing) return;
      setMessageCounter((prev) => {
        if (prev.acknowledged >= prev.outgoing) return prev;
        return {
          ...prev,
          acknowledged: prev.acknowledged + 1,
        };
      });
      return;
    }
    setLastOutRetryCount(0); // Reset the retry count
    setMessages((prevMessages) => [...prevMessages, JSON.parse(event.data)]);
  }, []);

  const handleClose = useCallback(() => {
    setIsConnected(false);
    setOutQueue([]);
    ws.current = null;

    if (heartbeatInterval.current !== null) {
      clearInterval(heartbeatInterval.current);
      heartbeatInterval.current = null;
    }

    console.log("WebSocket closed");
    console.log(reconnectionAttempts);
    if (authToken && reconnectionAttempts < maxAttempts) {
      setTimeout(() => {
        setReconnectionAttempts((prev) => prev + 1);
        connectWebSocket();
      }, reconnectInterval);
    }
  }, [
    authToken,
    reconnectInterval,
    reconnectionAttempts,
    setReconnectionAttempts,
  ]);

  const handleError = useCallback((error: Event) => {
    console.error("WebSocket error:", error);
  }, []);

  const connectWebSocket = useCallback(() => {
    if (!authToken) return;

    if (
      ws.current &&
      (ws.current.readyState === WebSocket.OPEN ||
        ws.current.readyState === WebSocket.CONNECTING)
    ) {
      console.log("WebSocket connection already established or in progress");
      return;
    }

    try {
      ws.current = new WebSocket(`wss://conductor.hedwigai.com`);
      ws.current.onopen = handleOpen;
      ws.current.onmessage = handleMessage;
      ws.current.onclose = handleClose;
      ws.current.onerror = handleError;
    } catch (error) {
      console.error("WebSocket connection failed:", error);
    }
  }, [
    authToken,
    handleOpen,
    handleMessage,
    handleClose,
    handleError,
    lastOutMessage,
  ]);

  useEffect(() => {
    if (authToken === undefined) {
      if (ws.current?.readyState === WebSocket.OPEN) {
        ws.current.close();
      }
      setMessages([]);
      setOutQueue([]);
      return;
    }

    if (ws.current?.readyState === WebSocket.OPEN && authToken) {
      // setMessageCounter((prev) => ({ ...prev, outgoing: prev.outgoing + 1 }));
      setMessageCounter({ outgoing: 1, acknowledged: 0 });
      ws.current.send(authToken);
    }

    connectWebSocket();

    return () => {
      if (ws.current) {
        ws.current.close();
      }
    };
  }, [authToken]);

  useEffect(() => {
    if (
      authToken === undefined ||
      outQueue.length === 0 ||
      ws.current?.readyState !== WebSocket.OPEN
    ) {
      return;
    }

    if (messageCounter.outgoing <= messageCounter.acknowledged) {
      if (lastOutMessage !== outQueue[0]) {
        setLastOutRetryCount(0);
      }

      if (lastOutRetryCount < 5) {
        setLastOutMessage(outQueue[0]);
        setLastOutRetryCount((prev) => prev + 1);
        ws.current.send(JSON.stringify(outQueue[0]));
        setOutQueue((prevQueue) => prevQueue.slice(1));
        setMessageCounter((prev) => ({
          ...prev,
          outgoing: prev.outgoing + 1,
        }));
      } else {
        Toast("Error loading suggestion. Skip to try again!");
        setOutQueue((prevQueue) => prevQueue.slice(1));
        setLastOutRetryCount(0); // Reset the retry count
      }
    }
  }, [outQueue, authToken, messageCounter]);

  return { isConnected, messages, setOutQueue, setMessages };
};

export const WebSocketProvider: React.FC<WebSocketProviderProps> = ({
  reconnectInterval = 5000,
  children,
  authToken,
}) => {
  const maxAttempts = 5;
  const { isConnected, messages, setOutQueue, setMessages } =
    useWebSocketConnection(authToken, reconnectInterval, maxAttempts);

  const sendMessage = (message: any) => {
    setOutQueue((prevQueue) => [...prevQueue, message]);
  };

  const pop = () => {
    setMessages((prevMessages) => prevMessages.slice(1));
  };

  return (
    <WebSocketContext.Provider
      value={{ sendMessage, isConnected, messages, pop, indexFiles }}
    >
      {children}
    </WebSocketContext.Provider>
  );
};

export const useWebSocket = (): WebSocketContextProps => {
  const context = useContext(WebSocketContext);
  if (!context) {
    throw new Error("useWebSocket must be used within a WebSocketProvider");
  }
  return context;
};
