import { Library } from "hedwigai";
import _ from "lodash";
import { create } from "zustand";
import { fetchImageAll } from "../../lib/image";
import {
  ElementData,
  Position,
  SearchResult,
  Size,
  calculateElementPositionAndSize,
  fetchImage,
} from "./utils/utils";
import { CSSProperties } from "react";

export enum StudioElementTypes {
  canvasBackground = "canvas",
  backgroundImage = "background",
  product = "products",
  designElement = "elements",
  text = "text",
}
interface BaseElementType {
  library?: Library;
  id: string;
  x: number;
  y: number;
  height: number;
  width: number;
  zIndex?: number;
  rotation: number;
  horizontalFlip: boolean;
  verticalFlip: boolean;
  blur: number;
  visibility: boolean;
  imgData?: ImageRecordObject;
  ref?: React.RefObject<HTMLDivElement>;
  sizeAndCoordinates: {
    size: { height: number; width: number };
    bbox: number[];
  };
  selector?: number | string;
  contour?: boolean;
  imageSrc?: string;
}
export interface BackgroundImageType extends BaseElementType {
  type: StudioElementTypes.backgroundImage;
  edit: boolean;
  hasImage: boolean;
  imageSrc: string;
  clipPath?: string;
  css?: CSSProperties;
}

export interface ProductType extends BaseElementType {
  type: StudioElementTypes.product;
  shadow: string;
  opacity: number;
  imageSrc: string;
  clipPath?: string;
}

export interface DesignElementType extends BaseElementType {
  type: StudioElementTypes.designElement;
  shadow: string;
  opacity: number;
  imageSrc: string;
  clipPath?: string;
}

export interface TextType extends BaseElementType {
  type: StudioElementTypes.text;
  text: string;
  backgroundColor: string;
  fontColor: string;
  fontFamily: string;
  fontSize: number;
  fontStyle: "normal" | "italic" | "oblique";
  lineSpacing: number | "normal";
  letterSpacing: number | "normal";
  textShadow: string;
  textAlign: "left" | "right" | "center" | "justify";
  textDecoration: "underline" | "strikethrough" | "none";
  fontWeight: string | number;
  lineHeight?: string | number;
  textTransform?:
    | "none"
    | "capitalize"
    | "uppercase"
    | "lowercase"
    | "full-width";
  whiteSpace?:
    | "normal"
    | "nowrap"
    | "pre"
    | "pre-wrap"
    | "pre-line"
    | "break-spaces";
  WebkitTextStroke?: string;
  wordSpacing?: string;
}

export type StudioElement =
  | BackgroundImageType
  | ProductType
  | DesignElementType
  | TextType;

export interface SelectedElement {
  id: string;
  type: StudioElementTypes;
}
export interface ImageRecordObject {
  srcId: string;
  base64: string;
  multiview?: boolean;
  bbox?: number[];
  mask?: string;
  index?: boolean;
}

export interface ImageRecord {
  [key: string]: ImageRecordObject;
}
export interface ChatMessage {
  id: string;
  message: string;
  user: boolean;
  type: ChatMessageType;
  srcId?: string;
}

export enum ChatMessageType {
  text = "text",
  searchProduct = "search-product",
  searchBackground = "search-background",
  generateElement = "generate-element",
  generateBackground = "generate-background",
}

export enum ChatInputTypes {
  // BUTTONS
  edit = "edit",
  addElement = "add-element",
  addText = "add-text",
  addBackground = "add-background",
  searchSimilarProduct = "search-similar-product",
  searchSimilarBackground = "search-similar-background",
  moreResults = "more-results",
  generateNewElement = "generate-new-element",
  generateNewBackground = "generate-new-background",
  // PROMPT INPUTS
  editPrompt = "edit-prompt",
  searchProductPrompt = "search-product-prompt",
  searchBackgroundPrompt = "search-background-prompt",
  generateElementPrompt = "generate-element-prompt",
  generateBackgroundPrompt = "generate-background-prompt",
}

export enum ChatActionTypes {
  addElement = "add-element",
  addBackground = "add-background",
  addText = "add-text",
  edit = "edit",
  searchSimilar = "search-similar",
  moreResults = "more-results",
  editCall = "edit-call",
  searchCall = "search-call",
  generateCall = "generate-call",
}

export interface SelectResultElement {
  srcId: string;
  base64: string;
  bbox: number[];
}

export interface SelectResult {
  background: SelectResultElement;
  element: SelectResultElement;
}

export interface CanvasCache {
  key: string;
  modifiedAt: number;
  needsUpdate: boolean;
}

export interface StudioStoreState {
  id: string;
  name: string | undefined;
  keywords: string[];
  elements: StudioElement[];
  selectedElements: SelectedElement[];
  undoStack: StudioElement[][];
  redoStack: StudioElement[][];
  lastModified: number;
  lastSaved: number;
  sidekickActive: boolean;
  sidekickContent: React.ReactNode | null;
  imageRecord: ImageRecord;
  size: { height: number; width: number };
  selectedContours: string[];
  selections: SelectResult[];
  fontFamilies: string[];
  hasContextChanged: boolean;
  sessionId: string;
  backgroundPrompts: string[];
  originalKey: string | undefined;
  shareModalOpen: boolean;
  canvasCache:
    | {
        foreground: CanvasCache;
        background: CanvasCache;
        canvas: CanvasCache;
      }
    | undefined;
  firstRenderOutput: { [key: string]: string[] } | undefined;
  setFirstRenderOutput: (output: { [key: string]: string[] }) => void;
  initWork: (data: any, id: string) => void;
  updateForegroundCache: () => void;
  updateBackgroundCache: () => void;
  updateCanvasCache: () => void;
  setNeedForegroundUpdate: (needUpdate: boolean) => void;
  setNeedBackgroundUpdate: (needUpdate: boolean) => void;
  setNeedCanvasUpdate: (needUpdate: boolean) => void;
  toggleShareModal: () => void;
  setOriginalKey: (key: string) => void;
  setBackgroundPrompts: (prompts: any[]) => void;
  setSessionId: (id: string) => void;
  setHasContextChanged: (changed: boolean) => void;
  setFontFamilies: (families: string[]) => void;
  setSelections: (selections: SelectResult[]) => void;
  updateSelectedContours: (contours: string[]) => void;
  setSize: (size: { height: number; width: number }) => void;
  getElementById: (id: string | undefined) => StudioElement | undefined;
  setImageRecord: (imageRecord: ImageRecord) => void;
  setKeywords: (keywords: string[]) => void;
  setImageRecordById: (
    id: string,
    {
      srcId,
      base64,
    }: {
      srcId: string;
      base64: string;
      multiview?: boolean;
      mask?: string;
      bbox?: number[];
      index?: boolean;
    },
  ) => void;
  setSidekickActive: (active: boolean) => void;
  setSidekickContent: (content: React.ReactNode) => void;
  updateNameAndId: (name: string, id: string) => void;
  updateName: (name: string) => void;
  initializeElements: (elements: StudioElement[]) => void;
  addElement: (element: StudioElement) => void;
  updateElement: (
    id: string,
    changes: Partial<StudioElement>,
    finalize?: boolean,
  ) => void;
  updateElements: (changes: Record<string, Partial<StudioElement>>) => void;
  removeElement: (id: string) => void;
  selectElement: (el: SelectedElement[]) => void;
  cloneElement: (id: string) => void;
  setLastSaved: () => void;
  setLastModified: (date: number) => void;
  undo: () => void;
  redo: () => void;
  emptyStore: () => void;
}

const useStudioStore = create<StudioStoreState>((set, get) => ({
  id: "",
  name: undefined,
  elements: [],
  selectedElements: [],
  undoStack: [],
  redoStack: [],
  keywords: [],
  addNew: null,
  magicBoxOpen: false,
  lastModified: 0,
  lastSaved: 0,
  sidekickActive: false,
  sidekickContent: null,
  imageRecord: {},
  size: { height: 0, width: 0 },
  selectedContours: [],
  selections: [],
  fontFamilies: [],
  hasContextChanged: false,
  sessionId: "",
  backgroundPrompts: [],
  originalKey: undefined,
  shareModalOpen: false,
  canvasCache: undefined,
  firstRenderOutput: undefined,
  setFirstRenderOutput: (output) => {
    set({ firstRenderOutput: output });
  },
  initWork: (data, id) => {
    const work = {
      id: id,
      canvasCache: {
        foreground: {
          key: `${id}-foreground`,
          modifiedAt: undefined,
          needsUpdate: false,
        },
        background: {
          key: `${id}-background`,
          modifiedAt: undefined,
          needsUpdate: false,
        },
        canvas: {
          key: `${id}-canvas`,
          modifiedAt: undefined,
          needsUpdate: false,
        },
      },
      name: data.name,
      imageRecord: data.imageRecord,
      size: data.size
        ? calculateCanvasSize(
            data.size.height,
            data.size.width,
            window.innerWidth,
          )
        : undefined,
      fontFamilies: data.fontFamilies ?? [],
      originalKey: data.originalKey,
      undoStack: [data.elements!],
      redoStack: [],
      lastModified: Date.now(),
      lastSaved: Date.now(),
      elements: data.elements.map((element: StudioElement) => {
        const newCoordinates = calculateAspectRatioAndPosition(
          data.size,
          element.sizeAndCoordinates.bbox,
          window.innerWidth,
        );
        return {
          ...element,
          x: newCoordinates.tempX,
          y: newCoordinates.tempY,
          width: newCoordinates.tempWidth,
          height: newCoordinates.tempHeight,
        };
      }),
    };
    set(work);
    console.log(work);
  },
  updateForegroundCache: () => {
    setTimeout(
      () =>
        set((state) => {
          if (!state.canvasCache) return state;
          return {
            canvasCache: {
              ...state.canvasCache,
              foreground: {
                ...state.canvasCache.foreground,
                modifiedAt: Date.now(),
              },
            },
          };
        }),
      1000,
    );
  },
  setNeedBackgroundUpdate: (needUpdate) => {
    set((state) => {
      if (!state.canvasCache) return state;
      return {
        canvasCache: {
          ...state.canvasCache,
          background: {
            ...state.canvasCache.background,
            needsUpdate: needUpdate,
          },
        },
      };
    });
  },
  setNeedCanvasUpdate: (needUpdate) => {
    set((state) => {
      if (!state.canvasCache) return state;
      return {
        canvasCache: {
          ...state.canvasCache,
          canvas: {
            ...state.canvasCache.canvas,
            needsUpdate: needUpdate,
          },
        },
      };
    });
  },
  setNeedForegroundUpdate: (needUpdate) => {
    set((state) => {
      if (!state.canvasCache) return state;
      return {
        canvasCache: {
          ...state.canvasCache,
          foreground: {
            ...state.canvasCache.foreground,
            needsUpdate: needUpdate,
          },
        },
      };
    });
  },
  updateBackgroundCache: () => {
    setTimeout(
      () =>
        set((state) => {
          if (!state.canvasCache) return state;
          return {
            canvasCache: {
              ...state.canvasCache,
              background: {
                ...state.canvasCache.background,
                modifiedAt: Date.now(),
              },
            },
          };
        }),
      100,
    );
  },
  updateCanvasCache: () => {
    setTimeout(
      () =>
        set((state) => {
          if (!state.canvasCache) return state;
          return {
            canvasCache: {
              ...state.canvasCache,
              canvas: {
                ...state.canvasCache.canvas,
                modifiedAt: Date.now(),
              },
            },
          };
        }),
      100,
    );
  },
  toggleShareModal: () => {
    set((state) => ({ shareModalOpen: !state.shareModalOpen }));
  },
  setOriginalKey: (key) => set({ originalKey: key }),
  setBackgroundPrompts: (prompts) => {
    set({ backgroundPrompts: prompts });
  },
  setSessionId: (id) => {
    set({ sessionId: id });
  },
  setHasContextChanged: (changed) => {
    set({ hasContextChanged: changed });
  },
  setFontFamilies: (families) => {
    set((state) => {
      const newFamilies = [...new Set([...state.fontFamilies, ...families])];
      if (newFamilies !== state.fontFamilies) {
        return { fontFamilies: newFamilies };
      }
      return state;
    });
  },
  setSelections: (selections) => {
    set({ selections: selections });
  },
  updateSelectedContours: (contours) => {
    set({ selectedContours: contours });
  },
  getElementById: (id: string | undefined) => {
    if (!id) return undefined;
    return get().elements.find((el) => el.id === id);
  },
  setImageRecord: (imageRecord: ImageRecord) => {
    set({ imageRecord: imageRecord });
  },
  setImageRecordById: (id, { srcId, base64, multiview, mask, bbox, index }) => {
    set((state) => ({
      imageRecord: {
        ...state.imageRecord,
        [id]: {
          srcId: srcId,
          base64: base64,
          multiview: multiview,
          mask,
          bbox,
          index,
        },
      },
      hasContextChanged: true,
    }));
  },
  setSize: (size: { height: number; width: number }) => {
    set({ size: size });
  },
  setSidekickActive: (active) => {
    set({ sidekickActive: active });
  },
  setSidekickContent: (content) => {
    set({ sidekickContent: content, sidekickActive: true });
  },
  setLastSaved: () => {
    set({ lastSaved: Date.now() });
  },
  setLastModified: (date) => {
    set({ lastModified: date });
  },
  updateNameAndId: (name, id) => {
    set({ name: name, id: id });
  },
  updateName: (name) => {
    set({ name: name, lastModified: Date.now() });
  },
  initializeElements: (elements: StudioElement[]) => {
    if (elements.length === 0) set({ elements: elements });
    else {
      set({
        elements: elements,
        undoStack: [...get().undoStack, get().elements],
        redoStack: [],
        lastModified: Date.now(),
      });
    }
  },
  setKeywords: (keywords: string[]) => {
    set({ keywords: keywords });
  },
  addElement: (element) => {
    get().updateForegroundCache();
    set((state) => ({
      elements: [...state.elements, element],
      undoStack: [...state.undoStack, state.elements],
      redoStack: [],
      lastModified: Date.now(),
      hasContextChanged: true,
    }));
  },
  updateElement: (id, changes, finalize = undefined) => {
    get().updateForegroundCache();
    // @ts-ignore
    set((state) => {
      const updatedElements = state.elements.map((el) => {
        if (el.id === id) {
          return { ...el, ...changes };
        }
        return el;
      });

      if (finalize === undefined || finalize === true) {
        return {
          undoStack: [...state.undoStack, state.elements],
          redoStack: [],
          lastModified: Date.now(),
          elements: updatedElements,
          // hasContextChanged: true,
        };
      } else {
        return {
          elements: updatedElements,
        };
      }
    });
  },
  updateElements: (changes) => {
    get().updateForegroundCache();
    // @ts-ignore
    set((state) => {
      const updatedElements = state.elements.map((el) => {
        if (changes[el.id]) {
          return { ...el, ...changes[el.id] };
        }
        return el;
      });

      return {
        undoStack: [...state.undoStack, state.elements],
        redoStack: [],
        lastModified: Date.now(),
        elements: updatedElements,
        // hasContextChanged: true,
      };
    });
  },
  removeElement: (id) => {
    get().updateForegroundCache();
    set((state) => {
      return {
        elements: state.elements.filter((el) => el.id !== id),
        undoStack: [...state.undoStack, state.elements],
        redoStack: [],
        selectedElement: null,
        lastModified: Date.now(),
      };
    });
  },
  selectElement: (el) => set(() => ({ selectedElements: el, addNew: null })),
  cloneElement: async (id) => {
    get().updateForegroundCache();
    const element = get().elements.find((el) => el.id === id);
    if (element) {
      const newElement = { ...element, id: await uuid() };
      set((state) => ({
        elements: [
          ...state.elements,
          { ...newElement, x: newElement.x + 10, y: newElement.y + 10 },
        ],
        undoStack: [...state.undoStack, state.elements],
        redoStack: [],
        lastModified: Date.now(),
      }));
    }
  },
  undo: () => {
    get().updateForegroundCache();
    get().updateBackgroundCache();
    set((state) => {
      const { undoStack, elements, redoStack } = state;
      if (undoStack.length > 0) {
        const previousState = undoStack[undoStack.length - 1];
        return {
          elements: previousState,
          undoStack: undoStack.slice(0, -1),
          redoStack: [...redoStack, elements],
          lastModified: Date.now(),
        };
      }
      return state;
    });
  },
  redo: () => {
    get().updateForegroundCache();
    get().updateBackgroundCache();
    const { redoStack, elements } = get();
    if (redoStack.length > 0) {
      const nextState = redoStack[redoStack.length - 1];
      set((state) => ({
        elements: nextState,
        redoStack: redoStack.slice(0, -1),
        undoStack: [...state.undoStack, elements],
        lastModified: Date.now(),
      }));
    }
  },
  emptyStore: () => {
    set({
      id: "",
      name: "",
      elements: [],
      selectedElements: [],
      undoStack: [],
      redoStack: [],
      lastModified: 0,
      lastSaved: 0,
      sidekickActive: false,
      sidekickContent: null,
      imageRecord: {},
      fontFamilies: [],
      selectedContours: [],
      selections: [],
      sessionId: "",
      backgroundPrompts: [],
      shareModalOpen: false,
      canvasCache: undefined,
    });
  },
}));

export interface ApiElement {
  id: string;
  room_type: string;
  caption: string;
  image: string;
  file_id: string;
  score: number;
  category: [StudioElementTypes, "3D"];
  height: number;
  width: number;
  bbox: number[];
  center: number[];
  zindex: number;
}

export function calculateElementBoundingBox(
  tempWidth: number,
  tempHeight: number,
  tempX: number,
  tempY: number,
  size: { height: number; width: number },
): number[] {
  const originalWidth = size.width;
  const originalHeight = size.height;

  const bboxX0 = tempX / originalWidth;
  const bboxY0 = tempY / originalHeight;
  const bboxX1 = bboxX0 + tempWidth / originalWidth;
  const bboxY1 = bboxY0 + tempHeight / originalHeight;

  const bbox = [bboxX0, bboxY0, bboxX1, bboxY1];

  return bbox;
}

export function calculateAspectRatioAndPosition(
  size: { height: number; width: number },
  bbox: number[],
  vWidth: number,
  // maxSize: number = 500,
): { tempWidth: number; tempHeight: number; tempX: number; tempY: number } {
  const maxSize = 0.4 * vWidth;
  const aspectRatio = size.height / size.width;
  let width, height;
  if (aspectRatio > 1) {
    width = maxSize / aspectRatio;
    height = maxSize;
  } else {
    height = maxSize * aspectRatio;
    width = maxSize;
  }

  const tempWidth = Math.floor((bbox[2] - bbox[0]) * width);
  const tempHeight = Math.floor((bbox[3] - bbox[1]) * height);
  const tempX = Math.floor(bbox[0] * width);
  const tempY = Math.floor(bbox[1] * height);

  return { tempWidth, tempHeight, tempX, tempY };
}

export function calculateCanvasSize(
  height: number,
  width: number,
  vWidth: number,
  maxSize: number = 0.4 * vWidth,
) {
  const aspectRatio = height / width;
  let nWidth, nHeight;
  if (aspectRatio > 1) {
    nWidth = maxSize / aspectRatio;
    nHeight = maxSize;
  } else {
    nHeight = maxSize * aspectRatio;
    nWidth = maxSize;
  }
  return { height: nHeight, width: nWidth };
}

async function createElement(
  apiEl: ApiElement,
  vWidth: number,
  vHeight: number,
  ImageRecord: ImageRecord,
): Promise<StudioElement> {
  const { tempWidth, tempHeight, tempX, tempY } =
    calculateAspectRatioAndPosition(
      { height: apiEl.height, width: apiEl.width },
      apiEl.bbox,
      vWidth,
    );

  const imageSrc = `data:image/png;base64,${apiEl.image}`;
  const newId = await uuid();

  ImageRecord[apiEl.id] = {
    srcId: apiEl.id,
    base64: imageSrc,
    multiview: apiEl.category.includes("3D"),
  };

  const commonProps = {
    id: newId,
    x: tempX,
    y: tempY,
    rotation: 0,
    horizontalFlip: false,
    verticalFlip: false,
    blur: 0,
    visibility: true,
    height: tempHeight,
    width: tempWidth,
    sizeAndCoordinates: {
      size: { height: apiEl.height, width: apiEl.width },
      bbox: apiEl.bbox,
    },
  };

  switch (apiEl.category[0]) {
    case "background":
      return {
        ...commonProps,
        type: StudioElementTypes.backgroundImage,
        imageSrc: apiEl.id,
        hasImage: true,
        edit: false,
      } as BackgroundImageType;

    case "products":
      return {
        ...commonProps,
        type: StudioElementTypes.product,
        imageSrc: apiEl.id,
        shadow: "0px 0px 0px rgba(0, 0, 0, 0)",
        opacity: 1,
      } as ProductType;

    case "elements":
      return {
        ...commonProps,
        type: StudioElementTypes.designElement,
        imageSrc: apiEl.id,
        shadow: "0px 0px 0px rgba(0, 0, 0, 0)",
        opacity: 1,
      } as DesignElementType;

    default:
      return {
        ...commonProps,
        type: StudioElementTypes.text,
        text: apiEl.caption,
        backgroundColor: "transparent",
        fontColor: "black",
        fontFamily: `"Futura", "Century Gothic", "CenturyGothic", "AppleGothic", sans-serif`,
        fontSize: 16,
        fontWeight: "normal",
        fontStyle: "normal",
        lineSpacing: "normal",
        letterSpacing: "normal",
        textShadow: "0px 0px 0px rgba(0, 0, 0, 0)",
        textAlign: "left",
        textDecoration: "none",
        height: 30,
        width: 200,
      } as TextType;
  }
}

export async function initializeElementsFromApiResponse(
  library: Library,
  apiResponse: ApiElement[],
  store: StudioStoreState,
  email: string,
  name: string,
  theme: string,
) {
  const vWidth = window.innerWidth;
  const vHeight = window.innerHeight;
  const ImageRecord: ImageRecord = {};

  const transformedElements: StudioElement[] = await Promise.all(
    apiResponse.map((apiEl) =>
      createElement(apiEl, vWidth, vHeight, ImageRecord),
    ),
  );

  const reOrderedElements = orderElementsByCategory(transformedElements);

  const id = await uuid(email, 24);
  await Promise.all([
    library.setWorkMeta(id, name, "", new Date().toISOString()),
    library.setWorkData(
      id,
      JSON.stringify({
        id,
        name: name,
        theme: theme,
        size: reOrderedElements[0].sizeAndCoordinates.size,
        imageRecord: ImageRecord,
        elements: reOrderedElements,
      }),
    ),
  ]);
  store.updateNameAndId(name, id);
  store.initializeElements(reOrderedElements);
  store.setLastSaved();

  return id;
}

export const initializeElementsFromTemplate = async (
  library: Library,
  template: any,
) => {
  const vWidth = window.innerWidth;
  const vHeight = window.innerHeight;
  const ImageRecord: ImageRecord = {};

  const transformedElements: StudioElement[] = await Promise.all(
    template.elements.map((apiEl: ApiElement) =>
      createElement(apiEl, vWidth, vHeight, ImageRecord),
    ),
  );

  const reOrderedElements = orderElementsByCategory(transformedElements);

  return reOrderedElements;
};

export const addNewTextElement = async (props: Partial<TextType> = {}) => {
  const store = useStudioStore.getState();
  const id = await uuid();
  const elements = store.elements;
  const size = store.size;

  const newElement: TextType = {
    id: id,
    type: StudioElementTypes.text,
    x: size.width / 2 - 50,
    y: size.height / 2 - 15,
    rotation: 0,
    horizontalFlip: false,
    verticalFlip: false,
    blur: 0,
    visibility: true,
    text: "New Text",
    backgroundColor: "transparent",
    fontColor: "black",
    fontFamily: `"Futura", "Century Gothic", "CenturyGothic", "AppleGothic", sans-serif`,
    fontWeight: "normal",
    fontSize: 16,
    fontStyle: "normal",
    lineSpacing: "normal",
    letterSpacing: "normal",
    textShadow: "0px 0px 0px rgba(0, 0, 0, 0)",
    textAlign: "center",
    textDecoration: "none",
    height: 30,
    width: 200,
    sizeAndCoordinates: {
      size: { height: 30, width: 200 },
      bbox: [0, 0, 0, 0],
    },
    contour: false,
    ...props,
  };

  store.initializeElements([...elements, newElement]);
  // store.selectElement([{ id: id, type: StudioElementTypes.text }]);
  return id;
};

export const addNewProductElement = async (
  library: Library,
  newProduct: SearchResult,
) => {
  const store = useStudioStore.getState();
  const id = await uuid();
  const elements = store.elements;
  const size = store.size;
  const newElement: ProductType = {
    id: id,
    type: StudioElementTypes.product,
    x: size.width / 2,
    y: size.height / 2 - 75,
    rotation: 0,
    horizontalFlip: false,
    verticalFlip: false,
    blur: 0,
    visibility: true,
    height: 150,
    width: 0,
    imageSrc: newProduct.srcId,
    shadow: "0px 0px 0px rgba(0, 0, 0, 0)",
    opacity: 1,
    sizeAndCoordinates: { size: newProduct.size, bbox: newProduct.bbox },
  };

  const base64 = await fetchImage(library, newProduct.srcId);
  const img = new Image();
  const handleLoad = () => {
    const aspectRatio = img.naturalWidth / img.naturalHeight;
    const calculatedWidth = 150 * aspectRatio;
    newElement.width = calculatedWidth;
    newElement.x = size.width / 2 - calculatedWidth / 2;
    store.setImageRecordById(newProduct.srcId, {
      srcId: newProduct.srcId,
      base64: base64,
      multiview: newProduct.multiview,
    });
    store.initializeElements([...elements, newElement]);
    store.selectElement([]);
  };
  img.onload = handleLoad;
  img.src = base64;
  return id;
};

export const addNewBackgroundElement = async (
  library: Library,
  newBackground: SearchResult,
  hasBackground?: boolean,
) => {
  const store = useStudioStore.getState();
  const id = await uuid();
  const elements = store.elements;
  const size = store.size;
  console.log(size);
  const newElement: BackgroundImageType = {
    id: id,
    type: StudioElementTypes.backgroundImage,
    x: 0,
    y: 0,
    rotation: 0,
    horizontalFlip: false,
    verticalFlip: false,
    blur: 0,
    visibility: true,
    height: size.height,
    width: size.width,
    imageSrc: newBackground.srcId,
    edit: false,
    sizeAndCoordinates: {
      size: size,
      bbox: [0, 0, 1, 1],
    },
    hasImage: true,
  };

  const base64 = await fetchImage(library, newBackground.srcId);
  const img = new Image();
  const handleLoad = () => {
    store.setImageRecordById(newBackground.srcId, {
      srcId: newBackground.srcId,
      base64: base64,
      multiview: newBackground.multiview,
    });
    if (hasBackground) {
      const newElements = elements.filter(
        (el) => el.type !== StudioElementTypes.backgroundImage,
      );
      store.initializeElements([newElement, ...newElements]);
    } else {
      store.initializeElements([newElement, ...elements]);
    }
    store.selectElement([]);
  };
  img.onload = handleLoad;
  img.src = base64;
  return id;
};

export const initializeHighRes = async (
  library: Library,
  setImageRecordById: (id: string, record: ImageRecordObject) => void,
  imageRecord: ImageRecord,
) => {
  return fetchImageAll(
    library,
    Object.keys(imageRecord).map((key) => key),
  )
    .then((base64s) => {
      base64s.forEach((base64, index) => {
        const key = Object.keys(imageRecord)[index];
        setImageRecordById(key, {
          ...imageRecord[key],
          base64: base64,
        });
      });
    })
    .catch((error) => {
      console.log(error);
    });
};

const elementOrderBase: { [key in StudioElementTypes]: number } = {
  [StudioElementTypes.canvasBackground]: 0,
  [StudioElementTypes.backgroundImage]: 1,
  [StudioElementTypes.designElement]: 2,
  [StudioElementTypes.text]: 3,
  [StudioElementTypes.product]: 4,
};

export function orderElementsByCategory(
  elements: StudioElement[],
): StudioElement[] {
  return elements.sort(
    (a, b) => elementOrderBase[a.type] - elementOrderBase[b.type],
  );
}

export async function uuid(seed: string = "", length: number = 16) {
  const inputString = seed + Math.random().toString();
  const buffer = await crypto.subtle.digest(
    "SHA-256",
    new TextEncoder().encode(inputString),
  );
  const hexDigits = Array.from(new Uint8Array(buffer)).map((byte) =>
    byte.toString(16).padStart(2, "0"),
  );
  return hexDigits.join("").substring(0, Math.min(length, 64));
}

export function bringElementToFront(selectedElement: SelectedElement) {
  const store = useStudioStore.getState();
  const elements = _.cloneDeep(store.elements);
  const elementIndex = elements.findIndex(
    (element) => element.id === selectedElement.id,
  );

  if (elementIndex === -1) return;

  const [elementToMove] = elements.splice(elementIndex, 1);

  if (selectedElement.type === StudioElementTypes.backgroundImage) {
    const lastBackgroundIndex = elements.reduce((lastIndex, element, index) => {
      return element.type === StudioElementTypes.backgroundImage
        ? index
        : lastIndex;
    }, -1);

    elements.splice(lastBackgroundIndex + 1, 0, elementToMove);
  } else {
    elements.push(elementToMove);
  }

  store.initializeElements(elements);
}
// export const createTextElementRemix

export const createTextElementFromTemplate = (
  element: ElementData,
  width: number,
  height: number,
) => {
  const css = element.properties.css;
  const { position, size } = calculateElementPositionAndSize(
    element.box,
    width,
    height,
  );
  const newTextElement: TextType = textBoilerPlate(
    element.key,
    position,
    size,
    css,
    element.box,
  );
  return newTextElement;
};

export const createProductElementFromTemplate = (
  element: ElementData,
  width: number,
  height: number,
) => {
  const { position, size } = calculateElementPositionAndSize(
    element.box,
    width,
    height,
  );
  const newProductElement: ProductType = productBoilerPlate(
    position,
    size,
    element.key,
    element.box,
  );
  return newProductElement;
};

export const createBackgroundElementFromTemplate = (
  element: ElementData,
  width: number,
  height: number,
) => {
  const { position, size } = calculateElementPositionAndSize(
    element.box,
    width,
    height,
  );
  const newBackgroundElement: BackgroundImageType = backgroundBoilerPlate(
    position,
    size,
    element.key,
    element.box,
    element.properties.css,
  );
  return newBackgroundElement;
};

export const productBoilerPlate = (
  position: Position,
  size: Size,
  imageSrc: string,
  bbox: number[],
  contour?: boolean,
): ProductType => {
  return {
    id: crypto.randomUUID(),
    x: position.x,
    y: position.y,
    rotation: 0,
    horizontalFlip: false,
    verticalFlip: false,
    blur: 0,
    visibility: true,
    height: size.height,
    width: size.width,
    sizeAndCoordinates: {
      size: {
        height: size.height,
        width: size.width,
      },
      bbox: bbox,
    },
    type: StudioElementTypes.product,
    imageSrc: imageSrc,
    shadow: "0px 0px 0px rgba(0, 0, 0, 0)",
    opacity: 1,
    contour: contour,
  };
};

export const backgroundBoilerPlate = (
  position: Position,
  size: Size,
  imageSrc: string,
  bbox: number[],
  css: any = {},
): BackgroundImageType => {
  return {
    id: crypto.randomUUID(),
    x: position.x,
    y: position.y,
    rotation: 0,
    horizontalFlip: false,
    verticalFlip: false,
    blur: 0,
    visibility: true,
    height: size.height,
    width: size.width,
    sizeAndCoordinates: {
      size: {
        height: size.height,
        width: size.width,
      },
      bbox: bbox,
    },
    type: StudioElementTypes.backgroundImage,
    imageSrc: imageSrc,
    edit: false,
    hasImage: true,
    css: css,
  };
};

export const textBoilerPlate = (
  text: string,
  position: Position,
  size: Size,
  css: any,
  bbox: number[],
  contour?: boolean,
): TextType => ({
  id: crypto.randomUUID(),
  x: position.x,
  y: position.y,
  width: size.width,
  height: size.height,
  rotation: 0,
  horizontalFlip: false,
  verticalFlip: false,
  blur: 0,
  visibility: true,
  sizeAndCoordinates: {
    size: {
      height: size.height,
      width: size.width,
    },
    bbox: bbox,
  },
  text: text,
  type: StudioElementTypes.text,
  backgroundColor: css.backgroundColor ?? "transparent",
  fontColor: css.color ?? "#000000",
  fontFamily: css.fontFamily ?? "Arial",
  fontSize: css.fontSize ? convertToPx(css.fontSize) : 16,
  fontStyle: css.fontStyle ?? "normal",
  fontWeight: css.fontWeight ?? "normal",
  letterSpacing: css.letterSpacing ? convertToPx(css.letterSpacing) : 0,
  lineSpacing: css.lineHeight ? parseFloat(css.lineHeight as string) : 1.5,
  textAlign: css.textAlign ?? "left",
  textDecoration: css.textDecoration
    ? (css.textDecoration as "underline" | "strikethrough" | "none")
    : "none",
  textShadow: css.textShadow ?? "none",
  textTransform: css.textTransform
    ? (css.textTransform as
        | "none"
        | "capitalize"
        | "uppercase"
        | "lowercase"
        | "full-width")
    : "none",
  whiteSpace: css.whiteSpace
    ? (css.whiteSpace as
        | "normal"
        | "nowrap"
        | "pre"
        | "pre-wrap"
        | "pre-line"
        | "break-spaces")
    : "normal",
  WebkitTextStroke: css.WebkitTextStroke ?? "0px",
  wordSpacing: css.wordSpacing ?? "normal",
  contour: contour,
});

export const calculateNormalizedBoundingBoxes = (
  containerRect: DOMRect,
  elements: DOMRect[],
) => {
  const getNormalizedBoundingBox = (rect: DOMRect) => {
    return [
      (rect.left - containerRect.left) / containerRect.width,
      (rect.top - containerRect.top) / containerRect.height,
      (rect.right - containerRect.left) / containerRect.width,
      (rect.bottom - containerRect.top) / containerRect.height,
    ];
  };

  return elements.map((element) => getNormalizedBoundingBox(element));
};

export const calculateNormalizedBoundingBox = (
  bg: Size,
  element: {
    size: Size;
    position: Position;
  },
) => {
  const bboxX0 = element.position.x / bg.width;
  const bboxY0 = element.position.y / bg.height;
  const bboxX1 = bboxX0 + element.size.width / bg.width;
  const bboxY1 = bboxY0 + element.size.height / bg.height;

  return [bboxX0, bboxY0, bboxX1, bboxY1];
};

export const convertToPx = (value: string | number): number => {
  if (typeof value === "number") return value;
  const baseFontSize = 16; // Default base font size for rem units
  const contextFontSize = 16; // Default context font size for em units

  const pxRegex = /^(\d+(?:\.\d+)?)px$/;
  const remRegex = /^(\d+(?:\.\d+)?)rem$/;
  const emRegex = /^(\d+(?:\.\d+)?)em$/;

  if (pxRegex.test(value)) {
    return parseFloat(value.match(pxRegex)![1]);
  } else if (remRegex.test(value)) {
    return parseFloat(value.match(remRegex)![1]) * baseFontSize;
  } else if (emRegex.test(value)) {
    return parseFloat(value.match(emRegex)![1]) * contextFontSize;
  } else {
    throw new Error(`Unsupported unit: ${value}`);
  }
};

export default useStudioStore;
