import React, { useState, useEffect } from "react";
import { Library } from "hedwigai";
import ToolTip from "../tooltip";
import { usePDF } from "react-to-pdf";
import {
  MissionView,
  Theme,
  useAccountStore,
  useBrandStore,
  usePrimaryMemoryStore,
  useThemeStore,
  useTypographyStore,
} from "../../lib/state";
import Toast from "../Toast";
import { RadarPersonalityUI } from "./radar-personality";
import { LockupGuideline, LogoGuideline, TitleGuideline } from "./guideline";
import { LockupUsage } from "./usage";
import {
  hexToCmyk,
  hexToRgb,
  hexToRgbArr,
  rgbToHsl,
  sampleColorData,
  shiftBrightness,
  shiftHue,
  shiftSaturation,
} from "../../lib/colorscience";
import { ColorData, Palette } from "../../lib/colorscience";
import { LoadingTypography } from "./loading";
import { Font } from "../../lib/typography";

interface BrandProps {
  library: Library;
}

interface SearchMenuProps {
  library: Library;
  viewMenu: boolean;
  onClick: (font: Font) => void;
}

const loadFont = async (font: Font) => {
  const files = font.files;
  const family = font.family;
  const regular = files.regular;

  const fontFace = new FontFace(family, `url(${regular})`);
  document.fonts.add(fontFace);
  await fontFace.load();
};

const FontMenu: React.FC<SearchMenuProps> = (props: SearchMenuProps) => {
  const [fontLoading, setFontLoading] = useState<NodeJS.Timeout>();
  const [fontOptions, setFontOptions] = useState<Font[]>();

  const getFont = (prompt: string | undefined) => {
    if (typeof prompt == "undefined") return;
    clearTimeout(fontLoading);
    setFontOptions(undefined);
    setFontLoading(
      setTimeout(() => {
        props.library.findTypography(prompt).then((fonts) => {
          setFontOptions(fonts);
        });
      }, 1000),
    );
  };

  return (
    <div
      className="search-menu"
      style={{ display: props.viewMenu ? "flex" : "none" }}
    >
      <div className="query">
        <input
          type="text"
          onChange={(ev) => getFont(ev.target.value)}
          placeholder="Find Font"
        />
      </div>
      {fontOptions &&
        fontOptions.slice(0, 5).map((font, index) => {
          loadFont(font).then(() => {});
          return (
            <div
              className="option"
              key={index}
              onClick={() => props.onClick(font)}
              style={{ fontFamily: font.family }}
            >
              {font.family.slice(0, 4)}
            </div>
          );
        })}
      {typeof fontOptions != "undefined" && fontOptions.length == 0 && (
        <div className="option no-results">No results</div>
      )}
      {typeof fontOptions == "undefined" && <LoadingTypography />}
    </div>
  );
};

interface AdjustColorMenuProps {
  library: Library;
  viewMenu: boolean;
  extendedPalette: boolean;
  setExtendedPalette: (value: boolean) => void;
  handleHue: (value: number) => void;
  handleSaturation: (value: number) => void;
  handleBrightness: (value: number) => void;
}

const AdjustColorMenu: React.FC<AdjustColorMenuProps> = (
  props: AdjustColorMenuProps,
) => {
  return (
    <div
      className="adjust-menu"
      style={{ display: props.viewMenu ? "flex" : "none" }}
    >
      <div className="input-label">
        <label>Hue</label>
      </div>
      <div className="input-range">
        <div
          className="color-track hue-track"
          onClick={(ev) => {
            // get the position of the click
            const rect = ev.currentTarget.getBoundingClientRect();
            const x = ev.clientX - rect.left;
            const fraction = (x - rect.width / 2.0) / (rect.width / 2.0);
            props.handleHue(fraction);
            const cursor = ev.currentTarget.parentNode?.querySelector(
              ".cursor",
            ) as HTMLDivElement;
            // Get position of the cursor
            if (cursor == null) return;
            cursor.style.transform = `translate3d(${x - cursor.getBoundingClientRect().width / 2.0}px, -25%, 0)`;
          }}
        >
          <span />
        </div>
        <div
          className="cursor"
          style={{ transform: "translate3d(650%, -25%, 0)" }}
        />
      </div>

      <div className="input-label">
        <label>Saturation</label>
      </div>
      <div className="input-range">
        <div
          className="color-track saturation-track"
          onClick={(ev) => {
            // get the position of the click
            const rect = ev.currentTarget.getBoundingClientRect();
            const x = ev.clientX - rect.left;
            const fraction = (x - rect.width / 2.0) / (rect.width / 2.0);
            props.handleSaturation(fraction);
            const cursor = ev.currentTarget.parentNode?.querySelector(
              ".cursor",
            ) as HTMLDivElement;
            // Get position of the cursor
            if (cursor == null) return;
            cursor.style.transform = `translate3d(${x - cursor.getBoundingClientRect().width / 2.0}px, -25%, 0)`;
          }}
        >
          <span />
        </div>
        <div
          className="cursor"
          style={{ transform: "translate3d(650%, -25%, 0)" }}
        />
      </div>

      <div className="input-label">
        <label>Brightness</label>
      </div>
      <div className="input-range">
        <div
          className="color-track brightness-track"
          onClick={(ev) => {
            // get the position of the click
            const rect = ev.currentTarget.getBoundingClientRect();
            const x = ev.clientX - rect.left;
            const fraction = (x - rect.width / 2.0) / (rect.width / 2.0);
            props.handleBrightness(fraction);
            const cursor = ev.currentTarget.parentNode?.querySelector(
              ".cursor",
            ) as HTMLDivElement;
            // Get position of the cursor
            if (cursor == null) return;
            cursor.style.transform = `translate3d(${x - cursor.getBoundingClientRect().width / 2.0}px, -25%, 0)`;
          }}
        >
          <span />
        </div>
        <div
          className="cursor"
          style={{ transform: "translate3d(650%, -25%, 0)" }}
        />
      </div>
      {/* <div className="check-box">
        <h2>Use Extended Palette</h2>
        <input type="checkbox" checked={props.extendedPalette} onChange={(ev) => props.setExtendedPalette(ev.target.checked)} />
      </div> */}
    </div>
  );
};

interface AdjustFontMenuProps {
  library: Library;
  viewMenu: boolean;
  inputTitleWeights: string[];
  inputCaptionWeights: string[];
  titleWeights: string[];
  captionWeights: string[];
  handleTitleWeights: (index: number) => void;
  handleCaptionWeights: (index: number) => void;
}

const AdjustFontMenu: React.FC<AdjustFontMenuProps> = (
  props: AdjustFontMenuProps,
) => {
  return (
    <div
      className="adjust-menu"
      style={{ display: props.viewMenu ? "flex" : "none" }}
    >
      <div className="input-label">
        <label>Heading</label>
      </div>
      <div className="input-values">
        {props.inputTitleWeights.map((el, index) => {
          return (
            <div
              key={index}
              className={`weight ${props.titleWeights.includes(props.inputTitleWeights[index]) ? "checked" : ""}`}
              onClick={() => props.handleTitleWeights(index)}
            >
              {el}
            </div>
          );
        })}
      </div>

      <div className="input-label">
        <label>Caption</label>
      </div>
      <div className="input-values">
        {props.inputCaptionWeights.map((el, index) => {
          return (
            <div
              key={index}
              className={`weight ${props.captionWeights.includes(props.inputCaptionWeights[index]) ? "checked" : ""}`}
              onClick={() => props.handleCaptionWeights(index)}
            >
              {el}
            </div>
          );
        })}
      </div>
    </div>
  );
};

interface AdjustVersionMenuProps {
  library: Library;
  viewMenu: boolean;
  memberName: string;
  onClick: (font: string) => void;
}

const AdjustVersionMenu: React.FC<AdjustVersionMenuProps> = (
  props: AdjustVersionMenuProps,
) => {
  return (
    <div
      className="adjust-menu"
      style={{ display: props.viewMenu ? "flex" : "none" }}
    >
      <label>Main</label>
      <label>{props.memberName}'s version</label>
    </div>
  );
};

// Range Slider for Font in the Bottom Bar
// The Range Slider allows selecting multiple stops for
// the banner and subheading
// Every Member Gets to Save their version separate from the Main one
// All Members can see each other's brand book version and the Main one

const Brand: React.FC<BrandProps> = (props: BrandProps) => {
  const missionView = usePrimaryMemoryStore((state) => state.missionView);
  const setMissionView = usePrimaryMemoryStore((state) => state.setMissionView);
  const banner = useTypographyStore((state) => state.banner);
  const setBanner = useTypographyStore((state) => state.setBanner);
  const caption = useTypographyStore((state) => state.caption);
  const setCaption = useTypographyStore((state) => state.setCaption);
  const theme = useThemeStore((state) => state.theme);
  const workspaceName = useAccountStore((state) => state.workspaceName);
  const memberName = useAccountStore((state) => state.memberName);
  const workspaceLogo = useAccountStore((state) => state.workspaceLogo);
  const [busyBackgroundUrl, setBusyBackgroundUrl] = useState<string>();
  const [plainBackgroundUrl, setPlainBackgroundUrl] = useState<string>();
  const BRAND_ID = "main";
  const [mission, setMission] = useState<string>();
  const setBrandMission = useBrandStore((state) => state.setBrandMission);
  const brandLogo = useBrandStore((state) => state.brandLogo);
  const setBrandLogo = useBrandStore((state) => state.setBrandLogo);
  const brandLockupSpace = useBrandStore((state) => state.lockupSpace);
  const setLockupSpace = useBrandStore((state) => state.setLockupSpace);
  const brandClearSpace = useBrandStore((state) => state.clearSpace);
  const setClearSpace = useBrandStore((state) => state.setClearSpace);
  const brandLockupValue = useBrandStore((state) => state.lockupValue);
  const setLockupValue = useBrandStore((state) => state.setLockupValue);
  const [configMenu, setConfigMenu] = useState<string>();
  const [brandLogoUrl, setBrandLogoUrl] = React.useState<string>();
  const [inputTitleWeights, setInputTitleWeights] = useState<string[]>([]);
  const [inputCaptionWeights, setInputCaptionWeights] = useState<string[]>([]);
  const [titleWeights, setTitleWeights] = useState<string[]>([]);
  const [captionWeights, setCaptionWeights] = useState<string[]>([]);
  const { toPDF, targetRef } = usePDF({ filename: "brand.pdf" });
  const handleHue = (value: number) => {
    // Shift the hue of the color based
    // on a fraction between -1 to 1
    primaryPalette.color.forEach((colorData, colorIndex) => {
      const color = shiftHue(colorData, value * 180);
      handlePrimaryPaletteColorChange(colorIndex, color);
    });
  };

  const handleSaturation = (value: number) => {
    primaryPalette.color.forEach((colorData, colorIndex) => {
      const color = shiftSaturation(colorData, value);
      handlePrimaryPaletteColorChange(colorIndex, color);
    });
  };

  const handleBrightness = (value: number) => {
    primaryPalette.color.forEach((colorData, colorIndex) => {
      const color = shiftBrightness(colorData, value);
      handlePrimaryPaletteColorChange(colorIndex, color);
    });
  };

  useEffect(() => {
    props.library.searchImageBackground("busy background", 4).then((frames) => {
      // frames["response"] = frames["response"];
      for (const item of frames["response"]) {
        props.library.getFileUrl(item["id"]).then((value) => {
          console.log(value);
          if (value.length == 0) return;
          const url = value[0]["url"];
          setBusyBackgroundUrl(url);
        });
        break;
      }
    });

    props.library.searchImageBackground("plain color", 4).then((frames) => {
      // frames["response"] = JSON.parse(frames["response"]);
      for (const item of frames["response"]) {
        props.library.getFileUrl(item["id"]).then((value) => {
          console.log(value);
          if (value.length == 0) return;
          const url = value[0]["url"];
          setPlainBackgroundUrl(url);
        });
        break;
      }
    });
  }, []);

  const [primaryPalette, setPrimaryPalette] = useState<Palette>({
    color: [],
  });

  const [secondaryPalette, setSecondaryPalette] = useState<Palette>({
    color: [],
  });

  const [tertiaryPalette, setTertiaryPalette] = useState<Palette>({
    color: [],
  });

  useEffect(() => {
    // load primary palette
    props.library.getBrandPalette(BRAND_ID).then((response) => {
      if (response.length == 0) return;
      const result = response[0];
      setPrimaryPalette({ color: result["primary_palette"] });
      setSecondaryPalette({ color: result["secondary_palette"] });
      setTertiaryPalette({ color: result["tertiary_palette"] });
    });

    props.library.getBrandHeadingFont(BRAND_ID).then((response) => {
      if (response.length == 0) return;
      const result = response[0];
      const heading: Font = {
        family: result["family"],
        files: result["files"],
      };
      setBanner(heading);
      setTitleWeights(Object.keys(heading.files));
    });
    props.library.getBrandBodyFont(BRAND_ID).then((response) => {
      if (response.length == 0) return;
      const result = response[0];
      const body: Font = {
        family: result["family"],
        files: result["files"],
      };
      setCaption(body);
      setCaptionWeights(Object.keys(body.files));
    });
  }, []);

  const save = () => {
    if (primaryPalette.color.length > 0) {
      props.library
        .setBrandPrimaryPalette(BRAND_ID, primaryPalette.color)
        .then((response) => {
          Toast(`Primary Palette ✨`);
        });
    }
    if (secondaryPalette.color.length > 0) {
      props.library
        .setBrandSecondaryPalette(BRAND_ID, secondaryPalette.color)
        .then((response) => {
          Toast(`Secondary Palette ✨`);
        });
    }
    if (tertiaryPalette.color.length > 0) {
      props.library
        .setBrandTertiaryPalette(BRAND_ID, tertiaryPalette.color)
        .then((response) => {
          Toast(`Tertiary Palette ✨`);
        });
    }

    if (typeof banner != "undefined") {
      // Get files from titleWeights
      const bannerFiles: { [key: string]: string } = {};
      Object.keys(banner.files)
        .filter((k) => titleWeights.includes(k))
        .forEach((e) => {
          bannerFiles[e] = banner.files[e];
        });
      props.library
        .setBrandHeadingFont(BRAND_ID, banner?.family, bannerFiles)
        .then((response) => {
          Toast(`Heading Font ✨`);
        });
    }

    if (typeof caption != "undefined") {
      const bodyFiles: { [key: string]: string } = {};
      Object.keys(caption.files)
        .filter((k) => captionWeights.includes(k))
        .forEach((e) => {
          bodyFiles[e] = caption.files[e];
        });
      props.library
        .setBrandBodyFont(BRAND_ID, caption?.family, bodyFiles)
        .then((response) => {
          Toast(`Body Font ✨`);
        });
    }
  };

  function handlePrimaryPaletteColorChange(
    colorIndex: number,
    newColor: string,
  ) {
    const all = [...primaryPalette.color];
    all[colorIndex] = newColor;
    setPrimaryPalette({ color: all });
  }

  function handleSecondaryPaletteColorChange(
    colorIndex: number,
    newColor: string,
  ) {
    const all = [...secondaryPalette.color];
    const update: ColorData[] = [
      ...all.slice(0, colorIndex),
      newColor,
      ...all.slice(colorIndex + 1),
    ];
    setSecondaryPalette({ color: update });
  }

  function handleTertiaryPaletteColorChange(
    colorIndex: number,
    newColor: string,
  ) {
    const all = [...tertiaryPalette.color];
    const update: string[] = [
      ...all.slice(0, colorIndex),
      newColor,
      ...all.slice(colorIndex + 1),
    ];
    setTertiaryPalette({ color: update });
  }

  function AddColor() {
    if (primaryPalette.color.length == 10) {
      Toast(`Palette 🎨 can have only 10 colors`);
      return;
    }
    const all = [...primaryPalette.color];
    const update: string[] = [...all, "#000000"];
    setPrimaryPalette({ color: update });
  }

  function SubtractColor() {
    if (primaryPalette.color.length == 2) {
      Toast(`Palette 🎨 must have at 2 colors`);
      return;
    }
    const all = [...primaryPalette.color];
    const update: string[] = [...all.slice(0, all.length - 1)];
    setPrimaryPalette({ color: update });
  }

  const handleTitleWeights = (index: number) => {
    if (titleWeights.includes(inputTitleWeights[index])) {
      // Remove the weight
      const update = titleWeights.filter(
        (item) => item !== inputTitleWeights[index],
      );
      setTitleWeights(update);
    } else {
      // Add the weight
      setTitleWeights([...titleWeights, inputTitleWeights[index]]);
    }
  };

  const handleCaptionWeights = (index: number) => {
    if (captionWeights.includes(inputCaptionWeights[index])) {
      // Remove the weight
      const update = captionWeights.filter(
        (item) => item !== inputCaptionWeights[index],
      );
      setCaptionWeights(update);
    } else {
      // Add the weight
      setCaptionWeights([...captionWeights, inputCaptionWeights[index]]);
    }
  };

  useEffect(() => {
    if (typeof banner == "undefined" || banner == null) return;
    const weights = Object.keys(banner.files).filter(
      (k) =>
        !k.includes("italic") &&
        (isNaN(Number(k.charAt(0))) ||
          (!isNaN(Number(k.charAt(0))) && Number(k.charAt(0)) > 3)),
    );
    loadFont(banner).then(() => {});
    setInputTitleWeights(weights);
  }, [banner]);

  useEffect(() => {
    if (!caption) return;
    const weights = Object.keys(caption.files).filter(
      (k) =>
        isNaN(Number(k.charAt(0))) ||
        (!isNaN(Number(k.charAt(0))) && Number(k.charAt(0)) < 5),
    );
    loadFont(caption).then(() => {});
    setInputCaptionWeights(weights);
  }, [caption]);

  useEffect(() => {
    if (brandLogo === undefined) return;
    props.library
      .getFileUrl(brandLogo)
      .then((response) => {
        if (response.length > 0) {
          setBrandLogoUrl(response[0]["url"]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [brandLogo]);

  useEffect(() => {
    props.library.getBrandMission(BRAND_ID).then((response) => {
      if (response.success) {
        setBrandMission(response.name);
        setMission(response.name);
      }
    });

    props.library.getBrandLogo(BRAND_ID).then((response) => {
      if (response.length > 0) {
        const item = response[0];
        setBrandLogo(item["file_id"]);
        setLockupSpace(item["lockup_space"]);
        setClearSpace(item["clear_space"]);
        setLockupValue(item["lockup_value"]);
      }
    });
  }, []);

  const [fillColor, setFillColor] = useState<string>("#000000");
  const [textColor, setTextColor] = useState<string>("#ffffff");
  useEffect(() => {
    setFillColor(theme === Theme.LIGHT ? "#ffffff" : "#000000");
    setTextColor(theme === Theme.LIGHT ? "#000000" : "#ffffff");
  }, [theme]);
  return (
    <div className="brand-book" ref={targetRef}>
      <div className="banner">
        <h2 style={{ fontFamily: banner?.family }}>{workspaceName}</h2>
      </div>
      <div className="panel">
        <div className="text-card">
          <div
            className="card mission-card"
            onClick={() => {
              missionView == MissionView.BRAND_MISSION
                ? setMissionView(undefined)
                : setMissionView(MissionView.BRAND_MISSION);
            }}
          >
            <span>Our Mission</span> {mission}
          </div>
        </div>
        <div className="brand-icon" style={{ fontFamily: banner?.family }}>
          <LockupGuideline
            lockupSpace={brandLockupSpace as string}
            clearSpace={brandClearSpace as string}
            logoUrl={brandLogoUrl as string}
            lockupValue={brandLockupValue as string}
            fontFamily={banner?.family as string}
            fillColor={fillColor}
            textColor={textColor}
            canvasWidthInRem={15}
            canvasHeightInRem={7}
            logoSizeInRem={6.65}
            fontSizeInRem={3}
          />
          <LockupGuideline
            lockupSpace={brandLockupSpace as string}
            clearSpace={brandClearSpace as string}
            logoUrl={brandLogoUrl as string}
            lockupValue={brandLockupValue as string}
            fontFamily={banner?.family as string}
            fillColor={fillColor}
            textColor={textColor}
            canvasWidthInRem={15}
            canvasHeightInRem={7}
            logoSizeInRem={6.65}
            fontSizeInRem={3}
          />
          <LogoGuideline
            lockupSpace={brandLockupSpace as string}
            clearSpace={brandClearSpace as string}
            logoUrl={brandLogoUrl as string}
            lockupValue={brandLockupValue as string}
            fillColor={fillColor}
            textColor={textColor}
            canvasWidthInRem={15}
            canvasHeightInRem={7}
            logoSizeInRem={6.65}
            fontSizeInRem={3}
          />
          <LogoGuideline
            lockupSpace={brandLockupSpace as string}
            clearSpace={brandClearSpace as string}
            logoUrl={brandLogoUrl as string}
            lockupValue={brandLockupValue as string}
            fillColor={fillColor}
            textColor={textColor}
            canvasWidthInRem={15}
            canvasHeightInRem={7}
            logoSizeInRem={6.65}
            fontSizeInRem={3}
          />
          <TitleGuideline
            lockupSpace={brandLockupSpace as string}
            clearSpace={brandClearSpace as string}
            logoUrl={brandLogoUrl as string}
            lockupValue={brandLockupValue as string}
            fontFamily={banner?.family as string}
            fillColor={fillColor}
            textColor={textColor}
            canvasWidthInRem={15}
            canvasHeightInRem={7}
            logoSizeInRem={6.65}
            fontSizeInRem={3}
          />
          <TitleGuideline
            lockupSpace={brandLockupSpace as string}
            clearSpace={brandClearSpace as string}
            logoUrl={brandLogoUrl as string}
            lockupValue={brandLockupValue as string}
            fontFamily={banner?.family as string}
            fillColor={fillColor}
            textColor={textColor}
            canvasWidthInRem={15}
            canvasHeightInRem={7}
            logoSizeInRem={6.65}
            fontSizeInRem={3}
          />
          <span
            className="settings material-symbols-outlined"
            onClick={() => {
              missionView == MissionView.BRAND_LOGO_MISSION
                ? setMissionView(undefined)
                : setMissionView(MissionView.BRAND_LOGO_MISSION);
            }}
          >
            settings
          </span>
        </div>
      </div>
      <div className="panel">
        <div className="text-card">
          <div className="card">
            <span>Our Primary Palette </span> should always be represented in
            one of these colours on this page. They are used to provide
            simplicity and consistency throughout all brand communications.
          </div>
        </div>
        <div className="primary-palette">
          {primaryPalette.color.map((colorData, colorIndex) => {
            const cmyk = hexToCmyk(colorData);
            const cmykStr = `C ${cmyk.c} | M ${cmyk.m} | Y ${cmyk.y} | K ${cmyk.k}`;
            return (
              <div
                key={colorIndex}
                style={{ backgroundColor: colorData }}
                className="color"
              >
                <input
                  type="color"
                  value={colorData}
                  style={{
                    backgroundColor: `rgba(${hexToRgb(colorData)}, 1.0)`,
                    width: "100%",
                    height: "100%",
                    border: "none",
                    cursor: "pointer",
                  }}
                  onChange={(e) =>
                    handlePrimaryPaletteColorChange(colorIndex, e.target.value)
                  }
                />
                <div className="label">
                  <p>{colorData.toUpperCase()}</p>
                  <p>{cmykStr}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="panel">
        <div className="text-card">
          <div className="card">
            <span>Our Extended Palette </span>should only be used sparingly to
            support the brand primary colour palette, for use in social assets,
            icons, stickers and backgrounds. Only a single colour from the
            extended colour palette can be used at a time so as not to dominate
            from the primary colour palette.
          </div>
        </div>
        <div className="extended-palette">
          <div className="secondary-palette">
            {secondaryPalette.color.map((colorData, colorIndex) => {
              const cmyk = hexToCmyk(colorData);
              const cmykStr = `C ${cmyk.c} | M ${cmyk.m} | Y ${cmyk.y} | K ${cmyk.k}`;
              return (
                <div
                  key={colorIndex}
                  style={{ backgroundColor: colorData }}
                  className="color"
                >
                  <input
                    type="color"
                    value={colorData}
                    style={{
                      backgroundColor: `rgba(${hexToRgb(colorData)}, 1.0)`,
                      width: "100%",
                      height: "100%",
                      border: "none",
                      cursor: "pointer",
                    }}
                    onChange={(e) =>
                      handleSecondaryPaletteColorChange(
                        colorIndex,
                        e.target.value,
                      )
                    }
                  />
                  <div className="label">
                    <p>{colorData.toUpperCase()}</p>
                    <p>{cmykStr}</p>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="tertiary-palette">
            {tertiaryPalette.color.map((colorData, colorIndex) => {
              const cmyk = hexToCmyk(colorData);
              const cmykStr = `C ${cmyk.c} | M ${cmyk.m} | Y ${cmyk.y} | K ${cmyk.k}`;
              return (
                <div
                  key={colorIndex}
                  style={{ backgroundColor: colorData }}
                  className="color"
                >
                  <input
                    type="color"
                    value={colorData}
                    style={{
                      backgroundColor: `rgba(${hexToRgb(colorData)}, 1.0)`,
                      width: "100%",
                      height: "100%",
                      border: "none",
                      cursor: "pointer",
                    }}
                    onChange={(e) =>
                      handleTertiaryPaletteColorChange(
                        colorIndex,
                        e.target.value,
                      )
                    }
                  />
                  <div className="label">
                    <p>{colorData.toUpperCase()}</p>
                    <p>{cmykStr}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="panel">
        <div className="text-card">
          <div className="card">
            <span>Our Color Palette</span> has most dominance on the layout.
            Only use extended palette to highlight message or the idea.
          </div>
        </div>
        <div className="color-distribution">
          <div className="distribution">
            {[
              sampleColorData(primaryPalette.color),
              sampleColorData(secondaryPalette.color),
              sampleColorData(primaryPalette.color),
            ].map((colorData, colorIndex) => {
              return (
                <div
                  key={colorIndex}
                  className="color"
                  style={{
                    backgroundColor: `rgba(${hexToRgb(colorData)}, 1.0)`,
                  }}
                ></div>
              );
            })}
          </div>
          <div className="distribution">
            {[
              sampleColorData(primaryPalette.color),
              sampleColorData(tertiaryPalette.color),
              sampleColorData(primaryPalette.color),
            ].map((colorData, colorIndex) => {
              return (
                <div
                  key={colorIndex}
                  className="color"
                  style={{
                    backgroundColor: `rgba(${hexToRgb(colorData)}, 1.0)`,
                  }}
                ></div>
              );
            })}
          </div>
          <div className="distribution">
            {[
              sampleColorData(primaryPalette.color),
              sampleColorData(secondaryPalette.color),
              sampleColorData(primaryPalette.color),
            ].map((colorData, colorIndex) => {
              return (
                <div
                  key={colorIndex}
                  className="color"
                  style={{
                    backgroundColor: `rgba(${hexToRgb(colorData)}, 1.0)`,
                  }}
                ></div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="panel panel-five">
        <div className="text-card">
          <div className="card">
            <span>Our Typeface</span> is
            <span>
              {typeof banner != "undefined" ? " " + banner.family : ""}
            </span>
            {typeof caption != "undefined" && caption.family ? " and " : null}
            {typeof caption != "undefined" ? (
              <span>{caption.family}</span>
            ) : null}
            .{" "}
            {typeof caption != "undefined"
              ? null
              : "It's light-hearted and crisp when used for body text, yet still retains punch when used in uppercase – perfect for buttons and short labels."}
            The thicker weights have a distinctly friendlier character, which we
            recommended as headlines.
          </div>
        </div>
        <div className="typeface">
          <p style={{ fontFamily: banner?.family, fontWeight: "bolder" }}>
            {banner?.family}
          </p>
          <p style={{ fontFamily: caption?.family, fontWeight: "bolder" }}>
            {caption?.family}
          </p>
        </div>
      </div>
      <div className="panel">
        <div className="text-card">
          <div className="card">
            <span>Our Headlines</span> are in {titleWeights.join(", ")}. The
            exception to using the thinner weights as headline is when the
            message is only a single sentence.
          </div>
        </div>
        <div className="typeface">
          {titleWeights.map((weight, index) => {
            if (isNaN(Number(weight.charAt(0)))) {
              return (
                <p
                  key={index}
                  style={{ fontFamily: banner?.family, fontStyle: weight }}
                >
                  {weight}
                </p>
              );
            } else {
              return (
                <p
                  key={index}
                  style={{ fontFamily: banner?.family, fontWeight: weight }}
                >
                  {weight}
                </p>
              );
            }
          })}
        </div>
      </div>
      <div className="panel">
        <div className="text-card">
          <div className="card">
            <span>Our Subheads & Body</span> text are in{" "}
            {captionWeights.join(", ")}. The subhead should be smaller and
            thinner than the headline.
          </div>
        </div>
        <div className="typeface">
          {captionWeights.map((weight, index) => {
            if (isNaN(Number(weight.charAt(0)))) {
              return (
                <p
                  key={index}
                  style={{ fontFamily: caption?.family, fontStyle: weight }}
                >
                  {weight}
                </p>
              );
            } else {
              return (
                <p
                  key={index}
                  style={{ fontFamily: caption?.family, fontWeight: weight }}
                >
                  {weight}
                </p>
              );
            }
          })}
        </div>
      </div>
      {/* <div className="panel">
        <div className="brand-personality">
          <h2>12 Archetypes</h2>
          <RadarPersonalityUI library={props.library} />
        </div>
      </div> */}
      <div className="panel">
        <div className="text-card">
          <div className="card">
            <span>Our Logo </span>
            can be in white or black. Ensure the image is never interfering with
            the logo. Incase of a busy image, the logo should always come on a
            coloured patch in the primary colours. The logo should be in 100%
            opacity and the background patch in 80% opacity.
          </div>
        </div>
        <div className="logo-options">
          <LockupUsage
            lockupSpace="e"
            clearSpace="h"
            logoUrl={brandLogoUrl as string}
            backgroundUrl={plainBackgroundUrl as string}
            fillColor={"transparent"}
            fontFamily={banner?.family as string}
            textColor={textColor}
            lockupValue={brandLockupValue as string}
            canvasWidthInRem={18}
            canvasHeightInRem={9}
            logoSizeInRem={6.65}
            fontSizeInRem={3}
          />
          <LockupUsage
            lockupSpace="e"
            clearSpace="h"
            logoUrl={brandLogoUrl as string}
            backgroundUrl={plainBackgroundUrl as string}
            fillColor={"transparent"}
            fontFamily={banner?.family as string}
            textColor={textColor}
            lockupValue={brandLockupValue as string}
            canvasWidthInRem={18}
            canvasHeightInRem={9}
            logoSizeInRem={6.65}
            fontSizeInRem={3}
          />
          <LockupUsage
            lockupSpace="e"
            clearSpace="h"
            logoUrl={brandLogoUrl as string}
            backgroundUrl={busyBackgroundUrl as string}
            // hex code
            fillColor={primaryPalette.color[0]}
            fontFamily={banner?.family as string}
            textColor={textColor}
            lockupValue={brandLockupValue as string}
            canvasWidthInRem={18}
            canvasHeightInRem={9}
            logoSizeInRem={6.65}
            fontSizeInRem={3}
          />
          <LockupUsage
            lockupSpace="e"
            clearSpace="h"
            logoUrl={brandLogoUrl as string}
            backgroundUrl={busyBackgroundUrl as string}
            fillColor={primaryPalette.color[0]}
            fontFamily={banner?.family as string}
            textColor={textColor}
            lockupValue={brandLockupValue as string}
            canvasWidthInRem={18}
            canvasHeightInRem={9}
            logoSizeInRem={6.65}
            fontSizeInRem={3}
          />
        </div>
      </div>
      {/* <div className="panel">
        <div className="brand-format">
          <div className="format-list">
            <input className="radio" type="radio" name="card" id="card-1" />
            <label className="content" htmlFor="card-1">
              <span className="icon">
                <i className="fas fa-sun"></i>
              </span>
              <h3 className="card-title">
                Serra da Freita, Vale de Cambra, Portugal
                <span className="subtitle">@hed</span>
              </h3>
            </label>
            <input className="radio" type="radio" name="card" id="card-2" />
            <label className="content" htmlFor="card-2">
              <span className="icon material-symbols-outlined">menu_book</span>
              <h3 className="card-title">
                París, Paris, France
                <span className="subtitle">@lolaguti</span>
              </h3>
            </label>
            <input className="radio" type="radio" name="card" id="card-3" />
            <label className="content" htmlFor="card-3">
              <span className="icon">
                <i className="fas fa-cloud-moon"></i>
              </span>
              <h3 className="card-title">
                La Joue du Loup, Le Dévoluy, France
                <span className="subtitle">@quentindrphotography</span>
              </h3>
            </label>
            <input className="radio" type="radio" name="card" id="card-4" />
            <label className="content" htmlFor="card-4">
              <span className="icon">
                <i className="fas fa-snowflake"></i>
              </span>
              <h3 className="card-title">
                Jasper National Park, Jasper, Canada
                <span className="subtitle">@punttim</span>
              </h3>
            </label>
            <input className="radio" type="radio" name="card" id="card-5" />
            <label className="content" htmlFor="card-5">
              <span className="icon">
                <i className="fas fa-poo-storm"></i>
              </span>
              <h3 className="card-title">
                Sioux Falls, United States
                <span className="subtitle">@eugenetriguba</span>
              </h3>
            </label>
          </div>
        </div>
      </div> */}

      <div className="brand-config">
        <div className="palette">
          {primaryPalette.color.map((colorData, colorIndex) => {
            return (
              <div
                key={colorIndex}
                className="color-data"
                style={{
                  backgroundColor: `rgba(${hexToRgb(colorData)}, 1.0)`,
                }}
              >
                <input
                  type="color"
                  value={colorData}
                  style={{
                    backgroundColor: `rgba(${hexToRgb(colorData)}, 1.0)`,
                    cursor: "pointer",
                  }}
                  onChange={(e) =>
                    handlePrimaryPaletteColorChange(colorIndex, e.target.value)
                  }
                />
              </div>
            );
          })}
          <div className="palette-mod">
            <ToolTip text="Add Color">
              <span className="icon add-icon" onClick={AddColor} />
            </ToolTip>
            <ToolTip text="Subtract Color">
              <span className="icon subtract-icon" onClick={SubtractColor} />
            </ToolTip>
          </div>
        </div>

        <div className="typography">
          <div
            className="item"
            style={{ fontFamily: banner?.family }}
            onClick={() => {
              setMissionView(MissionView.TYPOGRAPHY);
            }}
          >
            <p>Banner</p>
            <FontMenu
              library={props.library}
              onClick={setBanner}
              viewMenu={configMenu == "banner"}
            />
            <div
              className="action-button"
              onClick={() =>
                configMenu != "banner"
                  ? setConfigMenu("banner")
                  : setConfigMenu(undefined)
              }
            >
              <span className="material-symbols-outlined">format_size</span>
            </div>
          </div>

          <div className="item" style={{ fontFamily: caption?.family }}>
            <p>Caption</p>
            <FontMenu
              library={props.library}
              onClick={setCaption}
              viewMenu={configMenu == "caption"}
            />
            <div
              className="action-button"
              onClick={() =>
                configMenu != "caption"
                  ? setConfigMenu("caption")
                  : setConfigMenu(undefined)
              }
            >
              <span className="material-symbols-outlined">format_size</span>
            </div>
          </div>
        </div>

        <div className="interaction">
          {/* <ToolTip text="Adjust Colors">
            <AdjustColorMenu
              library={props.library}
              handleHue={handleHue}
              extendedPalette={extendedPalette}
              setExtendedPalette={setExtendedPalette}
              handleSaturation={handleSaturation}
              handleBrightness={handleBrightness}
              viewMenu={configMenu == "palette"}
            />
            <div
              className="button"
              onClick={() =>
                configMenu != "palette"
                  ? setConfigMenu("palette")
                  : setConfigMenu(undefined)
              }
            >
              <span className="material-symbols-outlined">tune</span>
            </div>
          </ToolTip> */}
          <ToolTip text="Adjust Colors">
            <AdjustFontMenu
              library={props.library}
              inputTitleWeights={inputTitleWeights}
              inputCaptionWeights={inputCaptionWeights}
              titleWeights={titleWeights}
              captionWeights={captionWeights}
              handleTitleWeights={handleTitleWeights}
              handleCaptionWeights={handleCaptionWeights}
              viewMenu={configMenu == "typeface"}
            />
            <div
              className="button"
              onClick={() =>
                configMenu != "typeface"
                  ? setConfigMenu("typeface")
                  : setConfigMenu(undefined)
              }
            >
              <span className="material-symbols-outlined">format_shapes</span>
            </div>
          </ToolTip>
          <ToolTip text="Save">
            <AdjustVersionMenu
              library={props.library}
              onClick={() => {}}
              viewMenu={configMenu == "version"}
              memberName={memberName as string}
            />
            <div
              className="button"
              onClick={
                () => save()
                // configMenu != "version"
                //   ? setConfigMenu("version")
                //   : setConfigMenu(undefined)
              }
            >
              <span className="material-symbols-outlined">save</span>
            </div>
          </ToolTip>

          {/* <ToolTip text="Export as PDF">
            <div className="button" onClick={() => {
              console.log(targetRef.current)
              toPDF()
            }}>
              <span className="material-symbols-outlined">export_notes</span>
            </div>
          </ToolTip> */}
        </div>
      </div>
    </div>
  );
};

export default Brand;
