import React from "react";

const BoxSpinner: React.FC<{}> = () => {
  return (
    <div className="box-spinner">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export default BoxSpinner;
