import { Navigate, Outlet, useNavigate } from "react-router-dom";
import { Library } from "hedwigai";
import React, { useEffect } from "react";
import { useAccountStore } from "../lib/state";

interface PrivateRouteProps {
  library: Library;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ library }) => {
  const token = useAccountStore((state) => state.token);

  const navigate = useNavigate();

  useEffect(() => {
    if (!token) {
      navigate("/");
    }
  }, [token]);

  return <Outlet />;
};

export default PrivateRoute;
