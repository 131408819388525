import { removeNonAlphaNumFromEnd, stringToNumericValue } from "./text";

enum HubView {
  AllAssets,
  MyCreatives,
  SettingsAndMembers,
  BrandBook,
}

enum UploadFeature {
  Name,
  Size,
  DateCreated,
  Extension,
  Boards,
  Tags,
  Favorites,
  Developed,
}

class UploadFile {
  file: File;
  progress: number = 0.0;
  fileId: string;
  size: number;
  lastModified: number;
  mimeType: string;
  thumbnail: string | undefined;

  constructor(file: File) {
    this.file = file;
    this.fileId = file.name;
    this.size = file.size;
    this.lastModified = file.lastModified;
    this.mimeType = file.type;
  }

  setProgress(value: number) {
    this.progress = value;
  }

  sortIndex(feature: UploadFeature) {
    if (feature == UploadFeature.Name) {
      return stringToNumericValue(this.getShortFileId());
    } else if (feature == UploadFeature.Boards) {
      return 0;
    } else if (feature == UploadFeature.DateCreated) {
      return this.lastModified;
    } else if (feature == UploadFeature.Developed) {
      return 0;
    } else if (feature == UploadFeature.Extension) {
      return stringToNumericValue(this.getExtension());
    } else if (feature == UploadFeature.Favorites) {
      return 0;
    } else if (feature == UploadFeature.Size) {
      return this.size;
    } else if (feature == UploadFeature.Tags) {
      return 0;
    } else {
      return 0;
    }
  }

  getExtension(): string {
    // const value = this.getShortFileId().split(".")
    const value = this.fileId.split(".");
    return removeNonAlphaNumFromEnd(value[value.length - 1]);
  }
  getShortFileId(window: number = 40): string {
    let response = this.fileId;
    if (response.length > window) {
      response = response.slice(0, window) + "...";
    }
    return response;
  }
}

export { HubView, UploadFile, UploadFeature, newUpload };
