import { Library, GenerateStyle, AspectRatio } from "hedwigai";

export interface AssetResolution {
  width: number;
  height: number;
}

export function getImageBase64(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(file);
  });
}

// Function to convert Blob to Data URL
export const blobToDataURL = (blob: Blob): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
};

export function fileToBlob(fileInput: HTMLInputElement): Promise<Blob> {
  return new Promise((resolve, reject) => {
    if (!fileInput || !fileInput.files || fileInput.files.length === 0) {
      reject(new Error("No file selected"));
      return;
    }

    const file = fileInput.files[0];
    const reader = new FileReader();

    reader.onload = () => {
      if (typeof reader.result === "string") {
        resolve(base64ToBlob(reader.result));
      } else {
        reject(new Error("Failed to read file"));
      }
    };

    reader.onerror = () => {
      reject(new Error("Failed to read file"));
    };

    reader.readAsDataURL(file);
  });
}

export async function convertImageToBase64(imageUrl: string): Promise<string> {
  try {
    const response = await fetch(imageUrl, {
      mode: "cors",
      // method: "GET",
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    if (!response.body) {
      throw new Error("Response body is null");
    }
    const base64 = await streamToBase64(response.body);
    // const imageBlob = await response.blob();
    // const urlParts: string = imageUrl.split("?")[0];
    // const extension: string = urlParts.split(".").pop()?.toLowerCase() || "";
    // const mimeTypes: { [key: string]: string } = {
    //   jpg: "image/jpeg",
    //   jpeg: "image/jpeg",
    //   png: "image/png",
    //   gif: "image/gif",
    //   webp: "image/webp",
    //   svg: "image/svg+xml",
    // };
    // const mimeType: string = mimeTypes[extension] || "application/octet-stream";
    // return new Promise<string>((resolve, reject) => {
    //   const reader = new FileReader();
    //   reader.onloadend = () => {
    //     const base64data: string = reader.result as string;
    //     const correctBase64data: string = base64data.replace(
    //       /^data:.*?;base64,/,
    //       `data:${mimeType};base64,`,
    //     );
    //     resolve(correctBase64data);
    //   };
    //   reader.onerror = () => {
    //     reject(new Error("Failed to read the image blob as base64"));
    //   };
    //   reader.readAsDataURL(imageBlob);
    // });
    return `data:image/png;base64,${base64}`;
  } catch (error) {
    console.error("Error converting image to base64:", error);
    return "";
  }
}

export const streamToBase64 = async (
  stream: ReadableStream,
): Promise<string> => {
  const reader = stream.getReader();
  const chunks: Uint8Array[] = [];
  let done = false;

  while (!done) {
    const { done: readerDone, value } = await reader.read();
    if (readerDone) {
      done = true;
    } else {
      chunks.push(value);
    }
  }

  const buffer = new Uint8Array(
    chunks.reduce((acc, chunk) => acc + chunk.length, 0),
  );
  let offset = 0;
  for (const chunk of chunks) {
    buffer.set(chunk, offset);
    offset += chunk.length;
  }

  let binary = "";
  buffer.forEach((byte) => {
    binary += String.fromCharCode(byte);
  });

  return window.btoa(binary);
};

export function base64ToBlob(base64: string): Promise<Blob> {
  return new Promise((resolve, reject) => {
    try {
      const matches = base64.match(/^data:(.*?);base64,/);
      if (!matches || matches.length !== 2) {
        throw new Error("Invalid base64 string");
      }
      const contentType = matches[1];

      const base64Data = base64.split(",")[1];
      const byteCharacters = atob(base64Data);
      const byteNumbers = new Array(byteCharacters.length);

      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: contentType });
      resolve(blob);
    } catch (error) {
      reject(error);
    }
  });
}

export function base64ToFile(base64: string, filename: string): File {
  const arr = base64.split(",");
  const mime = arr[0].match(/:(.*?);/)?.[1] || "";
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  console.log(mime, filename);

  return new File([new Blob([u8arr], { type: mime })], filename, {
    type: mime,
  });
}

export async function imageUrlToBlob(imageUrl: string): Promise<Blob> {
  const response = await fetch(imageUrl);
  if (!response.ok) {
    throw new Error(`Failed to fetch image: ${response.statusText}`);
  }
  const imageBlob = await response.blob();
  return imageBlob;
}

export function getImageDimensions(
  base64: string,
): Promise<{ width: number; height: number }> {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.onload = () => {
      resolve({ width: image.width, height: image.height });
    };
    image.onerror = (error) => {
      reject(new Error("Failed to load image"));
    };
    image.src = base64;
  });
}

export function convertImageToBlob(
  imageUrl: string,
  callback: (blob: Blob | null) => void,
): void {
  const img = new Image();
  img.crossOrigin = "anonymous";
  img.onload = function (): void {
    const canvas = document.createElement("canvas");
    canvas.width = img.width;
    canvas.height = img.height;
    const ctx = canvas.getContext("2d");
    if (!ctx) {
      callback(null);
      return;
    }
    ctx.drawImage(img, 0, 0);
    canvas.toBlob((blob: Blob | null): void => {
      callback(blob);
    }, "image/png");
  };
  img.onerror = function (): void {
    callback(null);
  };
  img.src = imageUrl;
}

export function downsampleBase64Image(
  base64Image: string,
  quality: number,
): Promise<string> {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.onload = () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d")!;

      canvas.width = image.width;
      canvas.height = image.height;

      ctx.drawImage(image, 0, 0, image.width, image.height);
      const newDataUrl = canvas.toDataURL("image/jpeg", 0.05);
      resolve(newDataUrl);

      image.src = "";
      image.onload = null;
      image.onerror = null;
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      canvas.width = 0;
      canvas.height = 0;
    };

    image.onerror = () => {
      console.log("Failed to load image for downsampling");
      image.src = "";
      image.onload = null;
      image.onerror = null;
    };
    if (base64Image === "") resolve("");
    image.src = base64Image;
  });
}

// CURRENT: Save captions on template generation
export const generateImage = async (
  library: Library,
  query: string,
  options: {
    remove?: boolean;
    searchInDam?: boolean;
    aspectRatio?: AspectRatio;
    style?: GenerateStyle;
  } = {},
) => {
  const {
    remove = true,
    searchInDam = false,
    aspectRatio = AspectRatio.ASPECT_RATIO_1_1,
    style = GenerateStyle.PHOTOGRAPHIC,
  } = options;

  const getImageFromLibrary = async (searchFn: Function) => {
    const res = await searchFn(query);
    return res.length ? { base64: res[0].image, srcId: res[0].key } : null;
  };

  if (searchInDam) {
    const result = remove
      ? await getImageFromLibrary(
          async (query: string) =>
            (await library.searchImageElement(query, 1)) as any[],
        )
      : await getImageFromLibrary(
          async (query: string) =>
            (await library.searchImage(query, 1)) as any[],
        );
    if (result) return result;
  }

  const genResponse = await library.generateImage(query, aspectRatio, style);
  // @ts-ignore
  const genImageUrl = genResponse[0].url;
  let genImageBase64 = await convertImageToBase64(genImageUrl);
  // @ts-ignore
  let genSrcId = genResponse[0].key.split("/").slice(1, 3).join("/");

  if (genImageBase64 && remove) {
    const genImgBlob = await base64ToBlob(genImageBase64);
    // @ts-ignore
    const genResRemoveBg = await library.removeBackgroundImage(
      genImgBlob,
      // @ts-ignore
      `${genResponse[0].key.split("/")[2]}_bg_removed.png`,
    );
    // @ts-ignore
    genImageBase64 = await convertImageToBase64(genResRemoveBg[0].url);
    // @ts-ignore
    genSrcId = genResRemoveBg[0].key.split("/").slice(1, 4).join("/");
  }

  return { base64: genImageBase64, srcId: genSrcId };
};

export const fetchImageAll = async (
  library: Library,
  fileIds: string[],
): Promise<string[]> => {
  const handleError = (error: unknown, context: string) => {
    if (error instanceof Error) {
      console.error(`Error ${context}: ${error.message}`);
    } else {
      console.error(`Unknown error ${context}: ${JSON.stringify(error)}`);
    }
  };

  try {
    const r = (await library.getFileUrl(fileIds)) as any;
    const res = r.response as any[];
    const base64Promises = res.map(async (item) => {
      try {
        return await convertImageToBase64(item.url);
      } catch (error) {
        handleError(error, "converting image to base64");
        return null;
      }
    });

    const base64s = await Promise.all(base64Promises);
    return base64s.filter((base64) => base64 !== null) as string[];
  } catch (error) {
    handleError(error, "fetching file URLs");
    return [];
  }
};

export const fetchImage = async (library: Library, fileId: string) => {
  const res = (await library.getFileUrl(fileId)) as any;
  const imageBase64 = await convertImageToBase64(res.response[0].url);
  return imageBase64;
};
