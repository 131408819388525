import React, { useEffect } from "react";
import { ThemeState, useThemeStore } from "../lib/state";

interface ThemeProps {}

const ThemeUI: React.FC<ThemeProps> = (props: ThemeProps) => {
  const themeToggle = useThemeStore((state: ThemeState) => state.toggle);

  return (
    <div className="toggle">
      <input className="toggle-input" type="checkbox" onClick={themeToggle} />
      <div className="toggle-bg"></div>
      <div className="toggle-switch">
        <div className="toggle-switch-figure"></div>
        <div className="toggle-switch-figureAlt"></div>
      </div>
    </div>
  );
};

export { ThemeUI };
