import { Library } from "hedwigai";
import React, { useEffect } from "react";
import { MissionView, usePrimaryMemoryStore } from "../../lib/state";
import ToolTip from "../tooltip";
import { AssetResolution } from "../../lib/image";
import Toast from "../Toast";
import { createRemix } from "../ImageStudio/utils/utils";
import { useNavigate } from "react-router-dom";
import { useOutsideClick } from "../../lib/utils";

interface AssetFullscreenViewerProps {
  library: Library;
}

interface FileAsset {
  url: string;
  segment: any;
}

const AssetFullscreenViewerUI: React.FC<AssetFullscreenViewerProps> = (
  props: AssetFullscreenViewerProps,
) => {
  const navigate = useNavigate();
  const missionRef = React.createRef<HTMLDivElement>();

  const focusAsset = usePrimaryMemoryStore((state) => state.focusAsset);
  const setFocusAsset = usePrimaryMemoryStore((state) => state.setFocusAsset);
  const [fileUrl, setFileUrl] = React.useState<{ [key: string]: string }>({});
  const primaryAssets = usePrimaryMemoryStore((state) => state.assets);
  const missionView = usePrimaryMemoryStore((state) => state.missionView);
  const setMissionView = usePrimaryMemoryStore((state) => state.setMissionView);
  const [openDeleteMenu, setOpenDeleteMenu] = React.useState<boolean>(false);
  const [openDownloadMenu, setOpenDownloadMenu] =
    React.useState<boolean>(false);
  const focusImageRef = React.createRef<HTMLImageElement>();
  const searchQuery = usePrimaryMemoryStore((state) => state.searchQuery);
  const [assetResolution, setAssetResolution] =
    React.useState<AssetResolution>();
  const handleImageLoad = () => {
    if (typeof focusImageRef == "undefined") return;
    const { naturalWidth, naturalHeight } = focusImageRef.current;
    setAssetResolution({
      width: naturalWidth,
      height: naturalHeight,
    });
  };
  const canvasRef = React.createRef<HTMLCanvasElement>();

  useEffect(() => {
    if (typeof focusAsset?.id == "undefined") return;
    if (!Object.keys(fileUrl).includes(focusAsset.id)) {
      props.library.getFileUrl(focusAsset?.id).then((data) => {
        const items = data["response"] as any[];
        items.forEach((item) => {
          fileUrl[focusAsset.id] = item["url"];
        });
        setFileUrl({ ...fileUrl });
      });
    }
  }, [focusAsset]);

  const downloadAtResolution = (resolution: number) => {
    const img = focusImageRef.current as HTMLImageElement;
    if (canvasRef.current == null) return;
    const canvas = canvasRef.current as HTMLCanvasElement;
    const ctx = canvas.getContext("2d") as CanvasRenderingContext2D;
    const newWidth =
      img.width > img.height
        ? resolution
        : (img.width / img.height) * resolution;
    const newHeight =
      img.width < img.height
        ? resolution
        : (img.height / img.width) * resolution;
    // Set canvas dimensions
    canvas.width = newWidth;
    canvas.height = newHeight;
    // Draw image on canvas
    ctx.drawImage(img, 0, 0, newWidth, newHeight);
    // Get data URL of the canvas
    canvas.toBlob((blob) => {
      if (blob) {
        const url = URL.createObjectURL(blob);
        if (typeof focusAsset == "undefined") return;
        const a = document.createElement("a");
        a.href = url;
        a.download = focusAsset.id;
        // Append the anchor element to the body
        document.body.appendChild(a);
        // Click the anchor element to trigger the download
        a.click();
        // Remove the anchor element from the body
        document.body.removeChild(a);
        // Revoke the URL to release resources
        URL.revokeObjectURL(url);
        Toast(`Downloading (${newWidth}px, ${newHeight}px)`);
      }
    }, "image/png");
  };

  useOutsideClick(missionRef, typeof focusAsset != "undefined", () => {
    setFocusAsset(undefined);
  });

  return (
    <>
      <div className={`translucent-bg ${focusAsset ? "" : "hidden"}`} />
      <canvas style={{ display: "none" }} ref={canvasRef}></canvas>
      <div
        onClick={() => {
          if (typeof focusAsset == "undefined") return;
          const currentId =
            primaryAssets.findIndex((a) => a.id == focusAsset.id) + 1;
          const nextElement = primaryAssets[currentId];
          if (currentId < primaryAssets.length) {
            setFocusAsset(nextElement);
          }
        }}
        className="next"
        style={{
          display: typeof focusAsset != "undefined" ? "block" : "none",
        }}
      ></div>
      <div
        onClick={() => {
          if (typeof focusAsset == "undefined") return;
          const currentId =
            primaryAssets.findIndex((a) => a.id == focusAsset.id) - 1;
          const prevElement = primaryAssets[currentId];
          if (currentId < primaryAssets.length) {
            setFocusAsset(prevElement);
          }
        }}
        className="prev"
        style={{
          display: typeof focusAsset != "undefined" ? "block" : "none",
        }}
      ></div>
      <div
        ref={missionRef}
        className="fullscreen-viewer"
        style={{
          display:
            typeof focusAsset != "undefined" &&
            Object.keys(fileUrl).includes(focusAsset.id)
              ? "flex"
              : "none",
        }}
        onClick={() => {}}
      >
        <div className="all-content">
          <div className="asset-title">
            <div className="text">
              <span data-category={"asset"}>Asset</span>
            </div>
            <div className="action">
              <ToolTip text="Open in studio" position="b-1">
                <div
                  className="icon favorite-icon"
                  onClick={async () => {
                    if (focusAsset) {
                      Toast("Creating remix...");
                      const workId = await createRemix(props.library, {
                        srcId: focusAsset.id,
                        base64: fileUrl[focusAsset.id],
                      });
                      if (workId.length) {
                        navigate(`/studio/${workId}`);
                      } else {
                        Toast(
                          "Unable to remix asset at the moment. Please try again.",
                        );
                      }
                    }
                  }}
                >
                  Remix
                </div>
              </ToolTip>

              <div className="download-action">
                <div
                  className="icon download-icon"
                  onClick={() =>
                    typeof missionView != "undefined"
                      ? setMissionView(undefined)
                      : setMissionView(MissionView.DOWNLOAD)
                  }
                >
                  Download Now
                </div>
                <div
                  className="more"
                  onClick={() => setOpenDownloadMenu(!openDownloadMenu)}
                >
                  <div
                    className="menu"
                    style={{ display: openDownloadMenu ? "flex" : "none" }}
                  >
                    <div
                      className="option"
                      onClick={() => downloadAtResolution(50)}
                    >
                      Small <span className="resolution">(50 x 50)</span>
                    </div>
                    <div
                      className="option"
                      onClick={() => downloadAtResolution(200)}
                    >
                      Medium <span className="resolution">(200 x 200)</span>
                    </div>
                    <div
                      className="option"
                      onClick={() => downloadAtResolution(800)}
                    >
                      Large <span className="resolution">(800 x 800)</span>
                    </div>
                    <div className="option original">
                      Original{" "}
                      <span className="resolution">
                        ({assetResolution?.width} x {assetResolution?.height})
                      </span>{" "}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`asset-visual`}>
            <img
              crossOrigin="anonymous"
              ref={focusImageRef}
              onLoad={handleImageLoad}
              src={
                typeof focusAsset != "undefined" &&
                Object.keys(fileUrl).includes(focusAsset.id)
                  ? fileUrl[focusAsset.id]
                  : ""
              }
            />
          </div>
          <div className="asset-action">
            {/* <ToolTip text="Share" position="b-1">
              <div
                className="icon share-icon"
                onClick={() =>
                  typeof missionView != "undefined"
                    ? setMissionView(undefined)
                    : setMissionView(MissionView.SHARE)
                }
              >
                Share
                <span className="material-symbols-outlined">
                  present_to_all
                </span>
              </div>
            </ToolTip> */}
            <ToolTip text="More" position="b-1">
              <div
                className="icon more-icon"
                onClick={() => {
                  setOpenDeleteMenu(!openDeleteMenu);
                }}
              >
                <span className="material-symbols-outlined">more_horiz</span>
                <div
                  className="menu"
                  style={{ display: openDeleteMenu ? "flex" : "none" }}
                >
                  <div
                    className="option svg-option"
                    onClick={() => {
                      setMissionView(MissionView.DELETE);
                      setOpenDeleteMenu(false);
                    }}
                  >
                    <span className="hover-icon material-symbols-outlined">
                      delete_forever
                    </span>
                    <span className="static-icon material-symbols-outlined">
                      delete
                    </span>
                    Delete
                  </div>
                </div>
              </div>
            </ToolTip>
          </div>
          <div className="asset-detail">
            {typeof focusAsset != "undefined" && (
              <p className="focus-id">{focusAsset.id}</p>
            )}
            <div className="asset-properties">
              <div className="property">
                <span className="material-symbols-outlined">
                  screenshot_tablet
                </span>
                <p className="resolution">
                  {assetResolution?.width} x {assetResolution?.height}
                </p>
              </div>
              <div className="property property-license">
                <span className="material-symbols-outlined">verified_user</span>
                Commercial License
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export { AssetFullscreenViewerUI };
