import { ImagePreview } from "../lib/hedwigai";
import React from "react";

interface AssetTileProps {
  item: ImagePreview;
  setFocusAsset: (item: ImagePreview) => void;
}

const AssetTileUI = (props: AssetTileProps) => {
  const item = props.item;

  return (
    <div key={item.id + Math.random()} className="tile">
      <div className="visual shine">
        <img
          src={`data:image/png;base64,${item.image.replace(/^b'|'$/g, "")}`}
        />
        <div
          className="button open-button"
          onClick={() => {
            props.setFocusAsset(item);
          }}
        >
          Open
        </div>
        <div className="button download-button">
          <span className="material-symbols-outlined">arrow_downward</span>
        </div>
        <div className="options">
          {/* <div className="button">
            <span className="material-symbols-outlined">favorite</span>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export { AssetTileUI };
