import React, { useEffect } from "react";
import { MissionView, usePrimaryMemoryStore } from "../../lib/state";
import { Library } from "hedwigai";
import { useOutsideClick } from "../../lib/utils";

interface SignUpProps {
  library: Library;
}

const SignUpMission: React.FC<SignUpProps> = (props: SignUpProps) => {
  const missionRef = React.createRef<HTMLDivElement>();
  const missionView = usePrimaryMemoryStore((state) => state.missionView);
  const setMissionView = usePrimaryMemoryStore((state) => state.setMissionView);
  const [inputEmail, setInputEmail] = React.useState<string>();
  const [inputPassword, setInputPassword] = React.useState<string>();
  const [triggerSignUp, setTriggerSignUp] = React.useState<boolean>();
  const [signing, setSigning] = React.useState<boolean>(false);
  const [verificationSent, setVerificationSent] =
    React.useState<boolean>(false);
  let signUpTrigger: NodeJS.Timeout;

  useEffect(() => {
    setVerificationSent(false);
  }, [inputEmail]);

  useEffect(() => {
    if (typeof inputEmail == "undefined") return;
    if (typeof inputPassword == "undefined") return;
    if (inputEmail.split("@").length == 2) {
      clearTimeout(signUpTrigger);
      setSigning(true);
      signUpTrigger = setTimeout(() => {
        props.library
          .waitlistMember(inputEmail, inputPassword)
          .then((success) => {
            setSigning(false);
            setVerificationSent(true);
          });
      }, 1000);
    }
  }, [triggerSignUp]);

  useOutsideClick(missionRef, missionView === MissionView.SIGN_UP, () => {
    setMissionView(undefined);
  });

  return (
    <div
      ref={missionRef}
      className="mission-view signup-mission"
      style={{
        display: missionView == MissionView.SIGN_UP ? "flex" : "none",
      }}
    >
      <div className="heading">
        <span
          className="icon close-window-icon"
          onClick={() => setMissionView(undefined)}
        ></span>
      </div>
      <div className="content">
        <div className="input-content">
          We are excited to have you !
          <label className="input-text-field">
            <input
              type="email"
              placeholder="&nbsp;"
              value={inputEmail}
              onChange={(e) => setInputEmail(e.target.value)}
            />
            <span className="placeholder">Enter Email</span>
            {/* <span className="error-message" aria-live="polite">The email is invalid</span> */}
          </label>
          <label className="input-text-field">
            <input
              type="password"
              placeholder="&nbsp;"
              value={inputPassword}
              style={{
                transform: "translate(0, 0)",
                transition: "translate 5.5s ease-in",
              }}
              onChange={(e) => setInputPassword(e.target.value)}
            />
            <span className="placeholder">Enter Password</span>
          </label>
        </div>

        <div className="output-action">
          <div
            onClick={() =>
              verificationSent ? null : setTriggerSignUp(!triggerSignUp)
            }
            className={`check-mail ${signing ? "check-sending" : verificationSent ? "" : "check-interact"}`}
          >
            {signing
              ? "Sending ✉️"
              : verificationSent
                ? "Check your email ✉️"
                : "Sign Up"}
          </div>
        </div>
      </div>
    </div>
  );
};

export { SignUpMission };
