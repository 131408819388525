async function getCurrentLocation(): Promise<string | undefined> {
  // Check if geolocation is supported by the browser
  if (!navigator.geolocation) {
    console.log("Geolocation is not supported by this browser.");
    return undefined;
  }

  try {
    // Use navigator.geolocation.getCurrentPosition to get user's current position
    const position = await new Promise<GeolocationPosition>(
      (resolve, reject) => {
        navigator.geolocation.getCurrentPosition(resolve, reject);
      },
    );

    const { latitude, longitude } = position.coords;

    // Use reverse geocoding to get address information from coordinates
    const response = await fetch(
      `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${latitude}&longitude=${longitude}&localityLanguage=en`,
    );
    const data = await response.json();

    // Extract city, state, and country from the reverse geocoding data
    const city = data.city || null;
    const state = data.principalSubdivision || null;
    const country = data.countryName || null;

    return `${city}, ${state}, ${country}`;
  } catch (error) {
    console.error("Error retrieving location:", error);
    return undefined;
  }
}

export { getCurrentLocation };
