import React from "react";

interface LoadingRainProps {
  message: string;
}

const LoadingMessage: React.FC<LoadingRainProps> = (
  props: LoadingRainProps,
) => {
  return (
    <div className="loading-message">
      <h1>
        <span>"Load</span>
        <span>ing..."</span>
        <br></br>
        <span>{props.message}</span>
      </h1>
    </div>
  );
};

export { LoadingMessage };
