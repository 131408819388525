import prettyBytes from "pretty-bytes";
import dateFormat from "dateformat";
import { KeyboardEvent } from "react";
import { isValidEmail } from "./text";
import { Library } from "hedwigai";
import Toast from "../components/Toast";

interface FileRoomData {
  id: string[];
  caption: string[];
  size: number[];
  status: string[];
  last_modified: string[];
  start_index: string[];
  end_index: string[];
  status_factor: string[];
  room_type: string;
}
interface FileRoomStat {
  size: string;
  count: number;
}

interface FileState {
  id: string;
  startIndex: string;
  endIndex: string;
  lastModified: string;
  status: string;
  statusFactor: string;
}

const getAccountId = (email: string) => {
  return email.split("@")[1].split(".")[0];
};

const getReadableDateTime = (timestamp: string) => {
  return `${dateFormat(timestamp, "mediumDate")} ${dateFormat(timestamp, "longTime")}`;
};
const getIndexStatus = (fileRoomData: FileRoomData[]): FileState[] => {
  const files: FileState[] = [];
  fileRoomData.forEach((value) => {
    value.id.map((idx, index) => {
      const name = idx.split("⟨")[1].slice(0, -1);

      files.push({
        id: name,
        lastModified: value.last_modified[index],
        startIndex: value.start_index[index],
        endIndex: value.end_index[index],
        status: value.status[index],
        statusFactor: parseFloat(value.status_factor[index]).toFixed(1),
      });
    });
  });
  return files;
};

const getSizeAndCount = (
  roomType: string,
  fileRoomData: FileRoomData[],
): FileRoomStat => {
  let size = 0;
  let count = 0;
  const data = fileRoomData
    .filter((item) => item.room_type === roomType)
    .at(0) as FileRoomData;
  if (typeof data === "undefined") {
    return { size: "0 MB", count: 0 } as FileRoomStat;
  }
  for (let i = 0; i < data.size.length; i++) {
    size += data.size[i];
    count++;
  }
  if (typeof size == "string") {
    size = parseInt(size);
  }
  return {
    size: prettyBytes(size),
    count,
  };
};

interface ExplorerObject {
  name: string;
  type: string;
  contains?: ExplorerObject[];
}

const getNestedFolder = (data: FileRoomData[]): ExplorerObject[] => {
  const nestedStructure: ExplorerObject[] = [];

  data
    .flatMap((item) => item.id)
    .forEach((pathx) => {
      let currentLevel = nestedStructure;
      let path = pathx.replace("..", "");
      const segments = path
        .split("/")
        .filter((segment) => segment.trim() !== "" || segment.trim() !== "..");
      segments.forEach((segment, index) => {
        const existingFolderIndex = currentLevel.findIndex(
          (item) => item.name === segment && item.type === "folder",
        );
        if (existingFolderIndex !== -1) {
          currentLevel = currentLevel[existingFolderIndex].contains;
        } else if (index === segments.length - 1) {
          currentLevel.push({
            name: segment,
            type: "file",
          });
        } else {
          const newFolder = {
            name: segment,
            type: "folder",
            contains: [],
          };
          currentLevel.push(newFolder);
          currentLevel = newFolder.contains;
        }
      });
    });

  return nestedStructure;
};

const getTimeDifference = (
  startISOString: string,
  endISOString: string,
): string => {
  const startTime = new Date(startISOString).getTime(); // Get time in milliseconds for start date
  const endTime = new Date(endISOString).getTime(); // Get time in milliseconds for end date

  // Calculate the difference in milliseconds
  const timeDifference = Math.abs(endTime - startTime) / 1000.0;

  return `${timeDifference}s`;
};

const interactWithSignButton = async (
  library: Library,
  email: string,
  password: string,
): Promise<boolean> => {
  if (!isValidEmail(email) || password.length < 6)
    return new Promise(() => false);
  return library.signIn(email, password).then((success: Boolean) => {
    if (success === true) {
      return true;
    } else {
      Toast("Credentials are ❌");
      return false;
    }
  });
};

const registerEnterKeyOnEmail = async (
  event: KeyboardEvent,
  library: Library,
  email: string,
  password: string,
): Promise<boolean> => {
  if (event.keyCode === 13) {
    return interactWithSignButton(library, email, password);
  } else {
    return new Promise(() => false);
  }
};

export {
  getAccountId,
  getSizeAndCount,
  getNestedFolder,
  getIndexStatus,
  getReadableDateTime,
  getTimeDifference,
  registerEnterKeyOnEmail,
  interactWithSignButton,
};

export type { FileRoomData, FileRoomStat, ExplorerObject, FileState };
