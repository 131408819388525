// Interface for HSL color values
interface HSL {
  h: number; // Hue (0-360)
  s: number; // Saturation (0-1)
  l: number; // Lightness (0-1)
}

interface CMYK {
  c: number;
  m: number;
  y: number;
  k: number;
}

interface Palette {
  color: string[];
}

function rgbToHsl(rgb: number[]): HSL {
  const rNorm = rgb[0] / 255;
  const gNorm = rgb[1] / 255;
  const bNorm = rgb[2] / 255;

  const max = Math.max(rNorm, gNorm, bNorm);
  const min = Math.min(rNorm, gNorm, bNorm);
  let h = 0;
  let s = 0;
  const l = (max + min) / 2;

  if (max !== min) {
    const d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);

    switch (max) {
      case rNorm:
        h = (gNorm - bNorm) / d + (gNorm < bNorm ? 6 : 0);
        break;
      case gNorm:
        h = (bNorm - rNorm) / d + 2;
        break;
      case bNorm:
        h = (rNorm - gNorm) / d + 4;
        break;
    }
    h /= 6;
  }

  return { h: h * 360, s, l };
}

/**
 * Converts HSL color values to RGB.
 * @param hsl The HSL color values as an object
 * @returns The corresponding RGB color values as an object
 */
function hslToRgb(hsl: HSL): number[] {
  const { h, s, l } = hsl;
  let r, g, b;

  const hue2rgb = (p: number, q: number, t: number) => {
    if (t < 0) t += 1;
    if (t > 1) t -= 1;
    if (t < 1 / 6) return p + (q - p) * 6 * t;
    if (t < 1 / 2) return q;
    if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
    return p;
  };

  if (s === 0) {
    r = g = b = l; // achromatic
  } else {
    const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    const p = 2 * l - q;
    r = hue2rgb(p, q, h / 360 + 1 / 3);
    g = hue2rgb(p, q, h / 360);
    b = hue2rgb(p, q, h / 360 - 1 / 3);
  }

  return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
}

function rgbToHex(rgb: number[]): string {
  const { r, g, b } = { r: rgb[0], g: rgb[1], b: rgb[2] };
  return "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
}

/**
 * Converts a hex color code to the CMYK color model.
 *
 * @param hex The hex color code (e.g., "#FF5733")
 * @returns An object containing the CMYK values (0-100)
 * @throws Error if the hex code is invalid
 */
function hexToCmyk(hex: string): CMYK {
  // Validate hex code
  if (!/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(hex)) {
    throw new Error("Invalid hex color code");
  }

  // Convert hex to RGB
  const rgb = hexToRgbArr(hex);
  const { r, g, b } = { r: rgb[0], g: rgb[1], b: rgb[2] };

  // Normalize RGB values (0-1)
  const rNorm = r / 255;
  const gNorm = g / 255;
  const bNorm = b / 255;

  // Calculate CMYK values
  const k = 1 - Math.max(rNorm, gNorm, bNorm);
  const c = ((1 - rNorm - k) / (1 - k)) * 100;
  const m = ((1 - gNorm - k) / (1 - k)) * 100;
  const y = ((1 - bNorm - k) / (1 - k)) * 100;

  // Handle division by zero (when k = 1)
  if (k === 1) {
    return { c: 0, m: 0, y: 0, k: 100 };
  }

  // Return CMYK values as percentages
  return {
    c: Math.round(c),
    m: Math.round(m),
    y: Math.round(y),
    k: Math.round(k * 100),
  };
}

/**
 * Shifts the brightness of a hex color by the specified amount.
 *
 * @param hex The hex color code (e.g., "#FF5733")
 * @param shift The brightness shift amount (-1 to 1)
 * @returns The modified hex color code
 * @throws Error if the hex code is invalid or the shift is outside the valid range
 */
function shiftBrightness(hex: string, shift: number): string {
  // Validate hex code format
  if (!/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(hex)) {
    throw new Error("Invalid hex color code");
  }

  // Validate shift range
  if (shift < -1 || shift > 1) {
    throw new Error("Shift amount must be between -1 and 1");
  }

  // Convert hex to RGB
  const rgb = hexToRgbArr(hex);

  // Convert RGB to HSL
  const hsl = rgbToHsl(rgb);

  // Shift the lightness (which controls brightness)
  hsl.l = Math.max(0, Math.min(1, hsl.l + shift)); // Clamp to 0-1 range

  // Convert HSL back to RGB
  const shiftedRgb = hslToRgb(hsl);

  // Convert RGB back to hex
  return rgbToHex(shiftedRgb);
}

/**
 * Shifts the saturation of a hex color by the specified amount.
 *
 * @param hex The hex color code (e.g., "#FF5733")
 * @param shift The saturation shift amount (-1 to 1)
 * @returns The modified hex color code
 * @throws Error if the hex code is invalid or the shift is outside the valid range
 */
function shiftSaturation(hex: string, shift: number): string {
  // Validate hex code format
  if (!/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(hex)) {
    throw new Error("Invalid hex color code");
  }

  // Validate shift range
  if (shift < -1 || shift > 1) {
    throw new Error("Shift amount must be between -1 and 1");
  }

  // Convert hex to RGB
  const rgb = hexToRgbArr(hex);

  // Convert RGB to HSL
  const hsl = rgbToHsl(rgb);

  // Shift the saturation
  hsl.s = Math.max(0, Math.min(1, hsl.s + shift)); // Clamp to 0-1 range

  // Convert HSL back to RGB
  const shiftedRgb = hslToRgb(hsl);

  // Convert RGB back to hex
  return rgbToHex(shiftedRgb);
}

/**
 * Shifts the hue of a hex color by the specified degrees.
 *
 * @param hex The hex color code (e.g., "#FF5733")
 * @param degrees The hue shift in degrees (-180 to 180)
 * @returns The modified hex color code
 * @throws Error if the hex code is invalid or the degrees are outside the valid range
 */
function shiftHue(hex: string, degrees: number): string {
  // Validate hex code format
  if (!/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(hex)) {
    throw new Error("Invalid hex color code");
  }

  // Validate degrees range
  if (degrees < -180 || degrees > 180) {
    throw new Error("Degrees must be between -180 and 180");
  }

  // Convert hex to RGB
  const rgb = hexToRgbArr(hex);

  // Convert RGB to HSL
  const hsl = rgbToHsl(rgb);

  // Shift the hue
  hsl.h = (hsl.h + degrees) % 360;
  if (hsl.h < 0) hsl.h += 360;

  // Convert HSL back to RGB
  const shiftedRgb = hslToRgb(hsl);

  // Convert RGB back to hex
  return rgbToHex(shiftedRgb);
}

function colorScienceA(rgb: number[]): number[] {
  // Check if the input array has three elements (for R, G, B values)
  if (rgb.length !== 3) {
    throw new Error("Input RGB array must have three elements");
  }

  // Define the transformation matrix A and vector y for Ax + y operation
  const A: number[][] = [
    [0.7, 0.2, 0.1],
    [0.2, 0.7, 0.2],
    [0.1, 0.1, 0.7],
  ];

  const y: number[] = [120, 120, 120]; // Complementary color vector

  const scale: number = 3;

  // Perform Ax + y operation
  const complementaryRgb: number[] = [
    (Math.round(A[0][0] * rgb[0] + A[0][1] * rgb[1] + A[0][2] * rgb[2] + y[0]) *
      scale) %
      255,
    (Math.round(A[1][0] * rgb[0] + A[1][1] * rgb[1] + A[1][2] * rgb[2] + y[1]) *
      scale) %
      255,
    (Math.round(A[2][0] * rgb[0] + A[2][1] * rgb[1] + A[2][2] * rgb[2] + y[2]) *
      scale) %
      255,
  ];

  return complementaryRgb;
}

const hexToRgb = (hex: string): string => {
  if (typeof hex !== "string") {
    return `0,0,0`;
  }
  let r = parseInt(hex.substring(1, 3), 16);
  let g = parseInt(hex.substring(3, 5), 16);
  let b = parseInt(hex.substring(5, 7), 16);
  return `${r},${g},${b}`;
};

const hexToRgbArr = (hex: string): number[] => {
  if (typeof hex !== "string") {
    return [0, 0, 0];
  }
  let r = parseInt(hex.substring(1, 3), 16);
  let g = parseInt(hex.substring(3, 5), 16);
  let b = parseInt(hex.substring(5, 7), 16);
  return [r, g, b];
};

const sampleColorData = (arr: string[]): string => {
  const found = arr[Math.floor(Math.random() * arr.length - 1e-5)];
  return found;
};

export {
  colorScienceA,
  hexToRgb,
  hexToRgbArr,
  rgbToHsl,
  sampleColorData,
  shiftHue,
  shiftSaturation,
  shiftBrightness,
  hexToCmyk,
};

export type { ColorData, Palette, CMYK };
