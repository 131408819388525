import React, { Suspense } from "react";
import { createRoot } from "react-dom/client";
import "../css/app.scss";
import "react-loading-skeleton/dist/skeleton.css";

import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { Theme, useAccountStore, useThemeStore } from "./lib/state";
import { MainUI } from "./Layouts/Main";
import useLibrary from "./lib/useLibrary";
import PrivateRoute from "./Layouts/PrivateRoute";
import { BoardConfigureUI } from "./components/Dam/board-configure";
import { AssetsViewerUI } from "./components/Dam/assets-viewer";
import { AssetFullscreenViewerUI } from "./components/Mission/asset-fullscreen";
import Brand from "./components/BrandBook/brand";
import { ROUTES } from "./lib/Route";
import { WebSocketProvider } from "./Layouts/SocketContext";
import posthog from "posthog-js";
import ShareUI from "./Layouts/Share";

if (!window.location.hostname.includes("localhost")) {
  posthog.init("phc_RQhZwJayy1jpvF7nkq5h8JMTjPrUFnDzERJh0ytYqkz", {
    api_host: "https://us.i.posthog.com",
    person_profiles: "identified_only", // or 'always' to create profiles for anonymous users as well
  });
}

const ImageUI = React.lazy(() => import("./Layouts/Image"));
const HubUI = React.lazy(() => import("./Layouts/Hub"));
const Workspace = React.lazy(
  () => import("./components/ImageStudio/Workspace"),
);

const App = () => {
  const library = useLibrary();
  const token = useAccountStore((state) => state.token);
  const theme = useThemeStore((state) => state.theme);

  return (
    <div
      className={`app ${
        theme === Theme.LIGHT ? "theme--light" : "theme--dark"
      }`}
    >
      <WebSocketProvider authToken={token}>
        <BrowserRouter>
          <Suspense fallback={<div>Loading...</div>}>
            <Routes>
              <Route path={ROUTES.MAIN}>
                <Route index element={<MainUI library={library} />} />
                <Route
                  path={ROUTES.ACTIVATE}
                  element={<MainUI library={library} />}
                />
                <Route
                  path={ROUTES.BILLING_CREDIT_CHECKOUT}
                  element={<MainUI library={library} />}
                />
                <Route
                  path={ROUTES.BILLING_SUBSCRIPTION_CHECKOUT}
                  element={<MainUI library={library} />}
                />
                <Route
                  path={ROUTES.INVITE}
                  element={<MainUI library={library} />}
                />
                <Route
                  path={ROUTES.MAGIC_LINK}
                  element={<MainUI library={library} />}
                />
                <Route
                  path={ROUTES.SHARE}
                  element={<ShareUI library={library} />}
                />
                <Route element={<PrivateRoute library={library} />}>
                  <Route
                    path={ROUTES.HUB}
                    element={<HubUI library={library} />}
                  >
                    <Route
                      path={ROUTES.HUB_ALL_ASSETS}
                      element={
                        <div className="board-ui">
                          <BoardConfigureUI library={library} />
                          <AssetsViewerUI library={library} />
                          <AssetFullscreenViewerUI library={library} />
                        </div>
                      }
                    />
                    <Route
                      path={ROUTES.HUB_BRAND_BOOK}
                      element={<Brand library={library} />}
                    />
                  </Route>
                  <Route
                    path={ROUTES.STUDIO}
                    element={<ImageUI library={library} />}
                  />
                  <Route
                    path={ROUTES.STUDIO_PLAYGROUND}
                    element={<Workspace library={library} />}
                  />
                </Route>
              </Route>
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
          </Suspense>
        </BrowserRouter>
      </WebSocketProvider>
    </div>
  );
};

const root = createRoot(document.getElementById("root")!);
root.render(<App />);
