import React, { useEffect } from "react";
import Toast from "../Toast";

function measureLetterHeight(
  ctx: CanvasRenderingContext2D,
  letter: string,
): number {
  // Measure the height of the letter
  const metrics = ctx.measureText(letter);
  return metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent;
}

interface LockupGuidelineProps {
  logoUrl: string;
  lockupSpace: string;
  clearSpace: string;
  fillColor: string;
  textColor: string;
  fontFamily: string;
  lockupValue: string;
  canvasWidthInRem: number;
  canvasHeightInRem: number;
  logoSizeInRem: number;
  fontSizeInRem: number;
}

const LockupGuideline: React.FC<LockupGuidelineProps> = (
  props: LockupGuidelineProps,
) => {
  const canvasRef = React.useRef<HTMLCanvasElement>(null);
  const [mouseHover, setMouseHover] = React.useState(false);
  const [imageElement, setImageElement] = React.useState<HTMLImageElement>();
  useEffect(() => {
    // handle mouse enter
    const handleMouseEnter = () => {
      setMouseHover(true);
    };
    // handle mouse leave
    const handleMouseLeave = () => {
      setMouseHover(false);
    };
    // add event listeners
    const canvas = canvasRef.current;
    if (canvas) {
      canvas.addEventListener("mouseenter", handleMouseEnter);
      canvas.addEventListener("mouseleave", handleMouseLeave);
    }
    // remove event listeners
    return () => {
      if (canvas) {
        canvas.removeEventListener("mouseenter", handleMouseEnter);
        canvas.removeEventListener("mouseleave", handleMouseLeave);
      }
    };
  }, []);

  const draw = (
    ctx: CanvasRenderingContext2D,
    canvas: HTMLCanvasElement,
    remSize: number,
    img: HTMLImageElement,
  ) => {
    // Clear the canvas
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    // Background
    ctx.fillStyle = props.fillColor;
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    // Typeface
    const fontSize = remSize * props.fontSizeInRem;
    ctx.font = `${fontSize}px '${props.fontFamily}'`; // Adjust font size and family as needed

    // Calculate image and text dimensions and positions
    const imageWidth = remSize * props.logoSizeInRem;
    const imageHeight = remSize * props.logoSizeInRem;
    const textWidth = ctx.measureText(props.lockupValue).width;
    const lockupSpace = measureLetterHeight(ctx, props.lockupSpace);
    const clearSpace = measureLetterHeight(ctx, props.clearSpace);
    const combinedWidth = imageWidth + lockupSpace + textWidth; // 10px gap between image and text

    const imageX = canvas.width / 2.0 - combinedWidth / 2;
    const imageY = (canvas.height - imageHeight) / 2;

    // Draw image
    ctx.drawImage(img, imageX, imageY, imageWidth, imageHeight);

    // Draw text
    ctx.fillStyle = props.textColor;
    const textX = imageX + imageWidth + lockupSpace;
    const textY = (canvas.height + fontSize * 0.7) / 2;
    // Calculate font height
    ctx.fillText(props.lockupValue, textX, textY);

    if (!mouseHover) return;

    // Draw a horizontal letter between the image and text
    ctx.save();
    ctx.translate(canvas.width / 2.0, canvas.height / 2);
    ctx.rotate(-Math.PI / 2);
    ctx.textAlign = "center";
    ctx.textBaseline = "middle";
    ctx.globalAlpha = 0.5;
    ctx.fillText(
      props.lockupSpace[0],
      0,
      -(combinedWidth / 2.0) + imageWidth + (lockupSpace * 1.4) / 2.0,
    );
    ctx.restore();

    // Draw a horizontal letter on the left of the image / lockup
    ctx.save();
    ctx.translate(canvas.width / 2.0, canvas.height / 2);
    ctx.rotate(-Math.PI / 2);
    ctx.textAlign = "center";
    ctx.textBaseline = "middle";
    ctx.globalAlpha = 0.5;
    ctx.fillText(
      props.clearSpace[0],
      0,
      -(combinedWidth / 2.0) - (clearSpace * 0.7) / 2.0,
    );
    ctx.restore();

    // Draw a horizontal letter on the right side of the lockup
    ctx.save();
    ctx.translate(canvas.width / 2.0, canvas.height / 2.0);
    ctx.rotate(Math.PI / 2);
    ctx.textAlign = "center";
    ctx.textBaseline = "middle";
    ctx.globalAlpha = 0.5;
    ctx.fillText(
      props.clearSpace[0],
      0,
      -(combinedWidth / 2.0 + (clearSpace * 0.7) / 2.0),
    );
    ctx.restore();

    // Draw a vertical letter above the lockup right in the middle
    ctx.save();
    ctx.globalAlpha = 0.5;
    ctx.textAlign = "center";
    ctx.textBaseline = "middle";
    ctx.fillText(
      props.lockupValue[0],
      canvas.width / 2,
      canvas.height / 2.0 - (imageHeight * 1.2) / 2.0,
    );
    ctx.restore();

    // Draw a vertical letter below the lockup right in the middle
    ctx.save();
    ctx.globalAlpha = 0.5;
    ctx.textAlign = "center";
    ctx.textBaseline = "middle";
    ctx.translate(canvas.width / 2, canvas.height / 2.0);
    ctx.rotate(-Math.PI);
    ctx.fillText(props.lockupValue[0], 0, -((imageHeight * 1.2) / 2.0));
    ctx.restore();

    // Guidelines
    ctx.save();
    ctx.strokeStyle = "dashed";
    ctx.strokeStyle = props.textColor;
    ctx.lineWidth = 1;
    ctx.setLineDash([5, 5]);
    // Horizontal Axis
    ctx.beginPath();
    ctx.moveTo(0, canvas.height / 2);
    ctx.lineTo(canvas.width, canvas.height / 2);
    ctx.stroke();
    // Vertical Axis
    ctx.beginPath();
    ctx.moveTo(canvas.width / 2, 0);
    ctx.lineTo(canvas.width / 2, canvas.height);
    ctx.stroke();
    // Lockup Axis
    // Left:Left
    ctx.beginPath();
    ctx.moveTo(imageX + imageWidth, 0);
    ctx.lineTo(imageX + imageWidth, canvas.height);
    ctx.stroke();
    // Left:Right
    ctx.beginPath();
    ctx.moveTo(imageX + imageWidth + lockupSpace, 0);
    ctx.lineTo(imageX + imageWidth + lockupSpace, canvas.height);
    ctx.stroke();
    // ClearSpace Axis
    // Left:Left
    ctx.beginPath();
    ctx.moveTo(imageX - clearSpace, 0);
    ctx.lineTo(imageX - clearSpace, canvas.height);
    ctx.stroke();
    // Left:Right
    ctx.beginPath();
    ctx.moveTo(imageX, 0);
    ctx.lineTo(imageX, canvas.height);
    ctx.stroke();
    // Top:Top
    ctx.beginPath();
    ctx.moveTo(0, canvas.height / 2.0 - (lockupSpace / 2.0 + lockupSpace));
    ctx.lineTo(
      canvas.width,
      canvas.height / 2.0 - (lockupSpace / 2.0 + lockupSpace),
    );
    ctx.stroke();
    // Top:Bottom
    ctx.beginPath();
    ctx.moveTo(0, canvas.height / 2.0 - (imageHeight / 2.0 + lockupSpace));
    ctx.lineTo(
      canvas.width,
      canvas.height / 2.0 - (imageHeight / 2.0 + lockupSpace),
    );
    ctx.stroke();
    // Right:Left
    ctx.beginPath();
    ctx.moveTo(imageX + combinedWidth, 0);
    ctx.lineTo(imageX + combinedWidth, canvas.height);
    ctx.stroke();
    // Right:Right
    ctx.beginPath();
    ctx.moveTo(imageX + combinedWidth + lockupSpace, 0);
    ctx.lineTo(imageX + combinedWidth + lockupSpace, canvas.height);
    ctx.stroke();
    // Bottom:Top
    ctx.beginPath();
    ctx.moveTo(0, canvas.height / 2.0 + imageHeight / 2.0);
    ctx.lineTo(canvas.width, canvas.height / 2.0 + imageHeight / 2.0);
    ctx.stroke();
    // Bottom:Bottom
    ctx.beginPath();
    ctx.moveTo(0, canvas.height / 2.0 + (imageHeight / 2.0 + lockupSpace));
    ctx.lineTo(
      canvas.width,
      canvas.height / 2.0 + (imageHeight / 2.0 + lockupSpace),
    );
    ctx.stroke();
    // End of Guidline
    ctx.restore();
  };

  const exportCanvasAsPNG = () => {
    if (canvasRef.current === null) return;
    const canvas = canvasRef.current;
    canvas.toBlob((blob) => {
      if (blob) {
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "logo.png";
        // Append the anchor element to the body
        document.body.appendChild(a);
        // Click the anchor element to trigger the download
        a.click();
        // Remove the anchor element from the body
        document.body.removeChild(a);
        // Revoke the URL to release resources
        URL.revokeObjectURL(url);
        Toast(`Downloading (${canvas.width}px, ${canvas.height}px)`);
      }
    }, "image/png");
  };

  useEffect(() => {
    if (typeof imageElement === "undefined") return;
    if (typeof props.lockupValue === "undefined") return;
    if (typeof props.clearSpace === "undefined") return;
    if (typeof props.lockupSpace === "undefined") return;
    if (
      props.lockupSpace.length == 0 ||
      props.clearSpace.length == 0 ||
      props.lockupValue.length == 0
    )
      return;
    draw(
      canvasRef.current?.getContext("2d") as CanvasRenderingContext2D,
      canvasRef.current as HTMLCanvasElement,
      parseFloat(getComputedStyle(document.documentElement).fontSize),
      imageElement as HTMLImageElement,
    );
  }, [
    mouseHover,
    imageElement,
    props.lockupSpace,
    props.clearSpace,
    props.lockupValue,
  ]);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (canvas) {
      const ctx = canvas.getContext("2d");
      if (ctx) {
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        const img = new Image();
        img.src = props.logoUrl;
        img.onload = () => {
          setImageElement(img);
        };
      }
    }
  }, [props.logoUrl]);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (canvas) {
      const ctx = canvas.getContext("2d");
      // Set the canvas width and height to 100%
      const remSize = parseFloat(
        getComputedStyle(document.documentElement).fontSize,
      ); // Get size of 1rem in pixels
      const divWidth = props.canvasWidthInRem * remSize; // 20rem
      const divHeight = props.canvasHeightInRem * remSize; // 10rem
      // Set the canvas element size
      canvas.width = divWidth * 2;
      canvas.height = divHeight * 2;
      canvas.style.width = `${divWidth}px`;
      canvas.style.height = `${divHeight}px`;

      if (ctx) {
        ctx.imageSmoothingEnabled = true;
        ctx.imageSmoothingQuality = "high";
        ctx.globalCompositeOperation = "destination-out";
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        // Reset the globalCompositeOperation to default
        ctx.globalCompositeOperation = "source-over";
        const img = new Image();
        img.src = props.logoUrl;
        img.onload = () => {
          setImageElement(img);
        };
      }
    }
  }, [canvasRef]);
  return (
    <div className="guideline lockup">
      <canvas ref={canvasRef} />

      <span className="material-symbols-outlined" onClick={exportCanvasAsPNG}>
        {" "}
        download{" "}
      </span>
    </div>
  );
};

interface LogoGuidelineProps {
  logoUrl: string;
  lockupSpace: string;
  clearSpace: string;
  fillColor: string;
  textColor: string;
  lockupValue: string;
  canvasWidthInRem: number;
  canvasHeightInRem: number;
  logoSizeInRem: number;
  fontSizeInRem: number;
}

const LogoGuideline: React.FC<LogoGuidelineProps> = (
  props: LockupGuidelineProps,
) => {
  const canvasRef = React.useRef<HTMLCanvasElement>(null);
  const [mouseHover, setMouseHover] = React.useState(false);
  const [imageElement, setImageElement] = React.useState<HTMLImageElement>();
  useEffect(() => {
    // handle mouse enter
    const handleMouseEnter = () => {
      setMouseHover(true);
    };
    // handle mouse leave
    const handleMouseLeave = () => {
      setMouseHover(false);
    };
    // add event listeners
    const canvas = canvasRef.current;
    if (canvas) {
      canvas.addEventListener("mouseenter", handleMouseEnter);
      canvas.addEventListener("mouseleave", handleMouseLeave);
    }
    // remove event listeners
    return () => {
      if (canvas) {
        canvas.removeEventListener("mouseenter", handleMouseEnter);
        canvas.removeEventListener("mouseleave", handleMouseLeave);
      }
    };
  }, []);

  const draw = (
    ctx: CanvasRenderingContext2D,
    canvas: HTMLCanvasElement,
    remSize: number,
    img: HTMLImageElement,
  ) => {
    // Clear the canvas
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    // Background
    ctx.fillStyle = props.fillColor;
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    // Typeface
    const fontSize = remSize * props.fontSizeInRem;
    ctx.font = `${fontSize}px 'Luckiest Guy'`; // Adjust font size and family as needed

    // Calculate image and text dimensions and positions
    const imageWidth = remSize * props.logoSizeInRem;
    const imageHeight = remSize * props.logoSizeInRem;
    const clearSpace = measureLetterHeight(ctx, props.clearSpace);

    const imageX = canvas.width / 2.0 - imageWidth / 2;
    const imageY = (canvas.height - imageHeight) / 2;

    // Draw image
    ctx.drawImage(img, imageX, imageY, imageWidth, imageHeight);

    if (!mouseHover) return;

    // Draw a horizontal letter on the left of the image
    ctx.fillStyle = props.textColor;
    ctx.save();
    ctx.translate(canvas.width / 2.0, canvas.height / 2);
    ctx.rotate(-Math.PI / 2);
    ctx.textAlign = "center";
    ctx.textBaseline = "middle";
    ctx.globalAlpha = 0.5;
    ctx.fillText(
      props.clearSpace[0],
      0,
      -(imageWidth / 2.0) - (clearSpace * 0.7) / 2.0,
    );
    ctx.restore();

    // Draw a horizontal letter on the right side of the lockup
    ctx.save();
    ctx.translate(canvas.width / 2.0, canvas.height / 2.0);
    ctx.rotate(Math.PI / 2);
    ctx.textAlign = "center";
    ctx.textBaseline = "middle";
    ctx.globalAlpha = 0.5;
    ctx.fillText(
      props.clearSpace[0],
      0,
      -(imageWidth / 2.0 + (clearSpace * 0.7) / 2.0),
    );
    ctx.restore();

    // Draw a vertical letter above the lockup right in the middle
    ctx.save();
    ctx.globalAlpha = 0.5;
    ctx.textAlign = "center";
    ctx.textBaseline = "middle";
    ctx.fillText(
      props.lockupValue[0],
      canvas.width / 2.0,
      canvas.height / 2.0 - imageHeight / 2.0 - (clearSpace * 0.7) / 2.0,
    );
    ctx.restore();

    // Draw a vertical letter below the lockup right in the middle
    ctx.save();
    ctx.globalAlpha = 0.5;
    ctx.textAlign = "center";
    ctx.textBaseline = "middle";
    ctx.translate(canvas.width / 2, canvas.height / 2.0);
    ctx.rotate(-Math.PI);
    ctx.fillText(
      props.lockupValue[0],
      0,
      -(imageHeight / 2.0) - (clearSpace * 0.7) / 2.0,
    );
    ctx.restore();

    // Guidelines
    ctx.save();
    ctx.strokeStyle = "dashed";
    ctx.strokeStyle = props.textColor;
    ctx.lineWidth = 1;
    ctx.setLineDash([5, 5]);
    // Horizontal Axis
    ctx.beginPath();
    ctx.moveTo(0, canvas.height / 2);
    ctx.lineTo(canvas.width, canvas.height / 2);
    ctx.stroke();
    // Vertical Axis
    ctx.beginPath();
    ctx.moveTo(canvas.width / 2, 0);
    ctx.lineTo(canvas.width / 2, canvas.height);
    ctx.stroke();
    // ClearSpace Axis
    // Left:Left
    ctx.beginPath();
    ctx.moveTo(imageX - clearSpace, 0);
    ctx.lineTo(imageX - clearSpace, canvas.height);
    ctx.stroke();
    // Left:Right
    ctx.beginPath();
    ctx.moveTo(imageX + imageWidth + clearSpace, 0);
    ctx.lineTo(imageX + imageWidth + clearSpace, canvas.height);
    ctx.stroke();
    // Top:Top
    ctx.beginPath();
    ctx.moveTo(0, canvas.height / 2.0 - imageHeight / 2.0 - clearSpace);
    ctx.lineTo(
      canvas.width,
      canvas.height / 2.0 - imageHeight / 2.0 - clearSpace,
    );
    ctx.stroke();
    // Top:Bottom
    ctx.beginPath();
    ctx.moveTo(0, canvas.height / 2.0 + imageHeight / 2.0 + clearSpace);
    ctx.lineTo(
      canvas.width,
      canvas.height / 2.0 + imageHeight / 2.0 + clearSpace,
    );
    ctx.stroke();
    // End of Guidline
    ctx.restore();
  };

  const exportCanvasAsPNG = () => {
    if (canvasRef.current === null) return;
    const canvas = canvasRef.current;
    canvas.toBlob((blob) => {
      if (blob) {
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "logo.png";
        // Append the anchor element to the body
        document.body.appendChild(a);
        // Click the anchor element to trigger the download
        a.click();
        // Remove the anchor element from the body
        document.body.removeChild(a);
        // Revoke the URL to release resources
        URL.revokeObjectURL(url);
        Toast(`Downloading (${canvas.width}px, ${canvas.height}px)`);
      }
    }, "image/png");
  };

  useEffect(() => {
    if (typeof imageElement === "undefined") return;
    draw(
      canvasRef.current?.getContext("2d") as CanvasRenderingContext2D,
      canvasRef.current as HTMLCanvasElement,
      parseFloat(getComputedStyle(document.documentElement).fontSize),
      imageElement as HTMLImageElement,
    );
  }, [
    mouseHover,
    imageElement,
    props.lockupSpace,
    props.lockupValue,
    props.clearSpace,
  ]);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (canvas) {
      const ctx = canvas.getContext("2d");
      // Set the canvas width and height to 100%
      const remSize = parseFloat(
        getComputedStyle(document.documentElement).fontSize,
      ); // Get size of 1rem in pixels
      const divWidth = props.canvasWidthInRem * remSize; // 20rem
      const divHeight = props.canvasHeightInRem * remSize; // 10rem
      // Set the canvas element size
      canvas.width = divWidth * 2;
      canvas.height = divHeight * 2;
      canvas.style.width = `${divWidth}px`;
      canvas.style.height = `${divHeight}px`;

      if (ctx) {
        ctx.imageSmoothingEnabled = true;
        ctx.imageSmoothingQuality = "high";
        ctx.globalCompositeOperation = "destination-out";
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        // Reset the globalCompositeOperation to default
        ctx.globalCompositeOperation = "source-over";
        const img = new Image();
        img.src = props.logoUrl;
        img.onload = () => {
          setImageElement(img);
        };
      }
    }
  }, [canvasRef]);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (canvas) {
      const ctx = canvas.getContext("2d");
      if (ctx) {
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        const img = new Image();
        img.src = props.logoUrl;
        img.onload = () => {
          setImageElement(img);
        };
      }
    }
  }, [props.logoUrl]);

  return (
    <div className="guideline lockup">
      <canvas ref={canvasRef} />
      <span className="material-symbols-outlined" onClick={exportCanvasAsPNG}>
        {" "}
        download{" "}
      </span>
    </div>
  );
};

interface TitleGuidelineProps {
  logoUrl: string;
  lockupSpace: string;
  clearSpace: string;
  fillColor: string;
  textColor: string;
  lockupValue: string;
  fontFamily: string;
  canvasWidthInRem: number;
  canvasHeightInRem: number;
  logoSizeInRem: number;
  fontSizeInRem: number;
}

const TitleGuideline: React.FC<TitleGuidelineProps> = (
  props: LockupGuidelineProps,
) => {
  const canvasRef = React.useRef<HTMLCanvasElement>(null);
  const [mouseHover, setMouseHover] = React.useState(false);
  const [imageElement, setImageElement] = React.useState<HTMLImageElement>();
  useEffect(() => {
    // handle mouse enter
    const handleMouseEnter = () => {
      setMouseHover(true);
    };
    // handle mouse leave
    const handleMouseLeave = () => {
      setMouseHover(false);
    };
    // add event listeners
    const canvas = canvasRef.current;
    if (canvas) {
      canvas.addEventListener("mouseenter", handleMouseEnter);
      canvas.addEventListener("mouseleave", handleMouseLeave);
    }
    // remove event listeners
    return () => {
      if (canvas) {
        canvas.removeEventListener("mouseenter", handleMouseEnter);
        canvas.removeEventListener("mouseleave", handleMouseLeave);
      }
    };
  }, []);

  const draw = (
    ctx: CanvasRenderingContext2D,
    canvas: HTMLCanvasElement,
    remSize: number,
    img: HTMLImageElement,
  ) => {
    // Clear the canvas
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    // Background
    ctx.fillStyle = props.fillColor;
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    // Typeface
    const fontSize = remSize * props.fontSizeInRem;
    ctx.font = `${fontSize}px '${props.fontFamily}'`; // Adjust font size and family as needed
    const textWidth = ctx.measureText(props.lockupValue).width;
    const clearSpace = measureLetterHeight(ctx, props.clearSpace);

    // Draw text
    ctx.fillStyle = props.textColor;
    const textX = canvas.width / 2.0;
    const textY = canvas.height / 2.0;
    ctx.textAlign = "center";
    ctx.textBaseline = "middle";
    // Calculate font height
    ctx.fillText(props.lockupValue, textX, textY + 0.2 * fontSize);

    if (!mouseHover) return;

    // Draw a horizontal letter on the left of the tile
    ctx.save();
    ctx.translate(canvas.width / 2.0, canvas.height / 2);
    ctx.rotate(-Math.PI / 2);
    ctx.textAlign = "center";
    ctx.textBaseline = "middle";
    ctx.globalAlpha = 0.5;
    ctx.fillText(
      props.clearSpace[0],
      0,
      -(textWidth / 2.0) - (clearSpace * 0.7) / 2.0,
    );
    ctx.restore();

    // Draw a horizontal letter on the right side of the title
    ctx.save();
    ctx.translate(canvas.width / 2.0, canvas.height / 2.0);
    ctx.rotate(Math.PI / 2);
    ctx.textAlign = "center";
    ctx.textBaseline = "middle";
    ctx.globalAlpha = 0.5;
    ctx.fillText(
      props.clearSpace[0],
      0,
      -(textWidth / 2.0 + (clearSpace * 0.7) / 2.0),
    );
    ctx.restore();

    // Draw a vertical letter above the title right in the middle
    ctx.save();
    ctx.translate(canvas.width / 2.0, canvas.height / 2.0);
    ctx.textAlign = "center";
    ctx.textBaseline = "middle";
    ctx.globalAlpha = 0.5;
    ctx.fillText(props.lockupValue[0], 0, -fontSize / 2.0);
    ctx.restore();

    // Draw a vertical letter below the lockup right in the middle
    ctx.save();
    ctx.globalAlpha = 0.5;
    ctx.textAlign = "center";
    ctx.textBaseline = "middle";
    ctx.translate(canvas.width / 2, canvas.height / 2.0);
    ctx.rotate(-Math.PI);
    ctx.fillText(props.lockupValue[0], 0, (-fontSize * 1.2) / 2.0);
    ctx.restore();

    // Guidelines
    ctx.save();
    ctx.strokeStyle = "dashed";
    ctx.strokeStyle = props.textColor;
    ctx.lineWidth = 1;
    ctx.setLineDash([5, 5]);
    // Horizontal Axis
    ctx.beginPath();
    ctx.moveTo(0, canvas.height / 2);
    ctx.lineTo(canvas.width, canvas.height / 2);
    ctx.stroke();
    // Vertical Axis
    ctx.beginPath();
    ctx.moveTo(canvas.width / 2, 0);
    ctx.lineTo(canvas.width / 2, canvas.height);
    ctx.stroke();
    // ClearSpace Axis
    // Left:Left
    ctx.beginPath();
    ctx.moveTo(canvas.width / 2.0 - textWidth / 2.0 - clearSpace, 0);
    ctx.lineTo(
      canvas.width / 2.0 - textWidth / 2.0 - clearSpace,
      canvas.height,
    );
    ctx.stroke();
    // Left:Right
    ctx.beginPath();
    ctx.moveTo(canvas.width / 2.0 + textWidth / 2.0 + clearSpace, 0);
    ctx.lineTo(
      canvas.width / 2.0 + textWidth / 2.0 + clearSpace,
      canvas.height,
    );
    ctx.stroke();
    // Top:Top
    ctx.beginPath();
    ctx.moveTo(0, canvas.height / 2.0 - (fontSize / 2.0 + clearSpace));
    ctx.lineTo(
      canvas.width,
      canvas.height / 2.0 - (fontSize / 2.0 + clearSpace),
    );
    ctx.stroke();
    // Right:Right
    ctx.beginPath();
    ctx.moveTo(textX + textWidth + clearSpace, 0);
    ctx.lineTo(textX + textWidth + clearSpace, canvas.height);
    ctx.stroke();
    // Bottom:Bottom
    ctx.beginPath();
    ctx.moveTo(0, canvas.height / 2.0 + (fontSize / 2.0 + clearSpace));
    ctx.lineTo(
      canvas.width,
      canvas.height / 2.0 + (fontSize / 2.0 + clearSpace),
    );
    ctx.stroke();
    // End of Guidline
    ctx.restore();
  };

  const exportCanvasAsPNG = () => {
    if (canvasRef.current === null) return;
    const canvas = canvasRef.current;
    canvas.toBlob((blob) => {
      if (blob) {
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "logo.png";
        // Append the anchor element to the body
        document.body.appendChild(a);
        // Click the anchor element to trigger the download
        a.click();
        // Remove the anchor element from the body
        document.body.removeChild(a);
        // Revoke the URL to release resources
        URL.revokeObjectURL(url);
        Toast(`Downloading (${canvas.width}px, ${canvas.height}px)`);
      }
    }, "image/png");
  };

  useEffect(() => {
    if (typeof imageElement === "undefined") return;
    if (
      props.lockupSpace.length == 0 ||
      props.clearSpace.length == 0 ||
      props.lockupValue.length == 0
    )
      return;
    draw(
      canvasRef.current?.getContext("2d") as CanvasRenderingContext2D,
      canvasRef.current as HTMLCanvasElement,
      parseFloat(getComputedStyle(document.documentElement).fontSize),
      imageElement as HTMLImageElement,
    );
  }, [
    mouseHover,
    imageElement,
    props.lockupSpace,
    props.clearSpace,
    props.lockupValue,
  ]);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (canvas) {
      const ctx = canvas.getContext("2d");
      // Set the canvas width and height to 100%
      const remSize = parseFloat(
        getComputedStyle(document.documentElement).fontSize,
      ); // Get size of 1rem in pixels
      const divWidth = props.canvasWidthInRem * remSize; // 20rem
      const divHeight = props.canvasHeightInRem * remSize; // 10rem
      // Set the canvas element size
      canvas.width = divWidth * 2;
      canvas.height = divHeight * 2;
      canvas.style.width = `${divWidth}px`;
      canvas.style.height = `${divHeight}px`;

      if (ctx) {
        ctx.imageSmoothingEnabled = true;
        ctx.imageSmoothingQuality = "high";
        ctx.globalCompositeOperation = "destination-out";
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        // Reset the globalCompositeOperation to default
        ctx.globalCompositeOperation = "source-over";
        const img = new Image();
        img.src = props.logoUrl;
        img.onload = () => {
          setImageElement(img);
        };
      }
    }
  }, [canvasRef]);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (canvas) {
      const ctx = canvas.getContext("2d");
      if (ctx) {
        ctx.fillRect(0, 0, canvas.width, canvas.height);
        const img = new Image();
        img.src = props.logoUrl;
        img.onload = () => {
          setImageElement(img);
        };
      }
    }
  }, [props.logoUrl]);

  return (
    <div className="guideline lockup">
      <canvas ref={canvasRef} />

      <span className="material-symbols-outlined" onClick={exportCanvasAsPNG}>
        download{" "}
      </span>
    </div>
  );
};

export { LockupGuideline, TitleGuideline, LogoGuideline };
