import React, { useEffect } from "react";
import { interactWithSignButton } from "../../lib/account";
import { isValidEmail } from "../../lib/text";
import {
  Integration,
  Invoice,
  Member,
  MissionView,
  useAccountStore,
  usePrimaryMemoryStore,
} from "../../lib/state";
import { Library } from "hedwigai";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../lib/Route";
import { getCurrentLocation } from "../../lib/location";

interface MemberSignProps {
  library: Library;
}

const MemberSignUI: React.FC<MemberSignProps> = (props: MemberSignProps) => {
  const email = useAccountStore((state) => state.email);
  const token = useAccountStore((state) => state.token);
  const [signing, setSigning] = React.useState<boolean>(false);
  const sign = useAccountStore((state) => state.sign);
  const setMissionView = usePrimaryMemoryStore((state) => state.setMissionView);
  const navigate = useNavigate();
  const [inputEmail, setInputEmail] = React.useState<string>();
  const [inputPassword, setInputPassword] = React.useState<string>();
  const setWorkspace = useAccountStore((state) => state.setWorkspace);
  const setAccountDebit = useAccountStore((state) => state.setAccountDebit);
  const setMemberLocation = useAccountStore((state) => state.setMemberLocation);
  const setMember = useAccountStore((state) => state.setMember);
  const setMemberList = useAccountStore((state) => state.setMemberList);
  const setInvoiceList = useAccountStore((state) => state.setInvoiceList);
  const setIntegrationList = useAccountStore(
    (state) => state.setIntegrationList,
  );
  const [setActiveBrand, setBrandNames, setBrandData, setActiveBrandData] =
    useAccountStore((state) => [
      state.setActiveBrand,
      state.setBrandNames,
      state.setBrandData,
      state.setActiveBrandData,
    ]);

  useEffect(() => {
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  const signButtonEvent = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (typeof inputEmail == "undefined" || typeof inputPassword == "undefined")
      return;
    if (!isValidEmail(inputEmail)) return;
    if (inputPassword.length < 6) return;
    setSigning(true);
    interactWithSignButton(props.library, inputEmail, inputPassword).then(
      (value) => {
        setSigning(false);
        if (value) {
          sign(inputEmail, props.library.getIdToken());
          props.library.getMember().then((item: any) => {
            const value = item[0];
            setMember(
              value["name"] as string,
              value["avatar"] as string,
              value["generation"] as string,
              value["gender"] as string,
              value["location"] as string,
              value["access_level"] as boolean,
              value["active_brand"] as string | null,
            );
          });

          // props.library.getAccountInfo().then((item) => {
          //   const value = item[0];
          //   setAccountInfo(
          //     value["plan"] as string,
          //     value["credit"] as number,
          //   );
          // });

          // props.library.getAccountDebit().then((item) => {
          //   let value = 0;
          //   for (const element of item) {
          //     value += element["value"];
          //   }
          //   setAccountDebit(value as number);
          // });

          props.library.getMemberList().then((item: any) => {
            const memberList: Member[] = item.map((value: any) => {
              return {
                name: value["name"],
                logo: value["avatar"],
                email: value["email"],
                generation: value["generation"],
                gender: value["gender"],
                location: value["location"],
                accessLevel: value["access_level"],
              } as Member;
            });
            setMemberList(memberList);
          });
          props.library
            .listBrandStory()
            .then((r: any) => {
              if (r.response.length === 0) {
                return;
              }
              let brand_names = [];
              let brand_data = {};
              for (const obj of r.response) {
                brand_data[obj["brand_name"]] = obj;
              }
              setBrandNames(brand_names);
              setBrandData(brand_data);
              props.library.getActiveBrand().then((item: any) => {
                const result = item["response"]["brand_name"];
                setActiveBrand(result);
                setActiveBrandData(brand_data[result]);
              });
            })
            .catch((e: any) => {
              console.log("Error getting brand data", e);
            });

          // props.library.getInvoiceList().then((item) => {
          //   const invoiceList: Invoice[] = item.map((value) => {
          //     return {
          //       name: value["name"],
          //       timestamp: value["timestamp"],
          //       credit: value["credit"],
          //       paid: value["paid"],
          //     } as Invoice;
          //   });
          //   setInvoiceList(invoiceList);
          // });

          // props.library.getIntegrationList().then((item) => {
          //   const integrationList: Integration[] = [
          //     {
          //       name: "Google Drive",
          //       lastSync: "23rd April, 2024",
          //       icon: "google-drive-icon",
          //       connected: true,
          //     },
          //     {
          //       name: "Shopify",
          //       lastSync: "15th March, 2024",
          //       icon: "shopify-icon",
          //       connected: true,
          //     },
          //   ];
          //   setIntegrationList(integrationList);
          // });

          getCurrentLocation().then((value) => {
            if (typeof value == "undefined") return;
            setMemberLocation(value);
          });

          navigate(ROUTES.STUDIO);
        }
      },
    );
  };

  const handleKeyPress = (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      signButtonEvent(event as unknown as React.FormEvent<HTMLFormElement>);
    }
  };

  return (
    <div className="member-signin">
      {typeof token != "undefined" && (
        // Animate Welcome in Multiple Languages
        <h2>
          Welcome <span>{email}</span>
        </h2>
      )}
      {typeof token == "undefined" && (
        <form onSubmit={signButtonEvent} onKeyDown={handleKeyPress}>
          <div className="user-input">
            <label className="input-text-field">
              <input
                type="email"
                placeholder="&nbsp;"
                onChange={(e) => setInputEmail(e.target.value)}
              />
              <span className="placeholder">Enter Email</span>
            </label>
            <label className="input-text-field">
              <input
                type="password"
                placeholder="&nbsp;"
                style={{
                  transform: "translate(0, 0)",
                  transition: "translate 5.5s ease-in",
                }}
                onChange={(e) => setInputPassword(e.target.value)}
              />
              <span className="placeholder">Enter Password</span>
            </label>
          </div>

          <div className="user-interaction">
            <div
              className="forgot-password"
              onClick={() => {
                setMissionView(MissionView.FORGOT_PASSWORD);
              }}
            >
              forgot password
            </div>
            <div className="sign-in">
              <button className="start-button" type="submit">
                <span className="top_left"></span>
                <span className="top_right"></span>
                <span className={`title ${signing ? "hide" : ""}`}>
                  Sign In
                </span>
                <span className="bottom_right"></span>
                <span className="bottom_left"></span>
                <span className={`${signing ? "rotate-span" : "hide"}`}>
                  <i className="fa fa-refresh"></i>
                </span>
              </button>
            </div>
            <div className="waitlist-message">
              <button onClick={() => setMissionView(MissionView.SIGN_UP)}>
                Create an account 🎉
              </button>
            </div>
          </div>
        </form>
      )}
    </div>
  );
};

export { MemberSignUI };
