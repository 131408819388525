import { ImagePreview } from "../../lib/hedwigai";
import * as React from "react";
import { MissionView, usePrimaryMemoryStore } from "../../lib/state";
import { AssetTileUI } from "./asset-tile";

interface CarouselProps {
  headline: string;
  items: ImagePreview[];
}

const Carousel: React.FC<CarouselProps> = (props: CarouselProps) => {
  const setFocusAsset = usePrimaryMemoryStore((state) => state.setFocusAsset);
  const contentRef = React.createRef<HTMLUListElement>();
  return (
    <div className="carousel">
      <div className="header">
        <h2>{props.headline}</h2>
        <div className="controls">
          <button
            className="arrow arrow-prev"
            onClick={() => {
              if (
                typeof contentRef.current == "undefined" ||
                contentRef.current == null
              )
                return;
              const x =
                contentRef.current.clientWidth / 2 -
                contentRef.current.scrollLeft +
                0;
              contentRef.current.scroll({
                left: -x,
                behavior: "smooth",
              });
            }}
          />
          <button
            className="arrow arrow-next"
            onClick={() => {
              if (
                typeof contentRef.current == "undefined" ||
                contentRef.current == null
              )
                return;
              const x =
                contentRef.current.clientWidth / 2 +
                contentRef.current.scrollLeft +
                0;
              contentRef.current.scroll({
                left: x,
                behavior: "smooth",
              });
            }}
          />
        </div>
      </div>
      <ul className="content" ref={contentRef}>
        {props.items.map((item, index) => {
          return (
            <li key={item.id}>
              <AssetTileUI item={item} setFocusAsset={setFocusAsset} />
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export { Carousel };
